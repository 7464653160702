import React, {
  useState,
  useEffect,
  fetchDataFromDatabase,
  useRef,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useAlert } from "react-alert";
import { Navigate, useNavigate } from "react-router-dom";
import { createPeople, getPeople } from "../../store/actions/peopleAction";
import {
  createSkills,
  getSkills,
  updateSkills,
  dropUpdateSkills,
} from "../../store/actions/skillAction";
import {
  createRole,
  getRole,
  updateRole,
  dropUpdateRole,
} from "../../store/actions/roleAction";
import {
  createLink,
  getLink,
  updateLink,
  dropUpdateLink,
} from "../../store/actions/linkAction";
import {
  createDomain,
  getDomain,
  updateDomain,
  dropUpdateDomain,
} from "../../store/actions/domainAction";
import {
  createProject,
  getProject,
} from "../../store/actions/projectAction";
import { createCircle, getCircle } from "../../store/actions/circleAction";
import {
  createState,
  updateState,
  getState,
} from "../../store/actions/stateAction";
// import {  } from "../../store/actions/skillAction";
import { updateMember, getMember } from "../../store/actions/memberAction";
import {
  PEOPLE_SUCCESS_MESSAGE_CLEAR,
  PEOPLE_ERROR_CLEAR,
  PEOPLE_ID_CLEAR,
} from "../../store/types/peopleType";
import {
  SKILL_SUCCESS_MESSAGE_CLEAR,
  SKILL_ERROR_CLEAR,
  SKILL_ID_CLEAR,
  SKILLS_MODIFICATIONS,
} from "../../store/types/skillType";
import {
  ROLE_SUCCESS_MESSAGE_CLEAR,
  ROLE_ERROR_CLEAR,
  ROLE_ID_CLEAR,
  ROLES_MODIFICATIONS,
} from "../../store/types/roleType";
import {
  LINK_SUCCESS_MESSAGE_CLEAR,
  LINK_ERROR_CLEAR,
  LINK_ID_CLEAR,
  LINKS_MODIFICATIONS,
} from "../../store/types/linkType";
import {
  DOMAIN_SUCCESS_MESSAGE_CLEAR,
  DOMAIN_ERROR_CLEAR,
  DOMAIN_ID_CLEAR,
  DOMAINS_MODIFICATIONS,
} from "../../store/types/domainType";
import {
  PROJECT_SUCCESS_MESSAGE_CLEAR,
  PROJECT_ERROR_CLEAR,
  PROJECT_ID_CLEAR,
} from "../../store/types/projectType";
import {
  CIRCLE_SUCCESS_MESSAGE_CLEAR,
  CIRCLE_ERROR_CLEAR,
  CIRCLE_ID_CLEAR,
} from "../../store/types/circleType";
import {
  STATE_SUCCESS_MESSAGE_CLEAR,
  STATE_ERROR_CLEAR,
  STATES_MODIFICATIONS,
} from "../../store/types/stateType";
import { noteCreate, getNote } from "../../store/actions/noteAction";
import {
  MEMBER_UPDATE_SUCCESS,
  MEMBER_MODIFICATIONS,
} from "../../store/types/memberType";
import { SERVER_URI, CARD_LIMIT, CLIENT_URL } from "../../config/keys";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  createAndUpdateMember,
  getPFMember,
} from "../../store/actions/pfmemberAction";
import { PCTOOL_STATE_CLAER } from "../../store/types/toolsType";
import {
  historyCreate,
  historyGet,
} from "../../store/actions/historyAction";
import Swal from "sweetalert2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createMember } from "../../store/actions/memberAction";
import TextareaAutosize from "react-textarea-autosize";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  freezeWindow,
  unfreezeWindow,
} from "../../../src/HelperFunctions/Overlay";
import { isCardExist } from "../../../src/HelperFunctions/CardExist";

import { generateStripeUpgradeUrl } from "../../store/actions/pricingAction";
// import { userVerify, paymentVerify } from "../../store/actions/authAction";

import dragula from "dragula";
import { clearLocalStorage } from "../../HelperFunctions/ClearLocalStorage";
import { GetAliasesName } from "../../HelperFunctions/GetAliasesName";

import PillComponent from "./PillComponent";
import {
  aggrigatedPersonFTE,
  GetPersonAvailability,
  GetTotalWorkLoad,
  sumFTEBody,
  sumFTEBody_Focus,
} from "../../HelperFunctions/FTECalculation";
import {
  Get_ShowHideColumn_Project_Circle_Mode,
  Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people,
  GetTagFilteredSortedData,
  TagWiseSorting,
} from "../../HelperFunctions/TagWiseSorting";
import { getTeam, updateTeam } from "../../store/actions/teamAction";
import PeopleList from "./StandardMode/PeopleList";
import SkillList from "./StandardMode/SkillList";
import RoleList from "./StandardMode/RoleList";
import DomainList from "./StandardMode/DomainList";
import LinkList from "./StandardMode/LinkList";
import CircleList from "./StandardMode/CircleList";
import ProjectList from "./StandardMode/ProjectList";
import FPPeopleList from "./PeopleFocusMode/FPPeopleList";
import FPSkillsList from "./PeopleFocusMode/FPSkillsList";
import FPRolesList from "./PeopleFocusMode/FPRolesList";
import FPDomainsList from "./PeopleFocusMode/FPDomainsList";
import FPLinksList from "./PeopleFocusMode/FPLinksList";
import FPCirclesList from "./PeopleFocusMode/FPCirclesList";
import FPProjectsList from "./PeopleFocusMode/FPProjectsList";
import FCPeopleList from "./CircleFocusMode/FCPeopleList";
import FCSkillsList from "./CircleFocusMode/FCSkillsList";
import FCRolesList from "./CircleFocusMode/FCRolesList";
import FCDomainsList from "./CircleFocusMode/FCDomainsList";
import FCLinksList from "./CircleFocusMode/FCLinksList";
import FCCirclesList from "./CircleFocusMode/FCCirclesList";
import FCProjectsList from "./CircleFocusMode/FCProjectsList";
import FPrPeopleList from "./ProjectFocusMode/FPrPeopleList";
import PrSkillsList from "./ProjectFocusMode/PrSkillsList";
import PrRolesList from "./ProjectFocusMode/PrRolesList";
import PrDomainsList from "./ProjectFocusMode/PrDomainsList";
import PrLinksList from "./ProjectFocusMode/PrLinksList";
import PrCirclesList from "./ProjectFocusMode/PrCirclesList";
import PrProjectsList from "./ProjectFocusMode/PrProjectsList";
import CircleModeList from "./CircleMode/CircleModeList";
import ProjectModeList from "./ProjectMode/ProjectModeList";
import CircleAsPeopleModeList from "./CircleMode/CircleAsPeopleModeList";
import ProjectAsPeopleModeList from "./ProjectMode/ProjectAsPeopleModeList";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250,
});

const isMatchFound = (searchStr, data) => {
  const completeDataAsString = Object.values(data).flat().map(item =>
    typeof item === "object" ? /* JSON.stringify(item) */ "" : String(item)
  ).join(" ");
  return completeDataAsString.toLowerCase().includes(searchStr);
}

function Body({
  showGenericRole,
  workloadToggle,
  openPeople,
  closePeople,
  openSkill,
  closeSkill,
  openRole,
  closeRole,
  openLink,
  closeLink,
  openCircle,
  closeCircle,
  openProject,
  closeProject,
  openDomain,
  closeDomain,
  indexSet,
  index,
  list,
  focusMode,
  compareMode,
  setCompareMode,
  naveSort,
  allSearch,
  isModeSwitched,
  clickedOnList,
  setClickedOnList,
  setIsEXT,
  isEXT,
  isNO,
  setIsNO,
  isTag,
  setIsTag,
  isTagData,
  setIsTagData,
  tagFilteredObj,
  setTagFilteredObj,
  extNoFilteredObj,
  setExtNoFilteredObj,
  specialModeDataObj,
  setSpecialModeDataObj,
  listHide,
  setListHide,
  focused,
  setFocused,
  setPepole,
  setPepoles,
  setSkill,
  setSkills,
  setRole,
  setRoles,
  setDomain,
  setDomains,
  setLink,
  setLinks,
  setCircle,
  setCircles,
  setProject,
  setProjects,
  openInfo,
  closeInfo,
  isInfos,
  isActive,
  xlsData,
  focusList,
  setFList,
  fList,
  setXls,
  setXlsName,
  setXlsCM,
  note,
  setNote,
  noteOnchange,
  saveNote,
  setSaveNote,
  openPeopleModeTool,
  openCircleModeTool,
  setFocusedMode,
  temporarilyMovedItem,
  setTemporarilyMovedItem,
  teams,
  SConToRole,
  RConToSkill,
  circleToProject,
  projectToCircle,
}) {
  const { loading, successMessage, error, authenticate, myInfo, curUserData } =
    useSelector((state) => state.auth);
  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const {
    skills,
    cuSkillId,
    nDaoamins,
    skillsMessagee,
    skillError,
    skillsMessage,
  } = useSelector((state) => state.skills);
  const { roles, cuRoleId, dRoles, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  const {
    domains,
    cuDomainId,
    dDomains,
    domainMessage,
    domainMessagee,
    domainError,
  } = useSelector((state) => state.domains);
  const { links, cuLinkId, linkMessagee, linkError } = useSelector(
    (state) => state.links
  );
  const { circles, dCircles, cuCircleId, circleMessagee, circleError } =
    useSelector((state) => state.circles);
  const { projects, cuProjectId, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { pfmembers, pfmemberMessagee, pfmemberError, pfmemberMessage } =
    useSelector((state) => state.pfmembers);
  const {
    meetings,
    meetingsMessage,
    meetingId,
    meetingsMessagee,
    meetingsError,
  } = useSelector((state) => state.meetings);
  const { noteData, noteMessage, noteMessagee, noteError } = useSelector(
    (state) => state.notes
  );
  const { circleTools, toolSuccessMessage, toolErrors } = useSelector(
    (state) => state.tools
  );
  const {
    states,
    statesLM,
    cuStateId,
    statesMessagee,
    statesError,
    statesMessage,
  } = useSelector((state) => state.states);
  const { stripeUrl } = useSelector((state) => state.pricings);

  const Allstate = useSelector((state) => state);

  const parentPeopleRef = useRef(null);
  const parentSkillRef = useRef(null);
  const parentRoleRef = useRef(null);
  const parentDomainRef = useRef(null);
  const parentLinkRef = useRef(null);
  const parentCircleRef = useRef(null);
  const parentProjectRef = useRef(null);
  const focusListRef = useRef(null);
  const setCombinedRefs = (providedRef, customRef) => (element) => {
    providedRef(element);
    customRef.current = element;
  };

  const [focusedRef, setFocusedRef] = useState(0);
  const [cardRef, setCardRef] = useState([
    { type: "people", val: 0 },
    { type: "skill", val: 0 },
    { type: "role", val: 0 },
    { type: "domain", val: 0 },
    { type: "link", val: 0 },
    { type: "circle", val: 0 },
    { type: "project", val: 0 }
  ]);


  /* Added Overlay on state change to prevent duplicate actions. Credit: Indranil */
  useEffect(() => {
    //This will add a transparent layer over the app window so that nothing else can be clicked afret one action. Thsi unfreeze automatically hapens after 500 ms of freezing.
    // freezeWindow();
    // scrollToDiv();
    document.body.classList.remove("home-page");
    document.body.classList.remove("purpose-tree");
    document.body.classList.remove("no-app-pages");
    document.body.classList.remove("workflow-page");
  }, [Allstate]);

  useLayoutEffect(() => {
    if (focusedRef && focusListRef && focusListRef.current) {
      focusListRef.current.scrollTop = focusedRef;
      // console.log('after asigning scrolltop', focusListRef.current.scrollTop)
    }
    if (cardRef.length > 0) {
      cardRef.forEach(item => {
        if (item.type === "people" && parentPeopleRef && parentPeopleRef.current) {
          parentPeopleRef.current.scrollTop = item.val;
        } else if (item.type === "skill" && parentSkillRef && parentSkillRef.current) {
          parentSkillRef.current.scrollTop = item.val;
        } else if (item.type === "role" && parentRoleRef && parentRoleRef.current) {
          parentRoleRef.current.scrollTop = item.val;
        } /* else if (item.type === "domain" && parentDomainRef && parentDomainRef.current) {
          parentDomainRef.current.scrollTop = item.val;
        } */ else if (item.type === "link" && parentLinkRef && parentLinkRef.current) {
          parentLinkRef.current.scrollTop = item.val;
        } else if (item.type === "circle" && parentCircleRef && parentCircleRef.current) {
          parentCircleRef.current.scrollTop = item.val;
        } else if (item.type === "project" && parentProjectRef && parentProjectRef.current) {
          parentProjectRef.current.scrollTop = item.val;
        }
      });
    }

    // unfreezeWindow()

  });

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/person/create`;

  let dispatch = useDispatch();

  /* Redirect the user to the login page if the session expiry message is captured. */
  useEffect(() => {
    let error =
      peopleError ||
      skillError ||
      roleError ||
      domainError ||
      linkError ||
      circleError ||
      projectError;
    if (
      error.trim() === "Your session has expired. Please re-login to continue."
    ) {
      // localStorage.clear();
      clearLocalStorage();
      window.location.href = "/login";
    }
  }, [
    peopleError,
    skillError,
    roleError,
    domainError,
    linkError,
    circleError,
    projectError,
  ]);

  //...............................................All Model............................................................

  // get current time in particular format
  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  //List From for field name
  const [lFromState, setLFromsState] = useState({
    Peoples: false,
    Skills: false,
    Roles: false,
    Domains: false,
    Links: false,
    Circles: false,
    Projects: false,
  });

  // People Modal
  const [fName, setFName] = useState("");
  const handleNameChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setFName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const peopleSubmit = (event) => {
    event.preventDefault();
    // Added by Debashis on 13-12-2023 (updated 18-12-2023)
    let lastname = "";
    let firstname = "";
    if (fName.trim().indexOf(" ") >= 0) {
      let nameArray = fName.split(" ");
      lastname = nameArray.pop();
      firstname = nameArray.join(" ");
    } else {
      lastname = "";
      firstname = fName;
    }

    let data = {
      fname: firstname,
      lname: lastname,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    if (data) {
      let peopleSort = localStorage.getItem("peopleSort");
      dispatch(createPeople({ ...data, peopleSort })).then(() => {
        lFromState.Peoples = false;
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (peopleMessagee && peopleMessagee.length > 0) {
      dispatch(getState(obj)).then(() => {
        localStorage.setItem("latest-created", cuPeopleId);
        dispatch({ type: PEOPLE_ID_CLEAR });
        dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
        setFName("");
        setLFromsState({
          ...lFromState,
          ["Peoples"]: false,
        });
      });
      dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (peopleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: peopleError,
      });
      dispatch({ type: PEOPLE_ERROR_CLEAR });
    }
  }, [peopleMessagee, peopleError, cuPeopleId]);

  // Skill Modal
  const [skill, setSkillName] = useState("");
  const handleSkillChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setSkillName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const skillSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = { skillName: skill, adminId: adminId, url: url, teamId: teamId };
    if (data) {
      let skillSort = localStorage.getItem("skillSort");
      dispatch(createSkills({ ...data, skillSort })).then((res) => {
        // dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getSkills(obj));
        lFromState.Skills = false;
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (skillsMessagee && skillsMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuSkillId);
      dispatch({ type: SKILL_ID_CLEAR });
      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
      setSkillName("");
      setLFromsState({
        ...lFromState,
        ["Skills"]: false,
      });
    }

    if (skillError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: skillError,
      });
      // alert.error(peopleError)
      dispatch({ type: SKILL_ERROR_CLEAR });
      // dispatch(getSkills(obj));
      // closeSkill();
    }
  }, [skillsMessagee, skillError, cuSkillId]);

  // Role Modal
  const [role, setRoleName] = useState("");
  const handleRoleChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setRoleName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const roleSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    let roleSort = localStorage.getItem("roleSort");
    obj.userId = myInfo.userId;
    let data = { roleName: role, adminId: adminId, url: url, teamId: teamId };
    if (data) {
      dispatch(createRole({ ...data, roleSort })).then((res) => {
        // dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getRole(obj));
        lFromState.Roles = false;
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (roleMessagee && roleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuRoleId);
      dispatch({ type: ROLE_ID_CLEAR });
      dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
      setRoleName("");
      setLFromsState({
        ...lFromState,
        ["Roles"]: false,
      });
    }

    if (roleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: roleError,
      });
      // alert.error(peopleError)
      dispatch({ type: ROLE_ERROR_CLEAR });
      // dispatch(getRole(obj));
      // closeRole();
    }
  }, [roleMessagee, roleError, cuRoleId]);

  // Link Modal
  const [link, setLinkName] = useState("");
  const handleLinkChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setLinkName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const linkSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = { linkName: link, adminId: adminId, url: url, teamId: teamId };
    let linkSort = localStorage.getItem("linkSort");
    if (data) {
      dispatch(createLink({ ...data, linkSort })).then((res) => {
        dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getLink(obj));
        lFromState.Links = false;
      });
    }
  };

  // console.log("linkmsg in body", linkMessagee);

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (linkMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuLinkId);
      dispatch({ type: LINK_ID_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
      setLinkName("");
      setLFromsState({
        ...lFromState,
        ["Links"]: false,
      });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
    }

    if (linkError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: linkError,
      });
      // alert.error(peopleError)
      dispatch({ type: LINK_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      // closeLink();
    }
  }, [linkMessagee, linkError, cuLinkId]);

  // Domain Modal
  const [domain, setDomainName] = useState("");
  const handleDomainChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setDomainName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const domainSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = {
      domainName: domain,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    let domainSort = localStorage.getItem("domainSort");
    let skillSort = localStorage.getItem("skillSort");
    let roleSort = localStorage.getItem("roleSort");
    if (data) {
      dispatch(createDomain({ ...data, domainSort, skillSort, roleSort })).then(
        (res) => {
          dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
          // dispatch(getDomain({...obj, domainSort}));
          lFromState.Domains = false;
        }
      );
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (domainMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuDomainId);
      dispatch({ type: DOMAIN_ID_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
      setDomainName("");
      setLFromsState({
        ...lFromState,
        ["Domains"]: false,
      });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
    }

    if (domainError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: domainError,
      });
      dispatch({ type: DOMAIN_ERROR_CLEAR });
    }
  }, [domainMessagee, domainError, cuDomainId]);

  // Circle Modal
  const [circle, setCircleName] = useState("");
  const handleCircleChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setCircleName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const circleSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let circleSort = localStorage.getItem("circleSort");
    let data = {
      circleName: circle,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    if (data) {
      dispatch(createCircle({ ...data, circleSort })).then((res) => {
        lFromState.Circles = false;
        setCircleName("");
        dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getCircle(obj));
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (circleMessagee && circleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuCircleId);
      dispatch({ type: CIRCLE_ID_CLEAR });
      dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });

      setCircleName("");
      setLFromsState({
        ...lFromState,
        ["Circles"]: false,
      });
      dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (circleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: circleError,
      });
      dispatch({ type: CIRCLE_ERROR_CLEAR });
    }
  }, [circleMessagee, circleError, cuCircleId]);

  // Project Modal
  const [project, setProjectName] = useState("");
  const handleProjectChange = (event) => {
    const { selectionStart } = event.target; // Save the cursor position
    setProjectName(event.target.value);
    cursorPos.current = selectionStart; // Update the ref with the cursor position
  };

  const projectSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = {
      projectName: project,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    if (data) {
      let projectSort = localStorage.getItem("projectSort");
      dispatch(createProject({ ...data, projectSort })).then((res) => {
        lFromState.Projects = false;
        setProjectName("");
        dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getProject(obj));
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (projectMessagee && projectMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuProjectId);
      dispatch({ type: PROJECT_ID_CLEAR });
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
      setProjectName("");
      setLFromsState({
        ...lFromState,
        ["Projects"]: false,
      });
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
    }

    if (projectError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: projectError,
      });
      dispatch({ type: PROJECT_ERROR_CLEAR });
      // closeProject();
    }
  }, [projectMessagee, projectError, cuProjectId]);

  //.......................................................All Curd State.......................................................//

  //................................................ Sorting , Drag and Drop..................................................

  // Peoples
  const peopleDragItem = useRef();
  const peopleDragOverItem = useRef();
  const [peopleSorted, setpeopleSorted] = useState([]);

  useEffect(() => {
    if (
      peoples &&
      peoples.length >= 0 &&
      JSON.stringify(peoples) !== JSON.stringify(peopleSorted)
    ) {
      // console.log(peoples, peopleSorted);
      setpeopleSorted(peoples);
    }
  }, [peoples]);

  const peopleStateUpdate = (curpeople) => {
    // console.log('peopleStateUpdate: ', curpeople);
    if (curpeople.length > 0) {
      localStorage.setItem("peopleSort", curpeople);
    }

    // console.log('States: ', states);
    // let updated_states = {
    //   ...states,
    //   Person: curpeople
    // };
    // console.log('Updated states: ', updated_states);
    // dispatch({ type: STATES_MODIFICATIONS, payload: updated_states })
    freezeWindow(false);
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        Person: curpeople,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
      dispatch(
        getState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          Person: curpeople,
        })
      );
      unfreezeWindow();
      // console.log('false')
    });
  };

  // states
  const PeopleSort = (data) => {
    let peoplest = [...peopleSorted];
    if (data === "NewToOld") {
      peoplest.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      peoplest
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      peoplest.sort((a, b) => {
        const nameA = `${a.fname.toUpperCase()} ${a.lname.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.fname.toUpperCase()} ${b.lname.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      peoplest.sort((a, b) => {
        const nameA = `${a.fname.toUpperCase()} ${a.lname.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.fname.toUpperCase()} ${b.lname.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Person &&
        states.Person.length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let i = 0; i < states.Person.length; i++) {
          for (let j = 0; j < peopleSorted.length; j++) {
            if (states.Person[i] === peopleSorted[j]._id) {
              curPData.push(peopleSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        peoplest = curPData;
      }
    }
    setpeopleSorted(peoplest);

    // console.log('PeopleSort: ', peoplest);
  };

  // Skills
  const skillsDragItem = useRef();
  const skillsDragOverItem = useRef();
  const [skillsSorted, setskillsSorted] = useState([]);

  useEffect(() => {
    if (
      skills &&
      skills.length >= 0 &&
      JSON.stringify(skills) !== JSON.stringify(skillsSorted)
    ) {
      setskillsSorted(skills);
    }
  }, [skills]);

  const skillStateUpdate = (curskill) => {
    if (curskill.length > 0) {
      localStorage.setItem("skillSort", curskill);
    }

    freezeWindow(false);
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/update`,
        Skills: curskill,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  //Skills sorting
  const SkillsSort = (data) => {
    let skillsData = [...skillsSorted];
    if (data === "NewToOld") {
      skillsData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      skillsData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      skillsData.sort((a, b) => {
        const nameA = `${a.skillName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.skillName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      skillsData.sort((a, b) => {
        const nameA = `${a.skillName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.skillName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Skills &&
        states.Skills.length > 0 &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < states.Skills.length; i++) {
          for (let j = 0; j < skillsSorted.length; j++) {
            if (states.Skills[i] === skillsSorted[j]._id) {
              curPData.push(skillsSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        skillsData = curPData;
      }
    }
    setskillsSorted(skillsData);
  };

  // Roles
  const rolesDragItem = useRef();
  const rolesDragOverItem = useRef();
  const [rolesSorted, setrolesSorted] = useState([]);

  const rolesDragStart = (e, position) => {
    rolesDragItem.current = position;
  };

  const rolesDragEnter = (e, position) => {
    rolesDragOverItem.current = position;
  };

  useEffect(() => {
    if (
      roles &&
      roles.length >= 0 &&
      JSON.stringify(roles) !== JSON.stringify(rolesSorted)
    ) {
      setrolesSorted(roles);
    }
  }, [roles]);

  const roleStateUpdate = (currole) => {
    // console.log('roleStateUpdate: ', currole)
    if (currole.length > 0) {
      localStorage.setItem("roleSort", currole);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Roles: currole,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  const RolesSort = (data) => {
    var rolesData = [...rolesSorted];
    if (data === "NewToOld") {
      rolesData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      rolesData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      rolesData.sort((a, b) => {
        const nameA = `${a.roleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.roleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      rolesData.sort((a, b) => {
        const nameA = `${a.roleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.roleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Roles &&
        states.Roles.length > 0 &&
        rolesSorted &&
        rolesSorted.length > 0
      ) {
        for (let i = 0; i < states.Roles.length; i++) {
          for (let j = 0; j < rolesSorted.length; j++) {
            if (states.Roles[i] === rolesSorted[j]._id) {
              curPData.push(rolesSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        rolesData = curPData;
      }
    }
    setrolesSorted(rolesData);
  };

  //Domains
  const domainsDragItem = useRef();
  const domainsDragOverItem = useRef();
  const [domainsSorted, setdomainsSorted] = useState([]);

  if (domainsSorted.length === 0) {
    setTimeout(() => {
      setdomainsSorted(domains);
    }, 1);
  } else if (domainsSorted.length !== domains.length) {
    setdomainsSorted(domains);
  }

  useEffect(() => {
    if (
      domains &&
      domains.length >= 0 &&
      JSON.stringify(domains) !== JSON.stringify(domainsSorted)
    ) {
      setdomainsSorted(domains);
    }
  }, [domains]);

  const domainStateUpdate = (curdomain) => {
    // console.log('domainStateUpdate: ', curdomain);
    if (curdomain.length > 0) {
      localStorage.setItem("domainSort", curdomain);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Domains: curdomain,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
      unfreezeWindow();
    });
  };

  const DomainsSort = (data) => {
    var domainsData = [...domainsSorted];
    if (data === "NewToOld") {
      domainsData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      domainsData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      domainsData.sort((a, b) => {
        const nameA = `${a.domainName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.domainName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      domainsData.sort((a, b) => {
        const nameA = `${a.domainName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.domainName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Domains &&
        states.Domains.length > 0 &&
        domainsSorted &&
        domainsSorted.length > 0
      ) {
        for (let i = 0; i < states.Domains.length; i++) {
          for (let j = 0; j < domainsSorted.length; j++) {
            if (states.Domains[i] === domainsSorted[j]._id) {
              curPData.push(domainsSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        domainsData = curPData;
      }
    }
    // console.log(domainsData)
    setdomainsSorted(domainsData);
  };

  // Links
  const linksDragItem = useRef();
  const linksDragOverItem = useRef();
  const [linksSorted, setlinksSorted] = useState([]);

  useEffect(() => {
    if (
      links &&
      links.length >= 0 &&
      JSON.stringify(links) !== JSON.stringify(linksSorted)
    ) {
      setlinksSorted(links);
    }
  }, [links]);

  const linkStateUpdate = (curlink) => {
    // console.log('linkStateUpdate: ', curlink)
    if (curlink.length > 0) {
      localStorage.setItem("linkSort", curlink);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Links: curlink,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  //Links sorting
  const LinksSort = (data) => {
    var linksData = [...linksSorted];
    if (data === "NewToOld") {
      linksData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      linksData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      linksData.sort((a, b) => {
        const nameA = `${a.linkName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.linkName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      linksData.sort((a, b) => {
        const nameA = `${a.linkName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.linkName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Links &&
        states.Links.length > 0 &&
        linksSorted &&
        linksSorted.length > 0
      ) {
        for (let i = 0; i < states.Links.length; i++) {
          for (let j = 0; j < linksSorted.length; j++) {
            if (states.Links[i] === linksSorted[j]._id) {
              curPData.push(linksSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        linksData = curPData;
      }
    }
    setlinksSorted(linksData);
  };

  // Circle
  const circlesDragItem = useRef();
  const circlesDragOverItem = useRef();
  const [circlesSorted, setCirclesSorted] = useState([]);

  useEffect(() => {
    if (
      circles &&
      circles.length >= 0 &&
      JSON.stringify(circles) !== JSON.stringify(circlesSorted)
    ) {
      setCirclesSorted(circles);
    }
  }, [circles]);

  const circleStateUpdate = (curcircle) => {
    // console.log('circleStateUpdate: ', curcircle);
    if (curcircle.length > 0) {
      localStorage.setItem("circleSort", curcircle);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Circles: curcircle,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  const CirclesSort = (data) => {
    var circlesData = [...circlesSorted];
    if (data === "NewToOld") {
      circlesData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      circlesData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      circlesData.sort((a, b) => {
        const nameA = `${a.circleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.circleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      circlesData.sort((a, b) => {
        const nameA = `${a.circleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.circleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Circles &&
        states.Circles.length > 0 &&
        circlesSorted &&
        circlesSorted.length > 0
      ) {
        for (let i = 0; i < states.Circles.length; i++) {
          for (let j = 0; j < circlesSorted.length; j++) {
            if (states.Circles[i] === circlesSorted[j]._id) {
              curPData.push(circlesSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        circlesData = curPData;
      }
    }
    // console.log(circlesData)
    setCirclesSorted(circlesData);
  };

  // Projects
  const projectsDragItem = useRef();
  const projectsDragOverItem = useRef();
  const [projectsSorted, setprojectsSorted] = useState([]);

  useEffect(() => {
    if (
      projects &&
      projects.length >= 0 &&
      JSON.stringify(projects) !== JSON.stringify(projectsSorted)
    ) {
      setprojectsSorted(projects);
    }
  }, [projects]);

  const projectStateUpdate = (curproject) => {
    // console.log('projectStateUpdate: ', curproject)
    if (curproject.length > 0) {
      localStorage.setItem("projectSort", curproject);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Projects: curproject,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  //Projects sorting
  const ProjectsSort = (data) => {
    var projectsData = [...projectsSorted];
    if (data === "NewToOld") {
      projectsData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      projectsData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      projectsData.sort((a, b) => {
        const nameA = `${a.projectName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.projectName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      projectsData.sort((a, b) => {
        const nameA = `${a.projectName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.projectName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Projects &&
        states.Projects.length > 0 &&
        projectsSorted &&
        projectsSorted.length > 0
      ) {
        for (let i = 0; i < states.Projects.length; i++) {
          for (let j = 0; j < projectsSorted.length; j++) {
            if (states.Projects[i] === projectsSorted[j]._id) {
              curPData.push(projectsSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        projectsData = curPData;
      }
    }
    // console.log(projectsData)
    setprojectsSorted(projectsData);
  };

  useEffect(() => {
    let datas = localStorage.getItem("sortType");
    if (datas && datas === "ACSORT" && naveSort.length > 0) {
      localStorage.removeItem("sortType");
      PeopleSort(naveSort);
      SkillsSort(naveSort);
      RolesSort(naveSort);
      LinksSort(naveSort);
      DomainsSort(naveSort);
      CirclesSort(naveSort);
      ProjectsSort(naveSort);
    }
  }, [naveSort]);

  const handeSorts = (modes, list) => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (
      list === "People" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        People: modes,
      };
      if (modes === "NewToOld") {
        PeopleSort("NewToOld");
      } else if (modes === "OldToNew") {
        PeopleSort("OldToNew");
      } else if (modes === "AtoZ") {
        PeopleSort("AtoZ");
      } else if (modes === "ZtoA") {
        PeopleSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Skills" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Skills: modes,
      };
      if (modes === "NewToOld") {
        SkillsSort("NewToOld");
      } else if (modes === "OldToNew") {
        SkillsSort("OldToNew");
      } else if (modes === "AtoZ") {
        SkillsSort("AtoZ");
      } else if (modes === "ZtoA") {
        SkillsSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Roles" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Roles: modes,
      };
      if (modes === "NewToOld") {
        RolesSort("NewToOld");
      } else if (modes === "OldToNew") {
        RolesSort("OldToNew");
      } else if (modes === "AtoZ") {
        RolesSort("AtoZ");
      } else if (modes === "ZtoA") {
        RolesSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // RolesSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Domains" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Domains: modes,
      };
      if (modes === "NewToOld") {
        DomainsSort("NewToOld");
      } else if (modes === "OldToNew") {
        DomainsSort("OldToNew");
      } else if (modes === "AtoZ") {
        DomainsSort("AtoZ");
      } else if (modes === "ZtoA") {
        DomainsSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // DomainsSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Links" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Links: modes,
      };
      if (modes === "NewToOld") {
        LinksSort("NewToOld");
      } else if (modes === "OldToNew") {
        LinksSort("OldToNew");
      } else if (modes === "AtoZ") {
        LinksSort("AtoZ");
      } else if (modes === "ZtoA") {
        LinksSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // LinksSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Circles" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Circles: modes,
      };
      if (modes === "NewToOld") {
        CirclesSort("NewToOld");
      } else if (modes === "OldToNew") {
        CirclesSort("OldToNew");
      } else if (modes === "AtoZ") {
        CirclesSort("AtoZ");
      } else if (modes === "ZtoA") {
        CirclesSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // CirclesSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Projects" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Projects: modes,
      };
      if (modes === "NewToOld") {
        ProjectsSort("NewToOld");
      } else if (modes === "OldToNew") {
        ProjectsSort("OldToNew");
      } else if (modes === "AtoZ") {
        ProjectsSort("AtoZ");
      } else if (modes === "ZtoA") {
        ProjectsSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // ProjectsSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }
  };

  //................................ fOCUSED MODELS .........................................
  let Fcard = localStorage.getItem("fCard");
  let Findex = localStorage.getItem("fIndex");
  let FCardId = localStorage.getItem("fCardId");

  let fData = {
    card: Fcard ? Fcard : "",
    index: Findex ? Number(Findex) : "",
    id: FCardId ? FCardId : "",
  };

  // const [focused, setFocused] = useState({ card: '', index: null })

  if (focused.card.length === 0 && fData.card.length > 0) {
    setFocused(fData);
  }

  useEffect(() => {
    if (focused.card.length > 0 && focused.index > -1) {
      localStorage.setItem("fCard", focused.card);
      localStorage.setItem("fIndex", focused.index);
      localStorage.setItem("fCardId", focused.id);
      // scrollToDiv();
    }
  }, [focused, setFocused]);

  const scrollToDiv = (id) => {
    setTimeout(() => {
      /* const elements = document.getElementsByClassName("focused-card");
      if (elements.length > 0) {
        // elements[0].scrollIntoView({ behavior: 'smooth' });
        elements[0].scrollIntoView();
      } */

      if (focusedRef && focusListRef && focusListRef.current) {
        // console.log({ focusedRef });
        focusListRef.current.scrollTop = focusedRef;
        // console.log('after asigning scrolltop', focusListRef.current.scrollTop)
      }
    }, 100);
  };

  /* if (parentPeopleRef.current) {
    const scrollTop = parentPeopleRef.current.scrollTop;
    // console.log("Scroll position:", scrollTop);
    setFocusedRef(scrollTop);
  } */

  const handelFocused = (data) => {
    // console.log({ cardRef, parentPeopleRef, parentSkillRef, parentRoleRef, parentLinkRef, parentCircleRef, parentProjectRef });
    if (data.card === "People") {
      if (parentPeopleRef.current) {
        const scrollTop = parentPeopleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setFocusedRef(scrollTop);
      }
      setFocused(data);
      scrollToDiv();
    } else if (data.card === "Circles") {
      if (parentCircleRef.current) {
        const scrollTop = parentCircleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setFocusedRef(scrollTop);
      }
      setFocused(data);
      scrollToDiv();
    } else if (data.card === "Projects") {
      if (parentProjectRef.current) {
        const scrollTop = parentProjectRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setFocusedRef(scrollTop);
      }
      setFocused(data);
      scrollToDiv();
    } else if (data.card === "CPeople") {
      localStorage.setItem("fCard", "");
      localStorage.setItem("fIndex", null);
      localStorage.setItem("fCardId", null);
      setFocused({ card: "", index: null, id: null });
    } else if (data.card === "CCircles") {
      localStorage.setItem("fCard", "");
      localStorage.setItem("fIndex", null);
      localStorage.setItem("fCardId", null);
      setFocused({ card: "", index: null, id: null });
    } else if (data.card === "CProjects") {
      localStorage.setItem("fCard", "");
      localStorage.setItem("fIndex", null);
      localStorage.setItem("fCardId", null);
      setFocused({ card: "", index: null, id: null });
    }

    localStorage.removeItem("fCard");
    localStorage.removeItem("fIndex");
    localStorage.removeItem("FIndex");
    localStorage.removeItem("fCardId");
    localStorage.removeItem("popoverId");
    localStorage.removeItem("popoverIds");
    localStorage.removeItem("pfmemberId");
    localStorage.removeItem("pfmemberIds");
    localStorage.removeItem("fcData");
    localStorage.removeItem("fcDatas");
    localStorage.removeItem("dragPepleCard");
  };
  /* ............................. People focused conditions ............................. */
  //Skill
  let PfoSkills = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < skills.length; i++) {
      let data = skills[i].owners;
      if (data && data.length > 0) {
        let inc = skills[i].owners.includes(foPeople);
        if (inc) {
          PfoSkills.push(skills[i]);
        }
      }
    }
  }

  let PfouSkills = [...skills];
  if (PfoSkills && PfoSkills.length > 0) {
    for (let i = 0; i < PfoSkills.length; i++) {
      PfouSkills = PfouSkills.filter((e) => {
        return e._id !== PfoSkills[i]._id;
        // console.log(e._id === PfoSkills[i]._id)
      });
    }
  }

  //Roles
  let PfoRoles = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < roles.length; i++) {
      let data = roles[i].owners;
      if (data && data.length > 0) {
        let inc = roles[i].owners.includes(foPeople);
        if (inc) {
          PfoRoles.push(roles[i]);
        }
      }
    }
  }

  let PfouRoles = [...roles];
  if (PfoRoles && PfoRoles.length > 0) {
    for (let i = 0; i < PfoRoles.length; i++) {
      PfouRoles = PfouRoles.filter((e) => {
        return e._id !== PfoRoles[i]._id;
      });
    }
  }

  //Links
  let PfoLinks = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < links.length; i++) {
      let data = links[i].owner;
      if (data && data.length > 0) {
        let inc = [data].includes(foPeople);
        if (inc) {
          PfoLinks.push(links[i]);
        }
      }
    }
  }

  let PfouLinks = [...links];
  if (PfoLinks && PfoLinks.length > 0) {
    for (let i = 0; i < PfoLinks.length; i++) {
      PfouLinks = PfouLinks.filter((e) => {
        return e._id !== PfoLinks[i]._id;
        // console.log(e._id === PfoSkills[i]._id)
      });
    }
  }

  //Domains
  let PfoDomains = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < domains.length; i++) {
      if (domains[i].owners) {
        let data = domains[i].owners.owner;
        if (data && data.length > 0) {
          let inc = [data].includes(foPeople);
          if (inc) {
            PfoDomains.push(domains[i]);
          }
        }
      }

      if (
        domains[i].standIn &&
        domains[i].standIn.toString() === foPeople.toString()
      ) {
        PfoDomains.push(domains[i]);
      }
    }
  }

  // let PfouDomains = [...domains];
  let PfouDomains = [];
  if (Array.isArray(domains)) {
    PfouDomains = [...domains];
  }

  if (PfoDomains && PfoDomains.length > 0) {
    for (let i = 0; i < PfoDomains.length; i++) {
      PfouDomains = PfouDomains.filter((e) => {
        return e._id !== PfoDomains[i]._id;
        // console.log(e._id === PfoSkills[i]._id)
      });
    }
  }

  //Circles
  let PfoCircles = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    if (circles && circles.length > 0) {
      for (let i = 0; i < circles.length; i++) {
        if (
          circles[i].lead &&
          circles[i].lead.length > 0 &&
          circles[i].lead === foPeople
        ) {
          PfoCircles.push(circles[i]);
        }
      }

      if (roles && roles.length > 0) {
        for (let i = 0; i < circles.length; i++) {
          for (let j = 0; j < roles.length; j++) {
            if (
              circles[i].lead &&
              circles[i].lead.length > 0 &&
              circles[i].lead === roles[j]._id
            ) {
              let incs = roles[j].owners.includes(foPeople);
              if (incs) {
                PfoCircles.push(circles[i]);
                break;
              }
            }
          }
        }
      }

      if (skills && skills.length > 0) {
        for (let i = 0; i < circles.length; i++) {
          for (let j = 0; j < skills.length; j++) {
            if (
              circles[i].lead &&
              circles[i].lead.length > 0 &&
              circles[i].lead === skills[j]._id
            ) {
              let incs = skills[j].owners.includes(foPeople);
              if (incs) {
                PfoCircles.push(circles[i]);
                break;
              }
            }
          }
        }
      }
    }
  }

  //Project
  let PfoProjects = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    if (projectsSorted && projectsSorted.length > 0) {
      for (let i = 0; i < projectsSorted.length; i++) {
        if (
          projectsSorted[i] &&
          projectsSorted[i].lead &&
          projectsSorted[i].lead.length > 0 &&
          projectsSorted[i].lead === foPeople
        ) {
          PfoProjects.push(projectsSorted[i]);
        }
      }
      if (roles && roles.length > 0) {
        for (let i = 0; i < projectsSorted.length; i++) {
          for (let j = 0; j < roles.length; j++) {
            if (
              projectsSorted[i] &&
              projectsSorted[i].lead &&
              projectsSorted[i].lead.length > 0 &&
              projectsSorted[i].lead === roles[j]._id
            ) {
              let incs = roles[j].owners.includes(foPeople);
              if (incs) {
                PfoProjects.push(projectsSorted[i]);
                break;
              }
            }
          }
        }
      }
      if (skills && skills.length > 0) {
        for (let i = 0; i < projectsSorted.length; i++) {
          for (let j = 0; j < skills.length; j++) {
            if (
              projectsSorted[i] &&
              projectsSorted[i].lead &&
              projectsSorted[i].lead.length > 0 &&
              projectsSorted[i].lead === skills[j]._id
            ) {
              let incs = skills[j].owners.includes(foPeople);
              if (incs) {
                PfoProjects.push(projectsSorted[i]);
                break;
              }
            }
          }
        }
      }
    }
  }

  /* ............................. People focused action............................. */

  //Skills
  //Add focused owner in curent skills
  const PSkillAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/skill/update`;
    let skillSort = localStorage.getItem("skillSort");
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        skillName: data.skillName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerType: data.ownerType ? data.ownerType : "Single owner",
        owners: data.owners ? data.owners : [],
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };
      if (
        arr.ownerType === "Single owner" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [focused.id];
      }
      if (
        arr.ownerType === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [...arr.owners, focused.id];
      }

      /* Hot Change in redux store */
      let updatedSkills = [...skills];
      let target_index = updatedSkills.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedSkills.splice(target_index, 1, arr);
      }
      dispatch({ type: SKILLS_MODIFICATIONS, payload: updatedSkills });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      dispatch(
        dropUpdateSkills({
          ...arr,
          skillSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // setskillsSorted(skills);
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const PSkillSub = (data) => {
    freezeWindow();
    // console.log(2242, 'PSkillSub', data, focused);
    let urls = `${SERVER_URI}/user/skill/update`;
    let skillSort = localStorage.getItem("skillSort");
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        skillName: data.skillName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerType: data.ownerType ? data.ownerType : "Single owner",
        owners: data.owners ? data.owners : [],
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };

      if (arr.ownerType === "Single owner") {
        arr.owners = [];
      }

      if (
        arr.ownerType === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = arr.owners.filter((e) => {
          return e !== focused.id;
        });
      }

      let updatedArr = { ...arr };
      if (arr.domains && arr.domains.length > 0) {
        // Iterate through domains and update owner to null if personId is present in domain's owner
        updatedArr = {
          ...updatedArr,
          owners: arr.owners.filter((e) => e !== focused.id),
          domains: arr.domains.map((item) => ({
            ...item,
            owner: item.owner === focused.id ? null : item.owner,
          })),
        };
      }

      /* Hot Change in redux store */
      let updatedSkills = [...skills];
      let target_index = updatedSkills.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedSkills.splice(target_index, 1, updatedArr);
      }
      dispatch({ type: SKILLS_MODIFICATIONS, payload: updatedSkills });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      dispatch(
        dropUpdateSkills({
          ...updatedArr,
          skillSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // setskillsSorted(skills);
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  // Drag and drop
  const foSkillDrop = (e, action) => {
    // console.log(e, action);
  };

  //Roles
  //Add focused owner in curent skills
  const PRolesAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/role/update`;
    let roleSort = localStorage.getItem("roleSort");
    let domainSort = localStorage.getItem("domainSort");
    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");

    /* let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.currole = currole; */
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        roleName: data.roleName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerRole: data.ownerRole,
        owners: data.owners,
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };
      if (
        arr.ownerRole === "Single owner" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [focused.id];
      }
      if (
        arr.ownerRole === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [...arr.owners, focused.id];
      }
      // console.log(arr)

      /* Hot Change in redux store */
      let updatedRoles = [...roles];
      let target_index = updatedRoles.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedRoles.splice(target_index, 1, arr);
      }
      dispatch({ type: ROLES_MODIFICATIONS, payload: updatedRoles });
      /* Hot Change in redux store */

      dispatch(
        dropUpdateRole({
          ...arr,
          roleSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // dispatch(getRole({ ...obj, currole }));
        // dispatch(getDomain({ ...obj, domainSort }));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const PRolesSub = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/role/update`;
    let roleSort = localStorage.getItem("roleSort");
    let domainSort = localStorage.getItem("domainSort");
    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");
    /* currole = currole.split(",");
    // console.log(currole) */
    /* let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.currole = currole; */
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        roleName: data.roleName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerRole: data.ownerRole,
        owners: data.owners,
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };

      if (arr.ownerRole === "Single owner") {
        arr.owners = [];
      }

      if (
        arr.ownerRole === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = arr.owners.filter((e) => {
          return e !== focused.id;
        });
      }

      let updatedArr = { ...arr };
      if (arr.domains && arr.domains.length > 0) {
        // Iterate through domains and update owner to null if personId is present in domain's owner
        updatedArr = {
          ...updatedArr,
          owners: arr.owners.filter((e) => e !== focused.id),
          domains: arr.domains.map((item) => ({
            ...item,
            owner: item.owner === focused.id ? null : item.owner,
          })),
        };
      }

      /* Hot Change in redux store */
      let updatedRoles = [...roles];
      let target_index = updatedRoles.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedRoles.splice(target_index, 1, updatedArr);
      }
      dispatch({ type: ROLES_MODIFICATIONS, payload: updatedRoles });
      /* Hot Change in redux store */

      dispatch(
        dropUpdateRole({
          ...updatedArr,
          roleSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // dispatch(getRole({ ...obj, currole }));
        // dispatch(getDomain({ ...obj, domainSort }));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  //Domains
  const PDomainsAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/domain/update`;
    let curdomain = localStorage.getItem("domainSort");
    curdomain = curdomain.split(",");
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curdomain = curdomain;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        domainName: data.domainName,
        purpose: data.purpose,
        tasks: data.tasks,
        owners: data.owners ? data.owners : {},
        standIn: data.standIn ? data.standIn : null,
        memberIn: data.memberIn,
        tags: data.tags ? data.tags : [],
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr && focused && peopleSorted && peopleSorted.length > 0) {
        arr.owners = {
          type: "63d3c91d40c4cf67373922e2",
          owner: focused.id,
        };
      }
      // console.log(arr);

      /* Hot Change in redux store */
      let updatedDomains = [...domains];
      let target_index = updatedDomains.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedDomains.splice(target_index, 1, arr);
      }
      dispatch({ type: DOMAINS_MODIFICATIONS, payload: updatedDomains });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      dispatch(dropUpdateDomain({ ...arr, domainSort })).then(() => {
        // dispatch(getDomain(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const PDomainsSub = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/domain/update`;
    let curdomain = localStorage.getItem("domainSort");
    curdomain = curdomain.split(",");
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curdomain = curdomain;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        domainName: data.domainName,
        purpose: data.purpose,
        tasks: data.tasks,
        owners: data.owners ? data.owners : {},
        standIn: data.standIn ? data.standIn : null,
        tags: data.tags ? data.tags : [],
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr) {
        arr.owners = {
          type: data.owners && data.owners.type ? data.owners.type : null,
          owner: null,
        };
      }

      /* Hot Change in redux store */
      let updatedDomains = [...domains];
      let target_index = updatedDomains.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedDomains.splice(target_index, 1, arr);
      }
      dispatch({ type: DOMAINS_MODIFICATIONS, payload: updatedDomains });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      dispatch(dropUpdateDomain({ ...arr, domainSort })).then(() => {
        // dispatch(getDomain(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  //Links
  const PLinksAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/link/update`;
    let curlink = localStorage.getItem("linkSort");
    curlink = curlink.split(",");
    // console.log(curlink)
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curlink = curlink;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        linkName: data.linkName,
        purpose: data.purpose,
        tasks: data.tasks,
        owner: data.owner,
        standIn: data.standIn,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr && focused && peopleSorted && peopleSorted.length > 0) {
        arr.owner = focused.id;
      }
      // console.log(arr);

      /* Hot Change in redux store */
      let updatedLinks = [...links];
      let target_index = updatedLinks.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedLinks.splice(target_index, 1, arr);
      }
      dispatch({ type: LINKS_MODIFICATIONS, payload: updatedLinks });
      /* Hot Change in redux store */

      let linkSort = localStorage.getItem("linkSort");
      dispatch(dropUpdateLink({ ...arr, linkSort })).then(() => {
        // dispatch(getLink(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const PLinksSub = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/link/update`;
    let curlink = localStorage.getItem("linkSort");
    curlink = curlink.split(",");
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curlink = curlink;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        linkName: data.linkName,
        purpose: data.purpose,
        tasks: data.tasks,
        owner: data.owner,
        standIn: data.standIn,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr) {
        arr.owner = null;
      }

      /* Hot Change in redux store */
      let updatedLinks = [...links];
      let target_index = updatedLinks.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedLinks.splice(target_index, 1, arr);
      }
      dispatch({ type: LINKS_MODIFICATIONS, payload: updatedLinks });
      /* Hot Change in redux store */

      let linkSort = localStorage.getItem("linkSort");
      dispatch(dropUpdateLink({ ...arr, linkSort })).then(() => {
        // dispatch(getLink(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  /* ............................. Circle focused conditions ............................. */

  let curCoreMembers = null;
  let curExtendedMembers = null;
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    circlesSorted.length > 0
  ) {
    // console.log(2694, members)
    let cId = focused.id;
    for (let i = 0; i < members.length; i++) {
      if (members[i].memberType === cId) {
        curCoreMembers = members[i].coreMembers;
        curExtendedMembers = members[i].extendedMembers;
        break;
      }
    }
  }

  // console.log(curCoreMembers);

  //People
  let CcorfoPeople = [];
  // console.log(2847, curCoreMembers);
  if (
    focused.card &&
    peopleSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers
  ) {
    let isLead = false;

    for (let i = 0; i < peopleSorted.length; i++) {
      // Check if added as a person
      let inc = curCoreMembers.People.includes(peopleSorted[i]._id);
      if (inc) {
        CcorfoPeople.push(peopleSorted[i]);
      }

      // Check if the lead is a person
      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      if (circlesSorted && circlesSorted.length > 0 && focusedCircle.lead) {
        let leadOfCicleRedInHtml = focusedCircle.lead;
        let flag = true;
        if (CcorfoPeople && CcorfoPeople.length > 0) {
          for (let j = 0; j < CcorfoPeople.length; j++) {
            if (CcorfoPeople[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              isLead = true;
              break;
            }
          }
        }

        if (flag && !isLead) {
          if (leadOfCicleRedInHtml === peopleSorted[i]._id) {
            CcorfoPeople.push(peopleSorted[i]);
            isLead = true;
          }
        }
      }

      if (
        circlesSorted &&
        circlesSorted.length > 0 &&
        focusedCircle.standIn &&
        focusedCircle.lead !== focusedCircle.standIn
      ) {
        let leadOfCicleGrayInHtml = focusedCircle.standIn;
        let flag = true;
        if (CcorfoPeople && CcorfoPeople.length > 0) {
          for (let j = 0; j < CcorfoPeople.length; j++) {
            if (CcorfoPeople[j]._id === leadOfCicleGrayInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleGrayInHtml === peopleSorted[i]._id) {
            CcorfoPeople.push(peopleSorted[i]);
          }
        }
      }

      let currentFocusedCircleMembers = members.filter(
        (item) => item.memberType === focused.id
      );
      let currentFocusedCirclePFMembers = pfmembers.filter(
        (item) => item.memberType === focused.id
      );
      // console.log(currentFocusedCirclePFMembers);
      if (
        currentFocusedCircleMembers.length > 0 &&
        currentFocusedCirclePFMembers.length > 0
      ) {
        let skillMembers = currentFocusedCircleMembers[0].coreMembers.Skills;
        if (skillMembers.length > 0) {
          skillsSorted.forEach((item) => {
            let isInPfmembersCore =
              currentFocusedCirclePFMembers[0].coreMembers.Skills.find(
                (skill) =>
                  skill.CardId === item._id &&
                  skill.peopleIds === peopleSorted[i]._id
              ) !== undefined;
            if (
              skillMembers.includes(item._id) &&
              item.owners.includes(peopleSorted[i]._id) &&
              isInPfmembersCore
            ) {
              CcorfoPeople.push(peopleSorted[i]);
            }
          });
        }

        let roleMembers = currentFocusedCircleMembers[0].coreMembers.Roles;
        if (roleMembers.length > 0) {
          rolesSorted.forEach((item) => {
            let isInPfmembersCore =
              currentFocusedCirclePFMembers[0].coreMembers.Roles.find(
                (role) =>
                  role.CardId === item._id &&
                  role.peopleIds === peopleSorted[i]._id
              ) !== undefined;
            if (
              roleMembers.includes(item._id) &&
              item.owners.includes(peopleSorted[i]._id) &&
              isInPfmembersCore
            ) {
              CcorfoPeople.push(peopleSorted[i]);
            }
          });
        }
      }
    }

    let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
    if (
      !isLead &&
      circlesSorted &&
      circlesSorted.length > 0 &&
      focusedCircle.lead
    ) {
      let leadOfCicleRedInHtml = focusedCircle.lead;
      let curPLead = "";
      if (skillsSorted && skillsSorted.length > 0 && leadOfCicleRedInHtml) {
        for (let j = 0; j < skillsSorted.length; j++) {
          if (
            skillsSorted[j].ownerType === "Single owner" &&
            skillsSorted[j].owners &&
            skillsSorted[j].owners.length > 0
          ) {
            if (skillsSorted[j]._id === leadOfCicleRedInHtml) {
              curPLead = skillsSorted[j].owners[0];
              break;
            }
          }
        }
      }

      if (curPLead.length === 0) {
        if (rolesSorted && rolesSorted.length > 0 && leadOfCicleRedInHtml) {
          for (let j = 0; j < rolesSorted.length; j++) {
            if (
              rolesSorted[j].ownerRole === "Single owner" &&
              rolesSorted[j].owners &&
              rolesSorted[j].owners.length > 0
            ) {
              if (rolesSorted[j]._id === leadOfCicleRedInHtml) {
                curPLead = rolesSorted[j].owners[0];
                break;
              }
            }
          }
        }
      }

      if (CcorfoPeople && CcorfoPeople.length > 0) {
        for (let j = 0; j < CcorfoPeople.length; j++) {
          if (CcorfoPeople[j]._id === curPLead) {
            isLead = true;
            break;
          }
        }
      }

      if (
        !isLead &&
        curPLead &&
        curPLead.length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let j = 0; j < peopleSorted.length; j++) {
          if (peopleSorted[j]._id === curPLead) {
            CcorfoPeople.push(peopleSorted[j]);
            isLead = true;
            break;
          }
        }
      }
    }
  }

  let CextfoPeople = [];
  if (
    focused.card &&
    peopleSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers
  ) {
    for (let i = 0; i < peopleSorted.length; i++) {
      let inc = curExtendedMembers.People.includes(peopleSorted[i]._id);
      if (inc) {
        CextfoPeople.push(peopleSorted[i]);
      }
    }
  }

  let CedeffoPeople = [...peopleSorted];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.People &&
    curExtendedMembers.People.length >= 0 &&
    curCoreMembers.People &&
    curCoreMembers.People.length >= 0
  ) {
    let cusPeople = [...curCoreMembers.People, ...curExtendedMembers.People];
    if (cusPeople.length > 0) {
      for (let i = 0; i < cusPeople.length; i++) {
        CedeffoPeople = CedeffoPeople.filter((e) => {
          return e._id !== cusPeople[i];
        });
      }
    }
  }

  //Skills
  let CcorfoSkill = [];
  if (
    focused.card &&
    skillsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles"
  ) {
    for (let i = 0; i < skillsSorted.length; i++) {
      if (
        curCoreMembers &&
        curCoreMembers.Skills &&
        curCoreMembers.Skills.length > 0
      ) {
        let inc = curCoreMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CcorfoSkill.push(skillsSorted[i]);
        }
      }

      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      if (
        focusedCircle &&
        circlesSorted &&
        circlesSorted.length > 0 &&
        focusedCircle.lead
      ) {
        let leadOfCicleRedInHtml = focusedCircle.lead;
        let flag = true;
        if (CcorfoSkill && CcorfoSkill.length > 0) {
          for (let j = 0; j < CcorfoSkill.length; j++) {
            if (CcorfoSkill[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === skillsSorted[i]._id) {
            CcorfoSkill.push(skillsSorted[i]);
          }
        }
      }
    }
  }

  let CextfoSkill = [];
  if (
    focused.card &&
    skillsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Skills &&
    curExtendedMembers.Skills.length > 0
  ) {
    for (let i = 0; i < skillsSorted.length; i++) {
      let inc = curExtendedMembers.Skills.includes(skillsSorted[i]._id);
      if (inc) {
        CextfoSkill.push(skillsSorted[i]);
      }
    }
  }

  let CedeffoSkill = [...skillsSorted];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    (CcorfoSkill.length > 0 || CextfoSkill.length >= 0)
  ) {
    let cusSkills = [...CcorfoSkill, ...CextfoSkill];
    if (cusSkills.length > 0) {
      CedeffoSkill = CedeffoSkill.filter((e) => {
        for (let i = 0; i < cusSkills.length; i++) {
          return e._id !== cusSkills[i]._id;
        }
      });
    }
  }

  //Roles
  let CcorfoRoles = [];
  if (
    focused.card &&
    rolesSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles"
  ) {
    for (let i = 0; i < rolesSorted.length; i++) {
      if (
        curCoreMembers &&
        curCoreMembers.Roles &&
        curCoreMembers.Roles.length > 0
      ) {
        let inc = curCoreMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CcorfoRoles.push(rolesSorted[i]);
        }
      }

      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      if (
        focusedCircle &&
        circlesSorted &&
        circlesSorted.length > 0 &&
        focusedCircle.lead
      ) {
        let leadOfCicleRedInHtml = focusedCircle.lead;
        let flag = true;
        if (CcorfoRoles && CcorfoRoles.length > 0) {
          for (let j = 0; j < CcorfoRoles.length; j++) {
            if (CcorfoRoles[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === rolesSorted[i]._id) {
            CcorfoRoles.push(rolesSorted[i]);
          }
        }
      }
    }
  }

  let CextfoRoles = [];
  if (
    focused.card &&
    rolesSorted.length &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Roles &&
    curExtendedMembers.Roles.length > 0
  ) {
    for (let i = 0; i < rolesSorted.length; i++) {
      let inc = curExtendedMembers.Roles.includes(rolesSorted[i]._id);
      if (inc) {
        CextfoRoles.push(rolesSorted[i]);
      }
    }
  }

  let CedeffoRoles = [...rolesSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    (CcorfoRoles.length > 0 || CextfoRoles.length > 0)
  ) {
    let cusRoles = [...CcorfoRoles, ...CextfoRoles];
    if (cusRoles.length > 0) {
      CedeffoRoles = CedeffoRoles.filter((e) => {
        for (let i = 0; i < cusRoles.length; i++) {
          return e._id !== cusRoles[i]._id;
        }
      });
    }
  }

  //Links
  let CcorfoLinks = [];
  if (
    focused.card &&
    linksSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Links &&
    curCoreMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curCoreMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        CcorfoLinks.push(linksSorted[i]);
      }
    }
  }

  let CextfoLinks = [];
  if (
    focused.card &&
    linksSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Links &&
    curExtendedMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curExtendedMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        CextfoLinks.push(linksSorted[i]);
      }
    }
  }

  let CedeffoLinks = [...linksSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Roles &&
    curExtendedMembers.Roles.length >= 0 &&
    curCoreMembers.Roles &&
    curCoreMembers.Roles.length >= 0
  ) {
    let cusLinks = [...curCoreMembers.Links, ...curExtendedMembers.Links];
    if (cusLinks.length > 0) {
      for (let i = 0; i < cusLinks.length; i++) {
        CedeffoLinks = CedeffoLinks.filter((e) => {
          return e._id !== cusLinks[i];
        });
      }
    }
  }

  //Domains
  let CcorfoDomains = [];
  if (
    focused.card &&
    domainsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Domains &&
    curCoreMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curCoreMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        CcorfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let CextfoDomains = [];
  if (
    focused.card &&
    domainsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Domains &&
    curExtendedMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curExtendedMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        CextfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let CedeffoDomains = [...domainsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Domains &&
    curExtendedMembers.Domains.length >= 0 &&
    curCoreMembers.Domains &&
    curCoreMembers.Domains.length >= 0
  ) {
    let cusDomains = [...curCoreMembers.Domains, ...curExtendedMembers.Domains];
    if (cusDomains.length > 0) {
      for (let i = 0; i < cusDomains.length; i++) {
        CedeffoDomains = CedeffoDomains.filter((e) => {
          return e._id !== cusDomains[i];
        });
      }
    }
  }

  //Projects
  let CcorfoProjects = [];
  if (
    focused.card &&
    projectsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Projects &&
    curCoreMembers.Projects.length > 0
  ) {
    for (let i = 0; i < projectsSorted.length; i++) {
      let inc = curCoreMembers.Projects.includes(projectsSorted[i]._id);
      if (inc) {
        CcorfoProjects.push(projectsSorted[i]);
      }
    }
  }
  // console.log(CextfoPeople)

  let CedeffoProjects = [...projectsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Projects &&
    curCoreMembers.Projects.length >= 0
  ) {
    let cusProjects = [...curCoreMembers.Projects];
    if (cusProjects.length > 0) {
      for (let i = 0; i < cusProjects.length; i++) {
        CedeffoProjects = CedeffoProjects.filter((e) => {
          return e._id !== cusProjects[i];
        });
      }
    }
  }

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Projects focused cunditions.............................

  let curPCoreMembers = {};
  let curPExtendedMembers = {};
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    projectsSorted.length > 0
  ) {
    let cId = focused.id;
    for (let i = 0; i < members.length; i++) {
      if (members[i].memberType === cId) {
        curPCoreMembers = members[i].coreMembers;
        curPExtendedMembers = members[i].extendedMembers;
        break;
      }
    }
  }

  //People
  let PrcorfoPeople = [];
  if (focused.card && focused.card.length > 0 && focused.card === "Projects") {
    let isLead = false;
    for (let i = 0; i < peopleSorted.length; i++) {
      if (
        curPCoreMembers &&
        curPCoreMembers.People &&
        curPCoreMembers.People.length > 0
      ) {
        let inc = curPCoreMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          PrcorfoPeople.push(peopleSorted[i]);
        }
      }

      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.lead
      ) {
        let leadOfCicleRedInHtml = focusedProject.lead;
        let flag = true;
        if (PrcorfoPeople && PrcorfoPeople.length > 0) {
          for (let j = 0; j < PrcorfoPeople.length; j++) {
            if (PrcorfoPeople[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              isLead = true;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === peopleSorted[i]._id) {
            PrcorfoPeople.push(peopleSorted[i]);
            isLead = true;
          }
        }
      }

      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.standIn &&
        focusedProject.lead !== focusedProject.standIn
      ) {
        let leadOfCicleGrayInHtml = focusedProject.standIn;
        let flag = true;
        if (PrcorfoPeople && PrcorfoPeople.length > 0) {
          for (let j = 0; j < PrcorfoPeople.length; j++) {
            if (PrcorfoPeople[j]._id === leadOfCicleGrayInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleGrayInHtml === peopleSorted[i]._id) {
            PrcorfoPeople.push(peopleSorted[i]);
          }
        }
      }
    }

    let focusedProject = projectsSorted.find((p) => p._id === focused.id);
    if (
      !isLead &&
      projectsSorted &&
      projectsSorted.length > 0 &&
      focusedProject &&
      focusedProject.lead
    ) {
      let leadOfCicleRedInHtml = focusedProject.lead;
      let curPLead = "";
      if (skillsSorted && skillsSorted.length > 0 && leadOfCicleRedInHtml) {
        for (let j = 0; j < skillsSorted.length; j++) {
          if (
            skillsSorted[j].ownerType === "Single owner" &&
            skillsSorted[j].owners &&
            skillsSorted[j].owners.length > 0
          ) {
            if (skillsSorted[j]._id === leadOfCicleRedInHtml) {
              curPLead = skillsSorted[j].owners[0];
              break;
            }
          }
        }
      }
      if (!curPLead) {
        if (rolesSorted && rolesSorted.length > 0 && leadOfCicleRedInHtml) {
          for (let j = 0; j < rolesSorted.length; j++) {
            if (
              rolesSorted[j].ownerRole === "Single owner" &&
              rolesSorted[j].owners &&
              rolesSorted[j].owners.length > 0
            ) {
              if (rolesSorted[j]._id === leadOfCicleRedInHtml) {
                curPLead = rolesSorted[j].owners[0];
                break;
              }
            }
          }
        }
      }
      if (PrcorfoPeople && PrcorfoPeople.length > 0) {
        for (let j = 0; j < PrcorfoPeople.length; j++) {
          if (PrcorfoPeople[j]._id === curPLead) {
            isLead = true;
            break;
          }
        }
      }
      if (
        !isLead &&
        curPLead &&
        curPLead.length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let j = 0; j < peopleSorted.length; j++) {
          if (peopleSorted[j]._id === curPLead) {
            PrcorfoPeople.push(peopleSorted[j]);
            isLead = true;
            break;
          }
        }
      }
    }
  }

  let PrextfoPeople = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.People &&
    curPExtendedMembers.People.length > 0
  ) {
    for (let i = 0; i < peopleSorted.length; i++) {
      let inc = curPExtendedMembers.People.includes(peopleSorted[i]._id);
      if (inc) {
        PrextfoPeople.push(peopleSorted[i]);
      }
    }
  }

  let PredeffoPeople = [...peopleSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.People &&
    curPExtendedMembers.People.length >= 0 &&
    curPCoreMembers &&
    curPCoreMembers.People &&
    curPCoreMembers.People.length >= 0
  ) {
    let cusPeople = [...curPCoreMembers.People, ...curPExtendedMembers.People];
    if (cusPeople.length > 0) {
      for (let i = 0; i < cusPeople.length; i++) {
        PredeffoPeople = PredeffoPeople.filter((e) => {
          return e._id !== cusPeople[i];
        });
      }
    }
  }

  //Skills
  let PrcorfoSkill = [];
  if (focused.card && focused.card.length > 0 && focused.card === "Projects") {
    for (let i = 0; i < skillsSorted.length; i++) {
      if (
        curPCoreMembers &&
        curPCoreMembers.Skills &&
        curPCoreMembers.Skills.length > 0
      ) {
        let inc = curPCoreMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          PrcorfoSkill.push(skillsSorted[i]);
        }
      }
      // console.log(3325, projectsSorted[focused.index])

      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.lead
      ) {
        let leadOfCicleRedInHtml = focusedProject.lead;
        let flag = true;
        if (PrcorfoSkill && PrcorfoSkill.length > 0) {
          for (let j = 0; j < PrcorfoSkill.length; j++) {
            if (PrcorfoSkill[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === skillsSorted[i]._id) {
            PrcorfoSkill.push(skillsSorted[i]);
          }
        }
      }
    }
  }

  let PrextfoSkill = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Skills &&
    curPExtendedMembers.Skills.length > 0
  ) {
    for (let i = 0; i < skillsSorted.length; i++) {
      let inc = curPExtendedMembers.Skills.includes(skillsSorted[i]._id);
      if (inc) {
        PrextfoSkill.push(skillsSorted[i]);
      }
    }
  }

  let PredeffoSkill = [...skillsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    (PrcorfoSkill.length > 0 || PrextfoSkill.length > 0)
  ) {
    let cusSkills = [...PrcorfoSkill, ...PrextfoSkill];
    if (cusSkills.length > 0) {
      PredeffoSkill = PredeffoSkill.filter((e) => {
        for (let i = 0; i < cusSkills.length; i++) {
          return e._id !== cusSkills[i]._id;
        }
      });
    }
  }

  //Roles
  let PrcorfoRoles = [];
  if (focused.card && focused.card.length > 0 && focused.card === "Projects") {
    for (let i = 0; i < rolesSorted.length; i++) {
      if (
        curPCoreMembers &&
        curPCoreMembers.Roles &&
        curPCoreMembers.Roles.length > 0
      ) {
        let inc = curPCoreMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          PrcorfoRoles.push(rolesSorted[i]);
        }
      }

      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.lead
      ) {
        let leadOfCicleRedInHtml = focusedProject.lead;
        let flag = true;
        if (PrcorfoRoles && PrcorfoRoles.length > 0) {
          for (let j = 0; j < PrcorfoRoles.length; j++) {
            if (PrcorfoRoles[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === rolesSorted[i]._id) {
            PrcorfoRoles.push(rolesSorted[i]);
          }
        }
      }
    }
  }

  let PrextfoRoles = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Roles &&
    curPExtendedMembers.Roles.length > 0
  ) {
    for (let i = 0; i < rolesSorted.length; i++) {
      let inc = curPExtendedMembers.Roles.includes(rolesSorted[i]._id);
      if (inc) {
        PrextfoRoles.push(rolesSorted[i]);
      }
    }
  }

  let PredeffoRoles = [...rolesSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    (PrcorfoRoles.length > 0 || PrextfoRoles.length > 0)
  ) {
    let cusRoles = [...PrcorfoRoles, ...PrextfoRoles];
    if (cusRoles.length > 0) {
      PredeffoRoles = PredeffoRoles.filter((e) => {
        for (let i = 0; i < cusRoles.length; i++) {
          return e._id !== cusRoles[i]._id;
        }
      });
    }
  }

  //Links
  let PrcorfoLinks = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Links &&
    curPCoreMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curPCoreMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        PrcorfoLinks.push(linksSorted[i]);
      }
    }
  }

  let PrextfoLinks = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Links &&
    curPExtendedMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curPExtendedMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        PrextfoLinks.push(linksSorted[i]);
      }
    }
  }

  let PredeffoLinks = [...linksSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Roles &&
    curPExtendedMembers.Roles.length >= 0 &&
    curPCoreMembers &&
    curPCoreMembers.Roles &&
    curPCoreMembers.Roles.length >= 0
  ) {
    let cusLinks = [...curPCoreMembers.Links, ...curPExtendedMembers.Links];
    if (cusLinks.length > 0) {
      for (let i = 0; i < cusLinks.length; i++) {
        PredeffoLinks = PredeffoLinks.filter((e) => {
          return e._id !== cusLinks[i];
        });
      }
    }
  }

  //Domains
  let PrcorfoDomains = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Domains &&
    curPCoreMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curPCoreMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        PrcorfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let PrextfoDomains = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Domains &&
    curPExtendedMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curPExtendedMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        PrextfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let PredeffoDomains = [...domainsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Domains &&
    curPExtendedMembers.Domains.length >= 0 &&
    curPCoreMembers &&
    curPCoreMembers.Domains &&
    curPCoreMembers.Domains.length >= 0
  ) {
    let cusDomains = [
      ...curPCoreMembers.Domains,
      ...curPExtendedMembers.Domains,
    ];
    if (cusDomains.length > 0) {
      for (let i = 0; i < cusDomains.length; i++) {
        PredeffoDomains = PredeffoDomains.filter((e) => {
          return e._id !== cusDomains[i];
        });
      }
    }
  }

  //Circles
  let PrcorfoCircles = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Circles &&
    curPCoreMembers.Circles.length > 0
  ) {
    for (let i = 0; i < circlesSorted.length; i++) {
      let inc = curPCoreMembers.Circles.includes(circlesSorted[i]._id);
      if (inc) {
        PrcorfoCircles.push(circlesSorted[i]);
      }
    }
  }

  let PredeffoCircles = [...circlesSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Projects &&
    curPCoreMembers.Projects.length >= 0
  ) {
    let cusCircles = [...curPCoreMembers.Circles];
    if (cusCircles.length > 0) {
      for (let i = 0; i < cusCircles.length; i++) {
        PredeffoCircles = PredeffoCircles.filter((e) => {
          return e._id !== cusCircles[i];
        });
      }
    }
  }
  //>>>>>>>>>>>People focused cunditions drag and drop.............................

  //skills
  const skillsDrops = (e, type) => {
    if (type === "defSkill") {
      PSkillAdd(e);
    }
    if (type === "corSkill") {
      PSkillSub(e);
    }
  };

  //Roles
  const rolesDrops = (e, type) => {
    if (type === "defRoles") {
      PRolesAdd(e);
    }
    if (type === "corRoles") {
      PRolesSub(e);
    }
  };

  //Links
  const linksDrops = (e, type) => {
    if (type === "defLinks") {
      PLinksAdd(e);
    }
    if (type === "corLinks") {
      PLinksSub(e);
    }
  };

  //Domains
  const domainsDrops = (e, type) => {
    if (type === "defDomains") {
      PDomainsAdd(e);
    }
    if (type === "corDomains") {
      PDomainsSub(e);
    }
  };

  //>>>>>>>>>>> Circle focused cunditions drag and drop.............................

  //............................(allSearch)/..................................................

  var ListData = [];

  // ....................................EXTA And LEAD..........................................................

  const dWhoIsOwnerType = (e) => {
    // console.log(3831, e.owners.type, '63d3c91d40c4cf67373922e2');

    if (e.owners && e.owners.type && e.owners.type.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        if (e.owners.type === skillsSorted[i]._id) {
          // console.log(e.domainName, skillsSorted[i]._id, skillsSorted[i].skillName);
          return {
            cardId: skillsSorted[i]._id,
            name: skillsSorted[i].skillName + ` (${GetAliasesName(teams, "Skills")})`,
            type: "Skill",
          };
        }
      }

      for (let i = 0; i < rolesSorted.length; i++) {
        if (e.owners && e.owners.type === rolesSorted[i]._id) {
          // console.log(e.domainName, rolesSorted[i]._id, rolesSorted[i].roleName);
          return {
            cardId: rolesSorted[i]._id,
            name: rolesSorted[i].roleName + ` (${GetAliasesName(teams, "Roles")})`,
            type: "Role",
          };
        }
      }
    }

    return {};
  };

  // Check if skill / role/ link/ domain owner is external type
  const IsOwnerExternal = (e, cardType) => {
    if (!e) return false;

    let allExternalPeopleIds = peopleSorted
      .filter((ps) => ps.workRole === "External")
      .map((ps) => ps._id);
    
      // console.log({allExternalPeopleIds});

    switch (cardType) {
      case "Skill":
      case "Role":
        if (e.owners) {
          let exists = e.owners.filter((value) =>
            allExternalPeopleIds.includes(value)
          );
          return exists.length > 0 ? true : false;
        } else {
          return false;
        }
        break;

      case "Domain":
        if (e.owners && e.owners.owner && e.owners.owner.length > 0) {
          let exists = allExternalPeopleIds.includes(e.owners.owner);
          return exists ? true : false;
        }
        break;

      case "Link":
        if (e.owner && e.owner.length > 0) {
          let exists = allExternalPeopleIds.includes(e.owner);
          return exists ? true : false;
        }
        break;

      default:
        return false;
    }
  };

  const OwnerExist = (owners) => {
    let exist = false;
    for (let i = 0; i < owners.length; i++) {
      if (
        peopleSorted.find((ps) => ps._id.toString() === owners[i].toString())
      ) {
        exist = true;
        break;
      }
    }
    return exist;
  };

  const isExistOwnerType = (ownerType) => {
    let exist = false;
    if (ownerType === "63d3c91d40c4cf67373922e2") {
      exist = true;
    } else {
      const arr = [...skills, ...roles, ...domains, ...links];
      let found = arr.find((se) => se._id === ownerType);
      if (found) {
        exist = true;
      }
    }
    return exist;
  }

  const dIsDirectMember = (e) => {
    // console.log(e.owners.type);
    if (e.owners && e.owners.type === "63d3c91d40c4cf67373922e2") {
      return true;
    } else {
      return false;
    }
  };

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    focused.index > -1 &&
    projectsSorted &&
    projectsSorted.length > 0
  ) {
    let focusedProject = projectsSorted.find((p) => p._id === focused.id);

    if (
      (focusedProject &&
        focusedProject.lead &&
        focusedProject.lead.length > 0) ||
      (focusedProject &&
        focusedProject.standIn &&
        focusedProject.standIn.length > 0)
    ) {
      let leadOfProjectRed = focusedProject.lead;
    }
    // console.log(PrcorfoDomains);
  }

  if (
    focused.card &&
    focused.card.length > 0 &&
    circlesSorted.length > 0 &&
    focused.card === "Circles" &&
    focused.index > -1
  ) {
    let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
    if (
      (focusedCircle && focusedCircle.lead && focusedCircle.lead.length > 0) ||
      (focusedCircle &&
        focusedCircle.standIn &&
        focusedCircle.standIn.length > 0)
    ) {
      let leadOfCircleRed = focusedCircle.lead;
    }
  }

  //............................................. IsExternal or not ......................................
  const pCardHideOnchange = (data) => {
    if (data === "People") {
      setListHide({
        ...listHide,
        People: true,
      });
    }
    if (data === "Skills") {
      setListHide({
        ...listHide,
        Skills: true,
      });
    }
    if (data === "Roles") {
      setListHide({
        ...listHide,
        Roles: true,
      });
    }
    if (data === "Domains") {
      setListHide({
        ...listHide,
        Domains: true,
      });
    }
    if (data === "Links") {
      setListHide({
        ...listHide,
        Links: true,
      });
    }
    if (data === "Circles") {
      setListHide({
        ...listHide,
        Circles: true,
      });
    }
    if (data === "Projects") {
      setListHide({
        ...listHide,
        Projects: true,
      });
    }
  };

  //  ........................................................Note Action..............................................
  // .................................................................................................................

  const [noteSaveData, setNoteSaveData] = useState({
    People: "",
    Skills: "",
    Roles: "",
    Domains: "",
    Links: "",
    Circles: "",
    Projects: "",
  });

  useEffect(() => {
    let obj = {
      People: "",
      Skills: "",
      Roles: "",
      Domains: "",
      Links: "",
      Circles: "",
      Projects: "",
    };

    if (noteData && noteData.length > 0) {
      let new_obj = { ...obj }; // Create a new object to store the updates
      for (let i = 0; i < noteData.length; i++) {
        new_obj[noteData[i].noteCard] = noteData[i].noteMessage; // Update the property
      }
      setNoteSaveData(new_obj); // Set the updated object in NoteSaveData
    }
  }, [noteData]);

  const handleOnKeyUpSave = (data) => {
    // console.log(data, 'as');
    if (data === "People" && noteSaveData.People.length > 0) {
      // dispatch(etItem('teamId'),
      //   noteCard: data,
      //   noteMessage: noteSaveData.People
      // }))
    }
  };

  const handleSave = (e) => {
    setNoteSaveData({
      ...noteSaveData,
      [e.target.name]: e.target.value,
    });
    saveNotes(e);
  };

  const saveNotes = (e) => {
    dispatch(
      noteCreate({
        teamId: localStorage.getItem("teamId"),
        noteCard: e.target.name,
        noteMessage: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (stripeUrl && Object.keys(stripeUrl).length > 0 && stripeUrl.url) {
      window.location.href = `${stripeUrl.url}`;
    }
  }, [stripeUrl]);

  //=========================================PEOPLE Standard algorithm===================================================
  // People
  const peopleFrom = () => {
    if (lFromState.Peoples === false) {
      if (
        curUserData &&
        Object.keys(curUserData).length > 0 &&
        curUserData.plan === "FREE" &&
        myInfo.role !== "Co-Admin"
      ) {
        if (peopleSorted.length >= CARD_LIMIT) {
          Swal.fire({
            // icon: "info",
            title: "Maximize Your Team's Potential!",
            showCancelButton: true,
            text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited Boards and team members!",
            confirmButtonText: "Upgrade now",
            cancelButtonText: `Close`,
            cancelButtonColor: "#EE7364",
          }).then((result) => {
            if (result.isConfirmed) {
              freezeWindow();
              dispatch(
                generateStripeUpgradeUrl({
                  userId: curUserData._id,
                  plan: "GO",
                  interval: "month",
                  email: curUserData.email,
                })
              );
              // window.location.href = `${CLIENT_URL}/pricing`
            }
          });
        } else if (peopleSorted.length < CARD_LIMIT) {
          setLFromsState({
            Peoples: !lFromState.Peoples,
            Skills: false,
            Roles: false,
            Domains: false,
            Links: false,
            Circles: false,
            Projects: false,
          });
        }
      } else {
        setLFromsState({
          ...lFromState,
          ["Peoples"]: !lFromState.Peoples,
        });
      }
    } else {
      setLFromsState({
        ...lFromState,
        ["Peoples"]: !lFromState.Peoples,
      });
    }
    // console.log(peoples)
  };

  const pref = useRef(null);
  const cursorPos = useRef(null);

  function retainCursorPosition(refObj) {
    if (refObj.current && cursorPos.current !== null) {
      refObj.current.setSelectionRange(cursorPos.current, cursorPos.current);
    }
  }

  useEffect(() => {
    if (lFromState.Peoples && pref.current !== document.activeElement) {
      pref.current.focus();
    }

    retainCursorPosition(pref);
  }, [lFromState, fName]);

  const pfNameHandel = (e) => {
    if (e.key === "Enter") {
      peopleSubmit(e);
      // peopleFrom()
    }
  };

  const addNewPeoples = () => {
    if (
      curUserData &&
      Object.keys(curUserData).length > 0 &&
      !curUserData.session_id
    ) {
      if (peopleSorted.length >= CARD_LIMIT) {
        Swal.fire({
          // icon: "info",
          title: "Maximize Your Team's Potential!",
          showCancelButton: true,
          text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited Boards and team members!",
          confirmButtonText: "Upgrade now",
          cancelButtonText: `Close`,
          cancelButtonColor: "#EE7364",
        }).then((result) => {
          if (result.isConfirmed) {
            freezeWindow();
            dispatch(
              generateStripeUpgradeUrl({
                userId: curUserData._id,
                plan: "GO",
                interval: "month",
                email: curUserData.email,
              })
            );
            // window.location.href = "https://app.teamdecoder.com/pricing"
          }
        });
      } else if (peopleSorted.length < CARD_LIMIT) {
        setPepoles();
      }
    } else {
      setPepoles();
    }
  };

  const [peopleSearch, setPeopleSearch] = useState("");
  const spref = useRef(null);
  useEffect(() => {
    if (peopleSearch.length > 0) {
      spref.current.focus();
    }
  }, [peopleSearch]);

  var filteredPData = [];

  if (
    (peopleSearch.length > 0 || allSearch.length > 0) &&
    peopleSorted.length > 0
  ) {
    filteredPData = peopleSorted.filter((value) => {
      const searchStr = peopleSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.fname} ${value.lname}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("People");
    }
  }

  if (
    filteredPData.length === 0 &&
    peopleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPData = [...peopleSorted];
  }

  let nonFiltePDta = [];
  if (peopleSearch.length > 0 && filteredPData.length !== peopleSorted.length) {
    nonFiltePDta = [...peopleSorted];
    for (let i = 0; i < filteredPData.length; i++) {
      nonFiltePDta = nonFiltePDta.filter((value, key) => {
        return value._id !== filteredPData[i]._id;
      });
    }
    if (filteredPData.length === 0) {
      nonFiltePDta = [...peopleSorted];
    }
  }

  let pnoteRef = useRef(null);
  var pnotePeopleData = noteSaveData.People;
  useEffect(() => {
    // console.log(pnoteRef , pnoteRef.current)
    if (
      pnoteRef &&
      pnoteRef.current &&
      noteSaveData.People &&
      noteSaveData.People.length > 0 &&
      !focusMode
    ) {
      pnoteRef.current.focus();
    }
  }, [note.People, noteSaveData.People]);

  // Skills
  const skillsFrom = () => {
    if (lFromState.Skills === false) {
      setLFromsState({
        Peoples: false,
        Skills: !lFromState.Skills,
        Roles: false,
        Domains: false,
        Links: false,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Skills"]: !lFromState.Skills,
      });
    }
  };

  const sref = useRef(null);
  useEffect(() => {
    if (lFromState.Skills && sref.current !== document.activeElement) {
      sref.current.focus();
    }

    retainCursorPosition(sref);
  }, [lFromState, skill]);

  const [skillSearch, setSkillSearch] = useState("");

  const sSref = useRef(null);
  useEffect(() => {
    if (skillSearch.length > 0) {
      sSref.current.focus();
    }
  }, [skillSearch]);

  var filteredSData = [];

  if (
    (skillSearch.length > 0 || allSearch.length > 0) &&
    skillsSorted.length > 0
  ) {
    filteredSData = skillsSorted.filter((value) => {
      const searchStr = skillSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredSData.length > 0) {
      // list = list.filter((e)=>{e !== 'Skills'})
      ListData.push("Skills");
    }
  }

  if (
    filteredSData.length === 0 &&
    skillSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredSData = [...skillsSorted];
  }

  let nonFilteSData = [];
  if (skillSearch.length > 0 && filteredSData.length !== skillsSorted.length) {
    nonFilteSData = [...skillsSorted];
    for (let i = 0; i < filteredSData.length; i++) {
      nonFilteSData = nonFilteSData.filter((value, key) => {
        return value._id !== filteredSData[i]._id;
      });
    }
    if (filteredSData.length === 0) {
      nonFilteSData = [...skillsSorted];
    }
  }

  // Roles
  const rolesFrom = () => {
    if (lFromState.Roles === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: !lFromState.Roles,
        Domains: false,
        Links: false,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Roles"]: !lFromState.Roles,
      });
    }
  };

  const rref = useRef(null);
  useEffect(() => {
    if (lFromState.Roles && rref.current !== document.activeElement) {
      rref.current.focus();
    }

    retainCursorPosition(rref);
  }, [lFromState, role]);

  const [roleSearch, setRoleSearch] = useState("");
  const sRref = useRef(null);
  useEffect(() => {
    if (roleSearch) {
      sRref.current.focus();
    }
  }, [roleSearch]);

  var filteredRData = [];
  if (
    (roleSearch.length > 0 || allSearch.length > 0) &&
    rolesSorted.length > 0
  ) {
    filteredRData = rolesSorted.filter((value) => {
      const searchStr = roleSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredRData.length > 0) {
      ListData.push("Roles");
    }
  }

  if (
    filteredRData.length === 0 &&
    roleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredRData = [...rolesSorted];
  }

  let nonFilteRData = [];
  if (roleSearch.length > 0 && filteredRData.length !== rolesSorted.length) {
    nonFilteRData = [...rolesSorted];
    for (let i = 0; i < filteredRData.length; i++) {
      nonFilteRData = nonFilteRData.filter((value, key) => {
        return value._id !== filteredRData[i]._id;
      });
    }
    if (filteredRData.length === 0) {
      nonFilteRData = [...rolesSorted];
    }
  }

  // Domains
  const domainsFrom = () => {
    if (lFromState.Domains === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: !lFromState.Domains,
        Links: false,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Domains"]: !lFromState.Domains,
      });
    }
  };

  const dref = useRef(null);
  useEffect(() => {
    if (lFromState.Domains && dref.current !== document.activeElement) {
      dref.current.focus();
    }

    retainCursorPosition(dref);
  }, [lFromState, domain]);

  const [domainSearch, setDomainSearch] = useState("");

  const sDref = useRef(null);
  useEffect(() => {
    if (domainSearch.length > 0) {
      sDref.current.focus();
    }
  }, [domainSearch]);



  var filteredDData = [];

  if (
    (domainSearch.length > 0 || allSearch.length > 0) &&
    domainsSorted.length > 0
  ) {
    filteredDData = [...domainsSorted, ...dDomains].filter((value) => {
      const searchStr = domainSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });

    if (allSearch && allSearch.length > 0 && filteredDData.length > 0) {
      ListData.push("Domains");
    }
  }

  if (
    filteredDData.length === 0 &&
    domainSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredDData = [...domainsSorted];
  }

  let nonFilteDData = [];
  if (
    domainSearch.length > 0 &&
    filteredDData.length !== domainsSorted.length
  ) {
    nonFilteDData = [...domainsSorted];
    for (let i = 0; i < filteredDData.length; i++) {
      nonFilteDData = nonFilteDData.filter((value, key) => {
        return value._id !== filteredDData[i]._id;
      });
    }
    if (filteredDData.length === 0) {
      nonFilteDData = [...domainsSorted];
    }
  }

  const openSkillAndRoleByDPill = (data) => {
    if (data && data.type === "Skill") {
      skillsSorted.filter((value, key) => {
        if (value._id === data.Id) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (data && data.type === "Role") {
      rolesSorted.filter((value, key) => {
        if (value._id === data.Id) {
          indexSet(value);
          openRole();
        }
      });
    }
  };

  // Links
  const linksFrom = () => {
    if (lFromState.Links === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: false,
        Links: !lFromState.Links,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Links"]: !lFromState.Links,
      });
    }
  };

  const lref = useRef(null);
  useEffect(() => {
    if (lFromState.Links && lref.current !== document.activeElement) {
      lref.current.focus();
    }

    retainCursorPosition(lref);
  }, [lFromState, link]);

  const [linkSearch, setLinkSearch] = useState("");

  const sLref = useRef(null);
  useEffect(() => {
    if (linkSearch.length > 0) {
      sLref.current.focus();
    }
  }, [linkSearch]);

  var filteredDLata = [];

  if (
    (linkSearch.length > 0 || allSearch.length > 0) &&
    linksSorted.length > 0
  ) {
    filteredDLata = linksSorted.filter((value) => {
      const searchStr = linkSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredDLata.length > 0) {
      // list = list.filter((e)=>{e !== 'Links'})
      ListData.push("Links");
    }
  }

  if (
    filteredDLata.length === 0 &&
    linkSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredDLata = [...linksSorted];
  }

  let nonFilteLData = [];
  if (linkSearch.length > 0 && filteredDLata.length !== linksSorted.length) {
    nonFilteLData = [...linksSorted];
    for (let i = 0; i < filteredDLata.length; i++) {
      nonFilteLData = nonFilteLData.filter((value, key) => {
        return value._id !== filteredDLata[i]._id;
      });
    }
    if (filteredDLata.length === 0) {
      nonFilteLData = [...linksSorted];
    }
  }

  // Circles
  const circlesFrom = () => {
    if (lFromState.Circles === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: false,
        Links: false,
        Circles: !lFromState.Circles,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Circles"]: !lFromState.Circles,
      });
    }
  };

  const cref = useRef(null);
  useEffect(() => {
    if (lFromState.Circles && cref.current !== document.activeElement) {
      cref.current.focus();
    }

    retainCursorPosition(cref);
  }, [lFromState, circle]);

  const [circleSearch, setCircleSearch] = useState("");

  const sCref = useRef(null);
  useEffect(() => {
    if (circleSearch.length > 0) {
      sCref.current.focus();
    }
  }, [circleSearch]);

  var filteredCData = [];

  if (
    (circleSearch.length > 0 || allSearch.length > 0) &&
    circlesSorted.length > 0
  ) {
    filteredCData = circlesSorted.filter((value) => {
      const searchStr = circleSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCData.length > 0) {
      // list = list.filter((e)=>{e !== 'Circles'})
      ListData.push("Circles");
    }
  }

  if (
    filteredCData.length === 0 &&
    circleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCData = [...circlesSorted];
  }

  let nonFilteCData = [];
  if (
    circleSearch.length > 0 &&
    filteredCData.length !== circlesSorted.length
  ) {
    nonFilteCData = [...circlesSorted];
    for (let i = 0; i < filteredCData.length; i++) {
      nonFilteCData = nonFilteCData.filter((value, key) => {
        return value._id !== filteredCData[i]._id;
      });
    }
    if (filteredCData.length === 0) {
      nonFilteCData = [...circlesSorted];
    }
  }

  // Projects
  const projectsFrom = () => {
    if (lFromState.Projects === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: false,
        Links: false,
        Circles: false,
        Projects: !lFromState.Projects,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Projects"]: !lFromState.Projects,
      });
    }
  };

  const prref = useRef(null);
  useEffect(() => {
    if (lFromState.Projects && prref.current !== document.activeElement) {
      prref.current.focus();
    }

    retainCursorPosition(prref);
  }, [lFromState, project]);

  const [projectSearch, setProjectSearch] = useState("");

  const sPrref = useRef(null);
  useEffect(() => {
    if (projectSearch.length > 0) {
      sPrref.current.focus();
    }
  }, [projectSearch]);

  var filteredPrData = [];

  if (
    (projectSearch.length > 0 || allSearch.length > 0) &&
    projectsSorted.length > 0
  ) {
    filteredPrData = projectsSorted.filter((value) => {
      const searchStr = projectSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPrData.length > 0) {
      // list = list.filter((e)=>{e !== 'Projects'})
      ListData.push("Projects");
    }
  }

  if (
    filteredPrData.length === 0 &&
    projectSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPrData = [...projectsSorted];
  }

  let nonFiltePrData = [];
  if (
    projectSearch.length > 0 &&
    filteredPrData.length !== projectsSorted.length
  ) {
    nonFiltePrData = [...projectsSorted];
    for (let i = 0; i < filteredPrData.length; i++) {
      nonFiltePrData = nonFiltePrData.filter((value, key) => {
        return value._id !== filteredPrData[i]._id;
      });
    }
    if (filteredPrData.length === 0) {
      nonFiltePrData = [...projectsSorted];
    }
  }
  // const [specialModeDataObj,setSpecialModeDataObj] = useState([]);
  const toggleEXTnNOCards = (
    type,
    pillType,
    reset = false,
    clickedOn = "list",
    specialMode = null,
    specialModeId = null
  ) => {
    /* console.log(4747, {
      type,
      pillType,
      reset,
      clickedOn,
      specialMode,
      specialModeId
    }) */

    /* reset Special Mode */
    const resetSpecialmode_DataSet = (idToRemove) => {
      if (idToRemove) {
        let isPresent = specialModeDataObj.some(
          (item) => item.id === idToRemove
        );
        if (isPresent) {
          if (specialModeDataObj.length === 1) {
            setIsEXT(false);
            setIsNO(false);
          }
          // Remove the item from the setSpecialModeDataObj array
          setSpecialModeDataObj((prevState) =>
            prevState.filter((item) => item.id !== idToRemove)
          );
        }
      }
    };

    /* reset */
    const resetDataSet = (array, type) => {
      if (array) {
        if (clickedOnList.listName.includes(type)) {
          if (clickedOnList.listName.length === 1) {
            setIsEXT(false);
            setIsNO(false);
          }
          // Remove the item from the listName array
          setClickedOnList((prev) => ({
            ...prev,
            isClicked: clickedOnList.listName.length === 1 ? false : true, //when last then remove and make it false
            listName: prev.listName.filter((item) => item !== type),
          }));
        }

        let dataSet = [];

        if (type === "people") {
          dataSet = array.map((sd) => sd._id);
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array.map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array.map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array.map((item) => item._id);
        }

        setExtNoFilteredObj((prev) => ({
          ...prev,
          [type]: dataSet,
        }));
      }
    };

    /* data set creation */
    const createDataSet = (array, pill, type) => {
      // console.log({array, pill, type});

      if (pill === "External") {
        const ExtPeople = peopleSorted
          .filter((sd) => sd.workRole === "External")
          .map((sd) => sd._id);
        let dataSet;
        if (type === "people") {
          dataSet = array
            .filter((sd) => sd.workRole === pill)
            .map((sd) => sd._id);
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) =>
              item.owners.some((owner) => ExtPeople.includes(owner))
            )
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owners.owner))
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owner))
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        // console.log({ array, pill, type, dataSet });
        /* state update */
        if (dataSet) {
          setExtNoFilteredObj((prev) => ({
            ...prev,
            [type]: dataSet,
          }));
        }
      }
      if (pill === "No") {
        let dataSet = [];
        if (type === "people") {
          dataSet = [];
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) => item.owners.length === 0)
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0 + now checking add if ownertype is exist or not
              if (item.owners.owner && isExistOwnerType(item.owners.type)) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
          console.log({ dataSet });
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => {
              // Check if item.owner exists and then check if item.owner is null or has a length of 0
              // console.log(item.owner);
              if (item.owner && peopleSorted.some(value=> value._id === item.owner)) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        // console.log("=======>>>>>", { pill, type, dataSet })
        if (dataSet) {
          setExtNoFilteredObj((prev) => ({
            ...prev,
            [type]: dataSet,
          }));
        }
      }
    };
    /* data set creation */
    const createDataSet_specialMode = (array, pill, type, specialModeId) => {
      if (pill === "External") {
        const ExtPeople = peopleSorted
          .filter((sd) => sd.workRole === "External")
          .map((sd) => sd._id);
        let dataSet;
        if (type === "people") {
          dataSet = array
            .filter((sd) => sd.workRole === pill)
            .map((sd) => sd._id);
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) =>
              item.owners.some((owner) => ExtPeople.includes(owner))
            )
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owners.owner))
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owner))
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        // console.log({ array, pill, type, dataSet });
        /* state update */
        setSpecialModeDataObj((prev) => {
          // Find if there's already an entry with the same specialModeId
          const existingEntry = prev.find((item) => item.id === specialModeId);
          if (existingEntry) {
            // If it exists, update the data for the corresponding type
            return prev.map((item) =>
              item.id === specialModeId
                ? { ...item, data: { ...item.data, [type]: dataSet } }
                : item
            );
          } else {
            // If it doesn't exist, add a new entry
            return [
              ...prev,
              {
                id: specialModeId,
                data: { [type]: dataSet },
              },
            ];
          }
        });
      }
      if (pill === "No") {
        let dataSet = [];
        if (type === "people") {
          dataSet = [];
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) => item.owners.length === 0)
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0
              // console.log(item.owners.owner);
              if (item.owners.owner) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0
              // console.log(item.owner);
              if (item.owner) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        /* state update */
        setSpecialModeDataObj((prev) => {
          // Find if there's already an entry with the same specialModeId
          const existingEntry = prev.find((item) => item.id === specialModeId);
          if (existingEntry) {
            // If it exists, update the data for the corresponding type
            return prev.map((item) =>
              item.id === specialModeId
                ? { ...item, data: { ...item.data, [type]: dataSet } }
                : item
            );
          } else {
            // If it doesn't exist, add a new entry
            return [
              ...prev,
              {
                id: specialModeId,
                data: { [type]: dataSet },
              },
            ];
          }
        });
      }
    };
    /*--------------- if clicked on list --------------- */
    if (clickedOn === "list") {
      /* {special mode} */
      if (specialMode) {
        if (reset) {
          resetSpecialmode_DataSet(specialModeId);
          // resetSpecialmode_DataSet(skillsSorted, "skill");
          // resetSpecialmode_DataSet(rolesSorted, "role");
          // resetSpecialmode_DataSet(domainsSorted, "domain");
          // resetSpecialmode_DataSet(linksSorted, "link");
        } else {
          if (specialModeId) {
            // setSpecialModeListClick((prev) => ({
            //       ...prev,
            //       isClicked: true,
            //       cpIds: [...prev.cpIds, specialModeId],
            // }));
            /* pillType EXT*/
            if (pillType === "EXT") {
              // setClickedOnList({isClicked : true, listName: type});
              setIsEXT(true);
              setIsNO(false);
              createDataSet_specialMode(
                peopleSorted,
                "External",
                "people",
                specialModeId
              );
              createDataSet_specialMode(
                skillsSorted,
                "External",
                "skill",
                specialModeId
              );
              createDataSet_specialMode(
                rolesSorted,
                "External",
                "role",
                specialModeId
              );
              createDataSet_specialMode(
                domainsSorted,
                "External",
                "domain",
                specialModeId
              );
              createDataSet_specialMode(
                linksSorted,
                "External",
                "link",
                specialModeId
              );
            }
            /* pillType NO*/
            if (pillType === "NO") {
              // setClickedOnList({isClicked : true, listName: type});
              setIsNO(true);
              setIsEXT(false);
              createDataSet_specialMode(
                peopleSorted,
                "No",
                "people",
                specialModeId
              );
              createDataSet_specialMode(
                skillsSorted,
                "No",
                "skill",
                specialModeId
              );
              createDataSet_specialMode(
                rolesSorted,
                "No",
                "role",
                specialModeId
              );
              createDataSet_specialMode(
                domainsSorted,
                "No",
                "domain",
                specialModeId
              );
              createDataSet_specialMode(
                linksSorted,
                "No",
                "link",
                specialModeId
              );
            }
          }
        }
      } else {
        /* {normal list click not special mode} */
        let setName = null;
        if (type === "people") {
          setName = "people";
        }
        if (type === "skills") {
          setName = "skill";
        }
        if (type === "roles") {
          setName = "role";
        }
        if (type === "domains") {
          setName = "domain";
        }
        if (type === "links") {
          setName = "link";
        }
        if (setName) {
          setClickedOnList((prev) => ({
            ...prev,
            isClicked: true,
            listName: [...prev.listName, setName],
          }));
        }
        // console.log({ reset })
        /* reset section */
        if (reset) {
          if (type === "people") {
            resetDataSet(peopleSorted, "people");
          }
          if (type === "skills") {
            resetDataSet(skillsSorted, "skill");
          }
          if (type === "roles") {
            resetDataSet(rolesSorted, "role");
          }
          if (type === "domains") {
            resetDataSet(domainsSorted, "domain");
          }
          if (type === "links") {
            resetDataSet(linksSorted, "link");
          }
        }
        /* pillType EXT */
        if (pillType === "EXT") {
          // setClickedOnList({isClicked : false, listName: type});
          setIsEXT(true);
          setIsNO(false);
          if (type === "people") {
            createDataSet(peopleSorted, "External", "people");
          }
          if (type === "skills") {
            createDataSet(skillsSorted, "External", "skill");
          }
          if (type === "roles") {
            createDataSet(rolesSorted, "External", "role");
          }
          if (type === "domains") {
            createDataSet(domainsSorted, "External", "domain");
          }
          if (type === "links") {
            createDataSet(linksSorted, "External", "link");
          }
        }
        /* pillType NO*/
        if (pillType === "NO") {
          // setClickedOnList({isClicked : false, listName: type});
          setIsNO(true);
          setIsEXT(false);
          if (type === "people") {
            createDataSet(peopleSorted, "No", "people");
          }
          if (type === "skills") {
            createDataSet(skillsSorted, "No", "skill");
          }
          if (type === "roles") {
            createDataSet(rolesSorted, "No", "role");
          }
          if (type === "domains") {
            createDataSet(domainsSorted, "No", "domain");
          }
          if (type === "links") {
            createDataSet(linksSorted, "No", "link");
          }
        }
      }
    }
    /*--------------- if clicked on pill --------------- */
    if (clickedOn === "pill") {
      if (specialModeDataObj.length > 0) {
        setSpecialModeDataObj([]);
        setIsEXT(false);
        setIsNO(false);
        setIsTag("");
        setExtNoFilteredObj({});
        setTagFilteredObj({});
        setTagFilteredMegedData({});
      }
      if (clickedOnList.isClicked) {
        setClickedOnList({ isClicked: false, listName: [] });
        setIsEXT(false);
        setIsNO(false);
        setIsTag("");
        setExtNoFilteredObj({});
        setTagFilteredObj({});
        setTagFilteredMegedData({});
      }
      if (pillType === "EXT") {
        setIsEXT(true);
        setIsNO(false);
        createDataSet(peopleSorted, "External", "people");
        createDataSet(skillsSorted, "External", "skill");
        createDataSet(rolesSorted, "External", "role");
        createDataSet(domainsSorted, "External", "domain");
        createDataSet(linksSorted, "External", "link");
        createDataSet(circlesSorted, "External", "circle");
        createDataSet(projectsSorted, "External", "project");
      }
      if (pillType === "NO") {
        setIsNO(true);
        setIsEXT(false);
        createDataSet(peopleSorted, "No", "people");
        createDataSet(skillsSorted, "No", "skill");
        createDataSet(rolesSorted, "No", "role");
        createDataSet(domainsSorted, "No", "domain");
        createDataSet(linksSorted, "No", "link");
        createDataSet(circlesSorted, "No", "circle");
        createDataSet(projectsSorted, "No", "project");
      }
      /* make all ext or all no visible */
      /* people */
    }
  };

  //...................................................Standard Mode............................................
  const toggleTagCards = (tag) => {
    if (specialModeDataObj.length > 0) {
      setSpecialModeDataObj([]);
      setIsEXT(false);
      setIsNO(false);
      setIsTag("");
      setExtNoFilteredObj({});
      setTagFilteredObj({});
      setTagFilteredMegedData({});
    }
    if (clickedOnList.isClicked) {
      setClickedOnList({ isClicked: false, listName: [] });
      setIsEXT(false);
      setIsNO(false);
      setIsTag("");
      setExtNoFilteredObj({});
      setTagFilteredObj({});
      setTagFilteredMegedData({});
    }
    setIsTag(tag);

    const createDataSet = (array, tag, type) => {
      let dataSet = array
        .filter((sd) => sd.tags.includes(tag))
        .map((sd) => sd._id);

      setTagFilteredObj((prev) => ({
        ...prev,
        [type]: dataSet,
      }));
    };

    createDataSet(peopleSorted, tag, "people");
    createDataSet(skillsSorted, tag, "skill");
    createDataSet(domainsSorted, tag, "domain");
    createDataSet(rolesSorted, tag, "role");
    createDataSet(linksSorted, tag, "link");
    createDataSet(projectsSorted, tag, "project");
    createDataSet(circlesSorted, tag, "circle");
  };

  const getSortIcon = (cardType) => {
    if (!statesLM || !statesLM[cardType]) return "";

    return statesLM[cardType] === "AtoZ" ? (
      <img alt="" src="images/sort-az.png" />
    ) : statesLM[cardType] === "ZtoA" ? (
      <img alt="" src="images/sort-za.png" />
    ) : statesLM[cardType] === "NewToOld" ? (
      <img alt="" src="images/sort-dd.png" />
    ) : statesLM[cardType] === "OldToNew" ? (
      <img alt="" src="images/sort-da.png" />
    ) : statesLM[cardType] === "Parsonal" ? (
      <img alt="" src="images/drag.png" />
    ) : null;
  };

  const handleDragStart = () => {
    // freezeWindow(false);
  };

  const handleDragEnd = (result) => {

    if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    }
    /* Restrict Drag and Drop */
    if (
      result.source.droppableId &&
      result.destination.droppableId &&
      // result.source.droppableId !== result.destination.droppableId // This prevents conversion
      !( // this will allow conversion
        (result.source.droppableId === "skills" && result.destination.droppableId === "roles") ||
        (result.source.droppableId === "roles" && result.destination.droppableId === "skills") ||
        (result.source.droppableId === "circles" && result.destination.droppableId === "projects") ||
        (result.source.droppableId === "projects" && result.destination.droppableId === "circles") ||
        (result.source.droppableId === result.destination.droppableId)
      )
    ) {
      console.log("❌ Drag and Drop is RESTRICTED ❌")
      unfreezeWindow(false);
      return false;
    }

    /* This is done to enable conversion from skills to roles, circles to projects and vice versa */
    if (
      (result.source.droppableId === "skills" && result.destination.droppableId === "roles") ||
      (result.source.droppableId === "roles" && result.destination.droppableId === "skills") ||
      (result.source.droppableId === "circles" && result.destination.droppableId === "projects") ||
      (result.source.droppableId === "projects" && result.destination.droppableId === "circles") ||
      (result.source.droppableId === result.destination.droppableId)
    ) {
      console.log("✅ Drag and Drop is ALLOWED ✅")
      freezeWindow(false);

      if (result.source.droppableId === result.destination.droppableId) {
        console.log("Here it is Intra category Drag And Drop")
        if (
          result.source.droppableId &&
          result.destination.droppableId &&
          result.source.droppableId !== result.destination.droppableId
        ) {
          unfreezeWindow(false);
          return false;
        }

        // console.log(`Dropped in ${result.destination.droppableId}: `);

        /* result.draggableId // - ID of the dragged item
        result.source.index // - source index of the dragged item
        result.destination.index // - destination index of the dragged item */
        // console.log(4326, states, states.cListMode)

        if (states && states.cListMode) {
          // Perform your state update logic
          if (
            result.source.droppableId === "people" &&
            peopleSorted.length > 0 &&
            states.cListMode.People === "Parsonal"
          ) {
            const copyListItems = [...peopleSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setpeopleSorted(copyListItems);
            // console.log(copyListItems)
            let curpeople = copyListItems.map((item) => item._id);
            if (curpeople && curpeople.length > 0) {
              peopleStateUpdate(curpeople);
            }
          }

          if (
            result.source.droppableId === "skills" &&
            skillsSorted.length > 0 &&
            states.cListMode.Skills === "Parsonal"
          ) {
            const copyListItems = [...skillsSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setskillsSorted(copyListItems);

            let curskill = copyListItems.map((item) => item._id);
            if (curskill && curskill.length > 0) {
              skillStateUpdate(curskill);
            }
          }

          if (
            result.source.droppableId === "roles" &&
            rolesSorted.length > 0 &&
            states.cListMode.Roles === "Parsonal"
          ) {
            const copyListItems = [...rolesSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setrolesSorted(copyListItems);

            let currole = copyListItems.map((item) => item._id);
            if (currole && currole.length > 0) {
              roleStateUpdate(currole);
            }
          }

          if (
            result.source.droppableId === "domains" &&
            domainsSorted.length > 0 &&
            states.cListMode.Domains === "Parsonal"
          ) {
            const copyListItems = [...domainsSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setdomainsSorted(copyListItems);

            let curdomain = copyListItems.map((item) => item._id);
            if (curdomain && curdomain.length > 0) {
              domainStateUpdate(curdomain);
            }
          }

          if (
            result.source.droppableId === "links" &&
            linksSorted.length > 0 &&
            states.cListMode.Links === "Parsonal"
          ) {
            const copyListItems = [...linksSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setlinksSorted(copyListItems);

            let curlink = copyListItems.map((item) => item._id);
            if (curlink && curlink.length > 0) {
              linkStateUpdate(curlink);
            }
          }

          if (
            result.source.droppableId === "circles" &&
            circlesSorted.length > 0 &&
            states.cListMode.Circles === "Parsonal"
          ) {
            const copyListItems = [...circlesSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setCirclesSorted(copyListItems);

            let curcircle = copyListItems.map((item) => item._id);
            if (curcircle && curcircle.length > 0) {
              circleStateUpdate(curcircle);
            }
          }

          if (
            result.source.droppableId === "projects" &&
            projectsSorted.length > 0 &&
            states.cListMode.Projects === "Parsonal"
          ) {
            const copyListItems = [...projectsSorted];
            const draggedItem = copyListItems.find(
              (item) => item._id === result.draggableId
            );
            copyListItems.splice(result.source.index, 1);
            copyListItems.splice(result.destination.index, 0, draggedItem);
            setprojectsSorted(copyListItems);

            let curproject = copyListItems.map((item) => item._id);
            if (curproject && curproject.length > 0) {
              projectStateUpdate(curproject);
            }
          }
        }
      } else {
        console.log("It is Inter Category Drag and Drop")
        // Convert start
        let { draggableId } = result;
        if (draggableId === undefined || !draggableId) {
          return;
        }
        let from = result.source.droppableId;
        let { index, droppableId: to } = result.destination || {};
        // console.log(`${draggableId} Will be converted from ${from}, to ${to} into index ${index}, please wait! Development in progress.`);

        if (from === "skills" && to === "roles") {
          let targetSkill = skills.find((ss) => ss._id === draggableId);
          let remainingSkills = skills.filter((ss) => ss._id !== draggableId);
          console.log(skills.length, remainingSkills.length);
          setskillsSorted(remainingSkills);
          setrolesSorted((prev) => {
            const newArray = [...prev];
            newArray.splice(index, 0, { ...targetSkill, roleName: targetSkill.skillName, defaultRole: false, ownerRole: targetSkill.ownerType }); // Insert the new element at the desired index
            return newArray;
          });
          SConToRole({ ...targetSkill, transferIndex: index });

        } else if (from === "roles" && to === "skills") {
          let targetRole = roles.find((sr) => sr._id === draggableId);
          let remainingRoles = roles.filter((sr) => sr._id !== draggableId);
          // console.log(roles.length, remainingRoles.length);
          setrolesSorted(remainingRoles);
          setskillsSorted((prev) => {
            const newArray = [...prev];
            newArray.splice(index, 0, { ...targetRole, skillName: targetRole.roleName, ownerType: targetRole.ownerRole, defaultRole: false }); // Insert the new element at the desired index
            return newArray;
          });
          RConToSkill({ ...targetRole, transferIndex: index });

        } else if (from === "circles" && to === "projects") {
          let targetCircle = circles.find((sc) => sc._id === draggableId);
          // console.log(circles, targetCircle);
          let remainingCircles = circles.filter((sc) => sc._id !== draggableId);
          // console.log(circles.length, remainingCircles.length);
          setCirclesSorted(remainingCircles);
          setprojectsSorted((prev) => {
            const newArray = [...prev];
            newArray.splice(index, 0, { ...targetCircle, projectName: targetCircle.circleName }); // Insert the new element at the desired index
            return newArray;
          });
          circleToProject({ ...targetCircle, transferIndex: index });

        } else if (from === "projects" && to === "circles") {
          let targetProject = projects.find((spr) => spr._id === draggableId);
          // console.log(projects, targetProject);
          let remainingProjects = projects.filter((spr) => spr._id !== draggableId);
          // console.log(projects.length, remainingProjects.length);
          setprojectsSorted(remainingProjects);
          setCirclesSorted((prev) => {
            const newArray = [...prev];
            newArray.splice(index, 0, { ...targetProject, circleName: targetProject.projectName }); // Insert the new element at the desired index
            return newArray;
          });
          projectToCircle({ ...targetProject, transferIndex: index });

        }
        // Convert end
        // unfreezeWindow(false);
        return false;
      }
    }
    // return;

    /* if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    } */

  };

  const [tagFilteredMegedData, setTagFilteredMegedData] = useState([]);

  useEffect(() => {
    let filter_dataset = GetTagFilteredSortedData(
      isTag,
      isEXT,
      isNO,
      tagFilteredObj,
      extNoFilteredObj
    );
    setTagFilteredMegedData(filter_dataset);
  }, [isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj]);

  useEffect(() => {
    if (isEXT || isNO) {
      toggleEXTnNOCards(
        "people",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
      toggleEXTnNOCards(
        "skill",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
      toggleEXTnNOCards(
        "role",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
      toggleEXTnNOCards(
        "domain",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
    }
    if (isTag) {
      toggleTagCards(isTag);
    }
  }, [
    peopleSorted,
    skillsSorted,
    rolesSorted,
    domainsSorted,
    linksSorted,
    circlesSorted,
    projectsSorted,
  ]);

  const [scrollPos, setScrollPos] = useState({
    people: null,
    skills: null,
    roles: null,
  });

  /* handle checkbox selection in compare mode */
  const handleCheckboxSelection = (id, type) => {
    // Get the scroll position of the parent container
    let modComareBetweenData = [];
    const dataSet = { id: id, type: type };
    if (type === "people") {
      if (parentPeopleRef.current) {
        const scrollTop = parentPeopleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setScrollPos((prev) => ({
          ...prev,
          people: scrollTop /* skills: null, roles: null */,
        }));
      }
      /* take only people type */
      modComareBetweenData = compareMode.compareBetween.filter(
        (data) => data.type !== "skill" && data.type !== "role"
      );
      /* check and push or remove*/
      const isPresent = modComareBetweenData.some(
        (data) => data.id === dataSet.id
      );
      if (!isPresent) {
        modComareBetweenData = [...modComareBetweenData, dataSet];
      } else {
        modComareBetweenData = compareMode.compareBetween.filter(
          (data) => data.id !== dataSet.id
        );
      }
    } else if (type === "skill" || type === "role") {
      if (type === "skill" && parentSkillRef.current) {
        const scrollTop = parentSkillRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setScrollPos((prev) => ({
          ...prev,
          /* people: null, */ skills: scrollTop /* roles: null */,
        }));
      }
      if (type === "role" && parentRoleRef.current) {
        const scrollTop = parentRoleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setScrollPos((prev) => ({
          ...prev,
          /* people: null, skills: null, */ roles: scrollTop,
        }));
      }
      /* remove only people type */
      modComareBetweenData = compareMode.compareBetween.filter(
        (data) => data.type !== "people"
      );

      /* check and push or remove*/
      const isPresent = modComareBetweenData.some(
        (data) => data.id === dataSet.id
      );
      if (!isPresent) {
        modComareBetweenData = [...modComareBetweenData, dataSet];
      } else {
        modComareBetweenData = compareMode.compareBetween.filter(
          (data) => data.id !== dataSet.id
        );
      }
    }
    setCompareMode((prev) => ({
      ...prev,
      compareBetween: modComareBetweenData,
    }));
  };

  useEffect(() => {
    if (parentPeopleRef && scrollPos && scrollPos.people) {
      parentPeopleRef.current.scrollTop = scrollPos.people;
    }
    if (parentSkillRef && scrollPos && scrollPos.skills) {
      parentSkillRef.current.scrollTop = scrollPos.skills;
    }
    if (parentRoleRef && scrollPos && scrollPos.roles) {
      parentRoleRef.current.scrollTop = scrollPos.roles;
    }
  }, [compareMode]);

  const IsChecked = (id) => {
    let checkStatus = compareMode.compareBetween.some((data) => data.id === id);
    return checkStatus;
  };
  const showCheckBox = (type) => {
    if (type === "people") {
      let checkStatusForSkill = compareMode.compareBetween.some(
        (data) => data.type === "skill"
      );
      let checkStatusForRole = compareMode.compareBetween.some(
        (data) => data.type === "role"
      );
      return !(checkStatusForSkill || checkStatusForRole);
    } else if (type === "skill" || type === "role") {
      let checkStatusForPeople = compareMode.compareBetween.some(
        (data) => data.type === "people"
      );
      return !checkStatusForPeople;
    }
  };

  const handleAliesChange = (e) => {
    const name = e.target.dataset.name;
    const value = e.target.innerText;

    // console.log("Update");
    const teamData = teams ? teams.find((team) => team._id === localStorage.getItem("teamId")) : {};
    const newAliases = { ...teamData.aliases };

    if (Object.keys(newAliases).length > 0) {
      newAliases[name] = value;
    }
    // const updatedTeamData = { ...teamData, aliases: newAliases };
    // console.log(updatedTeamData);

    dispatch(
      updateTeam({
        teamId: localStorage.getItem("teamId"),
        userId: myInfo.userId,
        aliases: newAliases
      })
    ).then(() => { dispatch(getTeam({ userId: myInfo.userId })) });
  };

  function ScrollIntoView() {
    // console.log('first')
    setTimeout(() => {
      const focusedCard = document.querySelector('.card.focused-card');
      if (focusedCard) {
        focusedCard.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling animation
          block: 'center',    // Align the element vertically in the center
          inline: 'nearest'   // Align horizontally if necessary
        });
      } else {
        console.warn('No element with the class "focused-card" was found.');
      }
    }, 1);
  }

  /* Standard Mode List Components */
  const People = () => <PeopleList tagFilteredMegedData={tagFilteredMegedData} GetAliasesName={GetAliasesName} teams={teams} peopleSorted={peopleSorted} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} addNewPeoples={addNewPeoples} pCardHideOnchange={pCardHideOnchange} noteOnchange={noteOnchange} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} note={note} pnoteRef={pnoteRef} handleSave={handleSave} noteSaveData={noteSaveData} spref={spref} peopleSearch={peopleSearch} setPeopleSearch={setPeopleSearch} filteredPData={filteredPData} TagWiseSorting={TagWiseSorting} statesLM={statesLM} ScrollIntoView={ScrollIntoView} setPepole={setPepole} parentPeopleRef={parentPeopleRef} setFocusedRef={setFocusedRef} setCardRef={setCardRef} workloadToggle={workloadToggle} aggrigatedPersonFTE={aggrigatedPersonFTE} toggleTagCards={toggleTagCards} compareMode={compareMode} handleCheckboxSelection={handleCheckboxSelection} IsChecked={IsChecked} handelFocused={handelFocused} lFromState={lFromState} peopleSubmit={peopleSubmit} pref={pref} fName={fName} handleNameChange={handleNameChange} pfNameHandel={pfNameHandel} setPepoles={setPepoles} setFName={setFName} peopleFrom={peopleFrom} isTag={isTag} isEXT={isEXT} isNO={isNO} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} skills={skills} roles={roles} dRoles={dRoles} domains={domains} links={links} />;

  const Skills = () => <SkillList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} filteredSData={filteredSData} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} setSkills={setSkills} pCardHideOnchange={pCardHideOnchange} noteOnchange={noteOnchange} note={note} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} parentSkillRef={parentSkillRef} handleSave={handleSave} noteSaveData={noteSaveData} sSref={sSref} skillSearch={skillSearch} setSkillSearch={setSkillSearch} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} statesLM={statesLM} setSkill={setSkill} setCardRef={setCardRef} workloadToggle={workloadToggle} sumFTEBody={sumFTEBody} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} compareMode={compareMode} handleCheckboxSelection={handleCheckboxSelection} IsChecked={IsChecked} lFromState={lFromState} skillSubmit={skillSubmit} sref={sref} skill={skill} handleSkillChange={handleSkillChange} setSkillName={setSkillName} skillsFrom={skillsFrom} />;

  const Roles = () => <RoleList showGenericRole={showGenericRole} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} filteredRData={filteredRData} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} setRoles={setRoles} pCardHideOnchange={pCardHideOnchange} noteOnchange={noteOnchange} note={note} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} parentRoleRef={parentRoleRef} handleSave={handleSave} noteSaveData={noteSaveData} sRref={sRref} roleSearch={roleSearch} setRoleSearch={setRoleSearch} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} statesLM={statesLM} setRole={setRole} setCardRef={setCardRef} workloadToggle={workloadToggle} sumFTEBody={sumFTEBody} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} compareMode={compareMode} handleCheckboxSelection={handleCheckboxSelection} IsChecked={IsChecked} lFromState={lFromState} roleSubmit={roleSubmit} sref={sref} role={role} handleRoleChange={handleRoleChange} setRoleName={setRoleName} rolesFrom={rolesFrom} dRoles={dRoles} rref={rref} />;

  const Domains = () => <DomainList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} filteredDData={filteredDData} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} setDomains={setDomains} teams={teams} pCardHideOnchange={pCardHideOnchange} noteOnchange={noteOnchange} note={note} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} handleSave={handleSave} noteSaveData={noteSaveData} sDref={sDref} setDomainSearch={setDomainSearch} domainSearch={domainSearch} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} IsOwnerExternal={IsOwnerExternal} dWhoIsOwnerType={dWhoIsOwnerType} statesLM={statesLM} setDomain={setDomain} parentDomainRef={parentDomainRef} setCardRef={setCardRef} workloadToggle={workloadToggle} sumFTEBody={sumFTEBody} cardOpensCfDomain={cardOpensCfDomain} isExistOwnerType={isExistOwnerType} toggleTagCards={toggleTagCards} dDomains={dDomains} domainSubmit={domainSubmit} dref={dref} handleDomainChange={handleDomainChange} compareMode={compareMode} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} allSearch={allSearch} lFromState={lFromState} domain={domain} setDomainName={setDomainName} domainsFrom={domainsFrom} />

  const Links = () => <LinkList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} setLinks={setLinks} pCardHideOnchange={pCardHideOnchange} noteOnchange={noteOnchange} note={note} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} handleSave={handleSave} noteSaveData={noteSaveData} setLinkSearch={setLinkSearch} linkSearch={linkSearch} filteredDLata={filteredDLata} TagWiseSorting={TagWiseSorting} IsOwnerExternal={IsOwnerExternal} statesLM={statesLM} setLink={setLink} parentLinkRef={parentLinkRef} setCardRef={setCardRef} workloadToggle={workloadToggle} sumFTEBody={sumFTEBody} linkSubmit={linkSubmit} handleLinkChange={handleLinkChange} setLinkName={setLinkName} linksFrom={linksFrom} teams={teams} links={links} sLref={sLref} tagFilteredObj={tagFilteredObj} toggleTagCards={toggleTagCards} lFromState={lFromState} lref={lref} compareMode={compareMode} extNoFilteredObj={extNoFilteredObj} link={link} peopleSorted={peopleSorted} />;

  const Circles = () => <CircleList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} filteredCData={filteredCData} getSortIcon={getSortIcon} isInfos={isInfos} setCircles={setCircles} pCardHideOnchange={pCardHideOnchange} setFocusedMode={setFocusedMode} noteOnchange={noteOnchange} note={note} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} handleSave={handleSave} noteSaveData={noteSaveData} circleSearch={circleSearch} setCircleSearch={setCircleSearch} tagFilteredObj={tagFilteredObj} statesLM={statesLM} ScrollIntoView={ScrollIntoView} setCircle={setCircle} parentCircleRef={parentCircleRef} setCardRef={setCardRef} toggleTagCards={toggleTagCards} handelFocused={handelFocused} circleSubmit={circleSubmit} dCircles={dCircles} cref={cref} circle={circle} handleCircleChange={handleCircleChange} setCircleName={setCircleName} circlesFrom={circlesFrom} compareMode={compareMode} teams={teams} sCref={sCref} isTag={isTag} lFromState={lFromState} />

  const Projects = () => <ProjectList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} filteredPrData={filteredPrData} getSortIcon={getSortIcon} isInfos={isInfos} setProjects={setProjects} pCardHideOnchange={pCardHideOnchange} setFocusedMode={setFocusedMode} noteOnchange={noteOnchange} note={note} handeSorts={handeSorts} setCombinedRefs={setCombinedRefs} handleSave={handleSave} noteSaveData={noteSaveData} projectSearch={projectSearch} setProjectSearch={setProjectSearch} tagFilteredObj={tagFilteredObj} statesLM={statesLM} ScrollIntoView={ScrollIntoView} setProject={setProject} parentProjectRef={parentProjectRef} setCardRef={setCardRef} toggleTagCards={toggleTagCards} handelFocused={handelFocused} projectSubmit={projectSubmit} prref={prref} project={project} handleProjectChange={handleProjectChange} setProjectName={setProjectName} projectsFrom={projectsFrom} compareMode={compareMode} teams={teams} sPrref={sPrref} isTag={isTag} lFromState={lFromState}
  />;

  //==================================================POPOVER FOR PEOPLE==================================================//
  var cuPfCirclePlus = [];

  useEffect(() => {
    if (focused.card && focused.card.length > 0 && peopleSorted.length > 0) {
      if (focused.card === "People") {
        let focusedPerson = peopleSorted.find((p) => p._id === focused.id);
        if (focusedPerson) {
          cuPfCirclePlus.push({
            name: `${focusedPerson.fname} ${focusedPerson.lname} (Person)`,
            Id: focused.id,
            type: "People",
          });
        }
        if (PfoSkills && PfoSkills.length > 0) {
          for (var i = 0; i < PfoSkills.length; i++) {
            cuPfCirclePlus.push({
              name: PfoSkills[i].skillName + `(${GetAliasesName(teams, "Skills")})`,
              Id: PfoSkills[i]._id,
              type: "Skill",
            });
          }
        }
        if (PfoRoles && PfoRoles.length > 0) {
          for (var i = 0; i < PfoRoles.length; i++) {
            cuPfCirclePlus.push({
              name: PfoRoles[i].roleName + ` (${GetAliasesName(teams, "Roles")})`,
              Id: PfoRoles[i]._id,
              type: "Role",
            });
          }
        }
        if (PfoDomains && PfoDomains.length > 0) {
          for (var i = 0; i < PfoDomains.length; i++) {
            cuPfCirclePlus.push({
              name: PfoDomains[i].domainName + ` (${GetAliasesName(teams, "Roles")})`,
              Id: PfoDomains[i]._id,
              type: "Domain",
            });
          }
        }
        if (PfoLinks && PfoLinks.length > 0) {
          for (var i = 0; i < PfoLinks.length; i++) {
            cuPfCirclePlus.push({
              name: PfoLinks[i].linkName + ` (${GetAliasesName(teams, "Links")})`,
              Id: PfoLinks[i]._id,
              type: "Link",
            });
          }
        }
      } else if (focused.card === "Circles") {
        // cuPfCirclePlus.push({ name: `${peopleSorted[focused.index].fname} ${peopleSorted[focused.index].lname} (Person)`, Id: focused.id })
      } else if (focused.card === "Projects") {
        // cuPfCirclePlus.push({ name: `${peopleSorted[focused.index].fname} ${peopleSorted[focused.index].lname} (Person)`, Id: focused.id })
      }
    }
  }, [
    focused,
    peopleSorted,
    skillsSorted,
    rolesSorted,
    domainsSorted,
    linksSorted,
  ]);

  // People focuesd to circle or project focused
  const PfToCAndP = (type, data) => {
    // console.log("here7777");
    if (type === "People") {
      if (
        data &&
        Object.keys(data).length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let i = 0; i < peopleSorted.length; i++) {
          if (peopleSorted[i]._id === data._id) {
            handelFocused({
              card: "People",
              index: i,
              id: peopleSorted[i]._id,
            });
            break;
          }
        }
      }
    } else if (type === "Circles") {
      if (
        data &&
        Object.keys(data).length > 0 &&
        circlesSorted &&
        circlesSorted.length > 0
      ) {
        for (let i = 0; i < circlesSorted.length; i++) {
          if (circlesSorted[i]._id === data._id) {
            handelFocused({
              card: "Circles",
              index: i,
              id: circlesSorted[i]._id,
            });
            break;
          }
        }
      }
    } else if (type === "Projects") {
      if (
        data &&
        Object.keys(data).length > 0 &&
        projectsSorted &&
        projectsSorted.length > 0
      ) {
        for (let i = 0; i < projectsSorted.length; i++) {
          if (projectsSorted[i]._id === data._id) {
            handelFocused({
              card: "Projects",
              index: i,
              id: projectsSorted[i]._id,
            });
            break;
          }
        }
      }
    }
    // console.log("here7777 --- end");
  };

  const [PfSCirclePlus, setPfSCirclePlus] = useState("");
  var cuFPfCirclePlus = [];

  if (PfSCirclePlus && PfSCirclePlus.length > 0) {
    cuFPfCirclePlus = cuPfCirclePlus.filter((value) => {
      const searchStr = PfSCirclePlus.toLowerCase();
      const nameMatches = `${value.name}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
  }

  if (cuFPfCirclePlus.length === 0) {
    cuFPfCirclePlus = [...cuPfCirclePlus];
  }

  useEffect(() => {
    if (cuPfCirclePlus && cuPfCirclePlus.length > 0) {
      localStorage.setItem(
        "pfcAddData",
        JSON.stringify({ data: cuPfCirclePlus })
      );
    }
  }, [cuPfCirclePlus]);

  let workRole = "core";

  const HandelCAndP = (e, type) => {
    localStorage.setItem("memberType", type);
    if (type === "Circles") {
      if (focused && focused.card === "People" && members.length > 0) {
        for (let i = 0; i < members.length; i++) {
          if (members[i].memberType === e._id) {
            let arr = {
              teamId: members[i].teamId,
              memberType: members[i].memberType,
              coreMembers: {
                People: members[i].coreMembers.People,
                Skills: members[i].coreMembers.Skills,
                Roles: members[i].coreMembers.Roles,
                Links: members[i].coreMembers.Links,
                Domains: members[i].coreMembers.Domains,
                Circles: members[i].coreMembers.Circles,
                Projects: members[i].coreMembers.Projects,
              },
              extendedMembers: {
                People: members[i].extendedMembers.People,
                Skills: members[i].extendedMembers.Skills,
                Roles: members[i].extendedMembers.Roles,
                Links: members[i].extendedMembers.Links,
                Domains: members[i].extendedMembers.Domains,
                Circles: members[i].extendedMembers.Circles,
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("membercData", JSON.stringify(arr));
            }
            break;
          }
        }
      }
      if (
        focused &&
        focused.card === "People" &&
        pfmembers &&
        pfmembers.length > 0
      ) {
        let count = 0;
        for (let i = 0; i < pfmembers.length; i++) {
          if (pfmembers[i].memberType === e._id) {
            let arr = {
              teamId: pfmembers[i].teamId,
              memberType: pfmembers[i].memberType,
              coreMembers: {
                Skills: pfmembers[i].coreMembers.Skills,
                Roles: pfmembers[i].coreMembers.Roles,
                Links: pfmembers[i].coreMembers.Links,
                Domains: pfmembers[i].coreMembers.Domains,
              },
              extendedMembers: {
                Skills: pfmembers[i].extendedMembers.Skills,
                Roles: pfmembers[i].extendedMembers.Roles,
                Links: pfmembers[i].extendedMembers.Links,
                Domains: pfmembers[i].extendedMembers.Domains,
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("pfmembercData", JSON.stringify(arr));
            }
            break;
          }
          count++;
        }
        if (
          count === pfmembers.length &&
          !JSON.parse(localStorage.getItem("pfmembercData"))
        ) {
          let arr = {
            teamId: localStorage.getItem("teamId"),
            memberType: e._id.toString(),
            coreMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
            extendedMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
          };
          if (arr && arr.teamId.length > 0) {
            localStorage.setItem("pfmembercData", JSON.stringify(arr));
          }
        }
      } else {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: e._id.toString(),
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          localStorage.setItem("pfmembercData", JSON.stringify(arr));
        }
      }
    } else if (type === "Projects") {
      if (focused && focused.card === "People" && members.length > 0) {
        for (let i = 0; i < members.length; i++) {
          if (members[i].memberType === e._id) {
            let arr = {
              teamId: members[i].teamId,
              memberType: members[i].memberType,
              coreMembers: {
                People: members[i].coreMembers.People,
                Skills: members[i].coreMembers.Skills,
                Roles: members[i].coreMembers.Roles,
                Links: members[i].coreMembers.Links,
                Domains: members[i].coreMembers.Domains,
                Circles: members[i].coreMembers.Circles,
                Projects: members[i].coreMembers.Projects,
              },
              extendedMembers: {
                People: members[i].extendedMembers.People,
                Skills: members[i].extendedMembers.Skills,
                Roles: members[i].extendedMembers.Roles,
                Links: members[i].extendedMembers.Links,
                Domains: members[i].extendedMembers.Domains,
                Circles: members[i].extendedMembers.Circles,
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("memberPData", JSON.stringify(arr));
            }
            break;
          }
        }
        if (
          focused &&
          focused.card === "People" &&
          pfmembers &&
          pfmembers.length > 0
        ) {
          let counts = 0;
          for (let i = 0; i < pfmembers.length; i++) {
            if (pfmembers[i].memberType === e._id) {
              let arr = {
                teamId: pfmembers[i].teamId,
                memberType: pfmembers[i].memberType,
                coreMembers: {
                  Skills: pfmembers[i].coreMembers.Skills,
                  Roles: pfmembers[i].coreMembers.Roles,
                  Links: pfmembers[i].coreMembers.Links,
                  Domains: pfmembers[i].coreMembers.Domains,
                },
                extendedMembers: {
                  Skills: pfmembers[i].extendedMembers.Skills,
                  Roles: pfmembers[i].extendedMembers.Roles,
                  Links: pfmembers[i].extendedMembers.Links,
                  Domains: pfmembers[i].extendedMembers.Domains,
                },
              };
              if (arr && arr.teamId.length > 0) {
                localStorage.setItem("pfmemberPData", JSON.stringify(arr));
              }
              break;
            }
            counts++;
          }
          if (
            counts === pfmembers.length &&
            !JSON.parse(localStorage.getItem("pfmemberPData"))
          ) {
            let arr = {
              teamId: localStorage.getItem("teamId"),
              memberType: e._id.toString(),
              coreMembers: {
                Skills: [],
                Roles: [],
                Links: [],
                Domains: [],
              },
              extendedMembers: {
                Skills: [],
                Roles: [],
                Links: [],
                Domains: [],
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("pfmemberPData", JSON.stringify(arr));
            }
          }
        } else {
          let arr = {
            teamId: localStorage.getItem("teamId"),
            memberType: e._id.toString(),
            coreMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
            extendedMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
          };
          if (arr && arr.teamId.length > 0) {
            localStorage.setItem("pfmemberPData", JSON.stringify(arr));
          }
        }
      }
    }
  };

  //=========================================PEOPLE FOCUSED algorithm===================================================

  //People
  let PFSotedData = [...peopleSorted];

  // Skills
  const [skillfSearch, setSkillfSearch] = useState("");

  const sPSref = useRef(null);
  useEffect(() => {
    if (skillfSearch.length > 0) {
      sPSref.current.focus();
    }
  }, [skillfSearch]);

  var filteredSFData = [];

  if (
    (skillfSearch.length > 0 || allSearch.length > 0) &&
    PfouSkills.length > 0
  ) {
    filteredSFData = PfouSkills.filter((value) => {
      const searchStr = skillfSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredSFData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Skills");
    }
  }

  if (
    filteredSFData.length === 0 &&
    skillfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredSFData = [...PfouSkills];
  }

  let nonFilteSFData = [];
  if (skillfSearch.length > 0 && filteredSFData.length !== PfouSkills.length) {
    nonFilteSFData = [...PfouSkills];
    for (let i = 0; i < filteredSFData.length; i++) {
      nonFilteSFData = nonFilteSFData.filter((value, key) => {
        return value._id !== filteredSFData[i]._id;
      });
    }
    if (filteredSFData.length === 0) {
      nonFilteSFData = [...PfouSkills];
    }
  }

  // Roles
  const [rolefSearch, setRolefSearch] = useState("");

  const fpRref = useRef(null);
  useEffect(() => {
    if (rolefSearch.length > 0) {
      fpRref.current.focus();
    }
  }, [rolefSearch]);

  var filteredRFData = [];

  if (
    (rolefSearch.length > 0 || allSearch.length > 0) &&
    PfouRoles.length > 0
  ) {
    filteredRFData = PfouRoles.filter((value) => {
      const searchStr = rolefSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredRFData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Roles");
    }
  }

  if (
    filteredRFData.length === 0 &&
    rolefSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredRFData = [...PfouRoles];
  }

  let nonFilteRFData = [];
  if (rolefSearch.length > 0 && filteredRFData.length !== PfouRoles.length) {
    nonFilteRFData = [...PfouRoles];
    for (let i = 0; i < filteredRFData.length; i++) {
      nonFilteRFData = nonFilteRFData.filter((value, key) => {
        return value._id !== filteredRFData[i]._id;
      });
    }
    if (filteredRFData.length === 0) {
      nonFilteRFData = [...PfouRoles];
    }
  }

  let dMentor = [];
  let dLinkOwner = [];
  let dDomainOwner = [];
  let dProjectLead = [];
  let dCircleLead = [];

  var curPefCircless = [...PfoCircles];
  var curPefProjectss = [...PfoProjects];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    peopleSorted &&
    peopleSorted.length > 0
  ) {
    let focusedPeople = focused.id;

    for (let i = 0; i < peopleSorted.length; i++) {
      if (peopleSorted[i].mentor === focusedPeople) {
        dMentor = [...new Set([...dMentor, peopleSorted[i]._id])];
      }
    }

    for (let i = 0; i < PfoLinks.length; i++) {
      if (PfoLinks[i].owner === focusedPeople) {
        dLinkOwner = [...new Set([...dLinkOwner, PfoLinks[i]._id])];
      }
    }

    for (let i = 0; i < PfoDomains.length; i++) {
      if (PfoDomains[i].owners.owner === focusedPeople) {
        dDomainOwner = [...new Set([...dDomainOwner, PfoDomains[i]._id])];
      }
    }
    // console.log(curPefCircless)
    // console.log(dCircles)
    if (curPefCircless && curPefCircless.length > 0) {
      for (let i = 0; i < curPefCircless.length; i++) {
        if (curPefCircless[i].lead === focusedPeople) {
          dCircleLead = [...new Set([...dCircleLead, curPefCircless[i]._id])];
        }
      }
    }

    if (dCircles && dCircles.length > 0) {
      for (let i = 0; i < dCircles.length; i++) {
        if (dCircles[i].lead === focusedPeople) {
          dCircleLead = [...new Set([...dCircleLead, dCircles[i]._id])];
        }
      }
    }

    if (curPefProjectss && curPefProjectss.length > 0) {
      for (let i = 0; i < curPefProjectss.length; i++) {
        if (curPefProjectss[i].lead === focusedPeople) {
          dProjectLead = [
            ...new Set([...dProjectLead, curPefProjectss[i]._id]),
          ];
        }
      }
    }
  }

  let DPFRole = [];
  let SPFRole = [];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    dRoles &&
    dRoles.length > 0 &&
    peopleSorted.length > 0
  ) {
    for (let i = 0; i < dRoles.length; i++) {
      if (dRoles[i].roleName === "Mentor" && dMentor.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (dRoles[i].roleName === "Mentor" && dMentor.length === 0) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Link Owner" && dLinkOwner.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Link Owner" &&
        dLinkOwner.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Domain Owner" && dDomainOwner.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Domain Owner" &&
        dDomainOwner.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Circle Lead" && dCircleLead.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Circle Lead" &&
        dCircleLead.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Project Lead" && dProjectLead.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Project Lead" &&
        dProjectLead.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Governance moderator" && focused.id) {
        let count = 0;
        if (dRoles[i].owners && dRoles[i].owners.length > 0) {
          for (let j = 0; j < dRoles[i].owners.length; j++) {
            if (dRoles[i].owners[j].toString() === focused.id.toString()) {
              SPFRole.push(dRoles[i]);
            } else {
              count++;
            }
          }
          if (count === dRoles[i].owners.length) {
            DPFRole.push(dRoles[i]);
          }
        } else if (dRoles[i].owners && dRoles[i].owners.length === 0) {
          DPFRole.push(dRoles[i]);
        }
      }
    }
  }

  // Domains
  const [domainfSearch, setDomainfSearch] = useState("");

  const fpDref = useRef(null);
  useEffect(() => {
    if (domainfSearch.length > 0) {
      fpDref.current.focus();
    }
  }, [domainfSearch]);

  var filteredDFData = [];
  let nonFilteDFData = [];

  if (
    (domainfSearch.length > 0 || allSearch.length > 0) &&
    PfouDomains.length > 0
  ) {
    filteredDFData = PfouDomains.filter((value) => {
      const searchStr = domainfSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredDFData.length > 0) {
      ListData.push("Domains");
    }
  }

  if (
    filteredDFData.length === 0 &&
    domainfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredDFData = [...PfouDomains];
  }

  if (
    domainfSearch.length > 0 &&
    filteredDFData.length !== PfouDomains.length
  ) {
    nonFilteDFData = [...PfouDomains];
    for (let i = 0; i < filteredDFData.length; i++) {
      nonFilteDFData = nonFilteDFData.filter((value, key) => {
        return value._id !== filteredDFData[i]._id;
      });
    }
    if (filteredDFData.length === 0) {
      nonFilteDFData = [...PfouDomains];
    }
  }

  /// Links
  const [linkfSearch, setLinkfSearch] = useState("");

  const fpLref = useRef(null);
  useEffect(() => {
    if (linkfSearch.length > 0) {
      fpLref.current.focus();
    }
  }, [linkfSearch]);

  var filteredLFData = [];

  if (
    (linkfSearch.length > 0 || allSearch.length > 0) &&
    PfouLinks.length > 0
  ) {
    filteredLFData = PfouLinks.filter((value) => {
      const searchStr = linkfSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredLFData.length > 0) {
      ListData.push("Links");
    }
  }

  if (
    filteredLFData.length === 0 &&
    linkfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredLFData = [...PfouLinks];
  }

  let nonFilteLFData = [];
  if (linkfSearch.length > 0 && filteredLFData.length !== PfouLinks.length) {
    nonFilteLFData = [...PfouLinks];
    for (let i = 0; i < filteredLFData.length; i++) {
      nonFilteLFData = nonFilteLFData.filter((value, key) => {
        return value._id !== filteredLFData[i]._id;
      });
    }
    if (filteredLFData.length === 0) {
      nonFilteLFData = [...PfouLinks];
    }
  }

  /// Circles
  var curPefCircles = [...PfoCircles]; /// [...PfoCircles]
  // console.log(6498, curPefCircles)
  var memberInDatas = [];

  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    peopleSorted &&
    peopleSorted.length > 0
  ) {
    let foPeople = peopleSorted.find((p) => p._id === focused.id); //peopleSorted[focused.index];
    if (foPeople && Object.keys(foPeople).length > 0) {
      members.map((e, index) => {
        let inc = e.coreMembers.People.includes(foPeople._id);
        let incs = e.extendedMembers.People.includes(foPeople._id);
        if (inc) {
          memberInDatas.push({
            memberId: e.memberType,
            XTD: "",
            // name: `${foPeople.fname} ${foPeople.lname}` + " " + "(Person)",
            name: `Added as person`,
            cardType: "People",
            Id: foPeople._id,
          });
        }
        if (incs) {
          memberInDatas.push({
            memberId: e.memberType,
            XTD: "XTD",
            // name: `${foPeople.fname} ${foPeople.lname}` + " " + "(Person)",
            name: `Added as person`,
            cardType: "People",
            Id: foPeople._id,
          });
        }
      });
    }

    if (PfoSkills && PfoSkills.length > 0) {
      for (let i = 0; i < PfoSkills.length; i++) {
        members.map((e, index) => {
          let pfmemberdata = pfmembers.filter(
            (pfe, index) => pfe.memberType === e.memberType
          );
          // console.log('Current pfmemberdata: ', pfmemberdata);

          let isInPfmembersCore = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].coreMembers &&
            pfmemberdata[0].coreMembers.Skills
          ) {
            isInPfmembersCore =
              pfmemberdata[0].coreMembers.Skills.find(
                (skill) =>
                  skill.CardId === PfoSkills[i]._id &&
                  skill.peopleIds === focused.id
              ) !== undefined;
          }

          let inc = e.coreMembers.Skills.includes(PfoSkills[i]._id);
          if (inc && isInPfmembersCore) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoSkills[i].skillName + " " + `(${GetAliasesName(teams, "Skills")})`,
              cardType: "Skills",
              Id: PfoSkills[i]._id,
            });
          }

          let incs = e.extendedMembers.Skills.includes(PfoSkills[i]._id);

          let isInPfmembersExt = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].extendedMembers &&
            pfmemberdata[0].extendedMembers.Skills
          ) {
            isInPfmembersExt =
              pfmemberdata[0].extendedMembers.Skills.find(
                (skill) =>
                  skill.CardId === PfoSkills[i]._id &&
                  skill.peopleIds === focused.id
              ) !== undefined;
          }

          if (incs && isInPfmembersExt) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoSkills[i].skillName + " " + `(${GetAliasesName(teams, "Skills")})`,
              cardType: "Skills",
              Id: PfoSkills[i]._id,
            });
          }
        });
      }
    }

    if (PfoRoles && PfoRoles.length > 0) {
      for (let i = 0; i < PfoRoles.length; i++) {
        members.map((e, index) => {
          let pfmemberdata = pfmembers.filter(
            (pfe, index) => pfe.memberType === e.memberType
          );
          // console.log('Current pfmemberdata: ', pfmemberdata);

          let inc = e.coreMembers.Roles.includes(PfoRoles[i]._id);

          let isInPfmembersCore = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].coreMembers &&
            pfmemberdata[0].coreMembers.Roles
          ) {
            isInPfmembersCore =
              pfmemberdata[0].coreMembers.Roles.find(
                (role) =>
                  role.CardId === PfoRoles[i]._id &&
                  role.peopleIds === focused.id
              ) !== undefined;
          }

          if (inc && isInPfmembersCore) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoRoles[i].roleName + " " + `(${GetAliasesName(teams, "Roles")})`,
              cardType: "Roles",
              Id: PfoRoles[i]._id,
            });
          }

          let incs = e.extendedMembers.Roles.includes(PfoRoles[i]._id);

          let isInPfmembersExt = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].extendedMembers &&
            pfmemberdata[0].extendedMembers.Roles
          ) {
            isInPfmembersExt =
              pfmemberdata[0].extendedMembers.Roles.find(
                (role) =>
                  role.CardId === PfoRoles[i]._id &&
                  role.peopleIds === focused.id
              ) !== undefined;
          }
          if (incs && isInPfmembersExt) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoRoles[i].roleName + " " + `(${GetAliasesName(teams, "Roles")})`,
              cardType: "Roles",
              Id: PfoRoles[i]._id,
            });
          }
        });
      }
    }

    if (PfoDomains && PfoDomains.length > 0) {
      for (let i = 0; i < PfoDomains.length; i++) {
        members.map((e, index) => {
          let inc = e.coreMembers.Domains.includes(PfoDomains[i]._id);
          let incs = e.extendedMembers.Domains.includes(PfoDomains[i]._id);
          if (inc) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoDomains[i].domainName + " " + `(${GetAliasesName(teams, "Roles")})`,
              cardType: "Domains",
              Id: PfoDomains[i]._id,
            });
          }
          if (incs) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoDomains[i].domainName + " " + `(${GetAliasesName(teams, "Roles")})`,
              cardType: "Domains",
              Id: PfoDomains[i]._id,
            });
          }
        });
      }
    }

    if (PfoLinks && PfoLinks.length > 0) {
      for (let i = 0; i < PfoLinks.length; i++) {
        members.map((e, index) => {
          let inc = e.coreMembers.Links.includes(PfoLinks[i]._id);
          let incs = e.extendedMembers.Links.includes(PfoLinks[i]._id);
          if (inc) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoLinks[i].linkName + " " + `(${GetAliasesName(teams, "Links")})`,
              cardType: "Links",
              Id: PfoLinks[i]._id,
            });
          }
          if (incs) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoLinks[i].linkName + " " + `(${GetAliasesName(teams, "Links")})`,
              cardType: "Links",
              Id: PfoLinks[i]._id,
            });
          }
        });
      }
    }
  }

  var membersInDatas = [];

  if (
    focused &&
    focused.card.length > 0 &&
    memberInDatas &&
    memberInDatas.length > 0
  ) {
    memberInDatas.map((e, index) => {
      let inc = membersInDatas.includes(e.memberId);
      if (!inc) {
        membersInDatas.push(e.memberId);
      }
    });
  }

  if (
    focused &&
    focused.card.length > 0 &&
    membersInDatas &&
    membersInDatas.length > 0
  ) {
    // console.log("Current membersInDatas: ", membersInDatas);
    circlesSorted.map((e, index) => {
      if (!e) return;
      // console.log("Checking circle: ", e);
      let inc = membersInDatas.includes(e._id);
      let duplicate = false;
      if (inc) {
        // If the e._id is in memberdatas
        // If the array is empty: Insert e into array
        if (curPefCircles.length === 0) {
          // console.log("Inserting circle: ", e);
          curPefCircles.push(e);
        } else if (curPefCircles.length > 0) {
          for (let i = 0; i < curPefCircles.length; i++) {
            if (curPefCircles[i]._id === e._id) {
              // console.log("Duplicate found: ", curPefCircles[i]._id, e._id, curPefCircles[i]._id === e._id);
              duplicate = true; // Raise a flag to skip this circle
              break; // break the loop
            }
          }

          if (!duplicate) {
            // If e is in membersInDatas array && duplicate is false: Insert e into array
            // console.log("Inserting circle: ", e);
            curPefCircles.push(e);
          }
        }
      }
    });
  }

  let PfouCircles = [...circlesSorted];
  if (curPefCircles && curPefCircles.length > 0) {
    for (let i = 0; i < curPefCircles.length; i++) {
      PfouCircles = PfouCircles.filter((e) => {
        if (e._id !== curPefCircles[i]._id) {
          return e;
        }
      });
    }
  }

  const [circlefSearch, setCirclefSearch] = useState("");

  const fpCref = useRef(null);
  useEffect(() => {
    if (circlefSearch.length > 0) {
      fpCref.current.focus();
    }
  }, [circlefSearch]);

  var filteredCFData = [];

  if (
    (circlefSearch.length > 0 || allSearch.length > 0) &&
    PfouCircles.length > 0
  ) {
    filteredCFData = PfouCircles.filter((value) => {
      const searchStr = circlefSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCFData.length > 0) {
      ListData.push("Circles");
    }
  }

  if (
    filteredCFData.length === 0 &&
    circlefSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFData = [...PfouCircles];
    if (
      focused &&
      focused.card === "People" &&
      peopleSorted &&
      peopleSorted.length > 0
    ) {
      let foPeople = peopleSorted.find((p) => p._id === focused.id); //peopleSorted[focused.index];
      let leadeData = [];
      filteredCFData = filteredCFData.filter((value) => {
        if (value.lead && foPeople) {
          if (value.lead !== foPeople._id) {
            return value;
          } else if (value.lead === foPeople._id) {
            leadeData.push(value);
          }
        } else if (!value.lead) {
          return value;
        }
      });
      if (leadeData && leadeData.length > 0) {
        curPefCircles = [...leadeData, ...filteredCFData];
      }
    }
  }

  let nonFilteCFData = [];
  if (
    circlefSearch.length > 0 &&
    filteredCFData.length !== PfouCircles.length
  ) {
    nonFilteCFData = [...PfouCircles];
    for (let i = 0; i < filteredCFData.length; i++) {
      nonFilteCFData = nonFilteCFData.filter((value, key) => {
        return value._id !== filteredCFData[i]._id;
      });
    }
    if (filteredCFData.length === 0) {
      nonFilteCFData = [...PfouCircles];
    }
  }

  const cardOpens = (datas) => {
    if (datas.cardType === "People") {
      peopleSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openPeople();
        }
      });
    } else if (datas.cardType === "Skills") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.cardType === "Roles") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openRole();
        }
      });
    } else if (datas.cardType === "Links") {
      linksSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openLink();
        }
      });
    } else if (datas.cardType === "Domains") {
      domainsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openDomain();
        }
      });
    }
  };

  // Edit detailsModel for people focused circle and project both
  const pfcircleOrProjectModes = (e, fCard) => {
    HandelCAndP(e, fCard);
    openPeopleModeTool();

    if (focused && focused.card.length > 0 && focused.card === "People") {
      let focusedPeople = focused.id;
      localStorage.setItem("fpData", focusedPeople);
      localStorage.setItem("pfCAndPrCData", JSON.stringify(e));
    }
  };

  const pFCcARDSub = (elements, popoverId, type) => {
    // console.log('pFCcARDSub');
    if (type === "core" && popoverId && popoverId.coreMembers) {
      if (
        elements.cardType === "People" &&
        popoverId.coreMembers.People &&
        popoverId.coreMembers.People.length > 0
      ) {
        popoverId.coreMembers.People = popoverId.coreMembers.People.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Skills" &&
        popoverId.coreMembers.Skills &&
        popoverId.coreMembers.Skills.length > 0
      ) {
        popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Roles" &&
        popoverId.coreMembers.Roles &&
        popoverId.coreMembers.Roles.length > 0
      ) {
        popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Domains" &&
        popoverId.coreMembers.Domains &&
        popoverId.coreMembers.Domains.length > 0
      ) {
        popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Links" &&
        popoverId.coreMembers.Links &&
        popoverId.coreMembers.Links.length > 0
      ) {
        popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Circles" &&
        popoverId.coreMembers.Circles &&
        popoverId.coreMembers.Circles.length > 0
      ) {
        popoverId.coreMembers.Circles = popoverId.coreMembers.Circles.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Projects" &&
        popoverId.coreMembers.Projects &&
        popoverId.coreMembers.Projects.length > 0
      ) {
        popoverId.coreMembers.Projects = popoverId.coreMembers.Projects.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      }
    } else if (type === "extend" && popoverId && popoverId.extendedMembers) {
      if (
        elements.cardType === "People" &&
        popoverId.extendedMembers.People &&
        popoverId.extendedMembers.People.length > 0
      ) {
        popoverId.extendedMembers.People =
          popoverId.extendedMembers.People.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Skills" &&
        popoverId.extendedMembers.Skills &&
        popoverId.extendedMembers.Skills.length > 0
      ) {
        popoverId.extendedMembers.Skills =
          popoverId.extendedMembers.Skills.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Roles" &&
        popoverId.extendedMembers.Roles &&
        popoverId.extendedMembers.Roles.length > 0
      ) {
        popoverId.extendedMembers.Roles =
          popoverId.extendedMembers.Roles.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Domains" &&
        popoverId.extendedMembers.Domains &&
        popoverId.extendedMembers.Domains.length > 0
      ) {
        popoverId.extendedMembers.Domains =
          popoverId.extendedMembers.Domains.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Links" &&
        popoverId.extendedMembers.Links &&
        popoverId.extendedMembers.Links.length > 0
      ) {
        popoverId.extendedMembers.Links =
          popoverId.extendedMembers.Links.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Circles" &&
        popoverId.extendedMembers.Circles &&
        popoverId.extendedMembers.Circles.length > 0
      ) {
        popoverId.extendedMembers.Circles =
          popoverId.extendedMembers.Circles.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Projects" &&
        popoverId.extendedMembers.Projects &&
        popoverId.extendedMembers.Projects.length > 0
      ) {
        popoverId.extendedMembers.Projects =
          popoverId.extendedMembers.Projects.filter((e) => {
            return e !== elements.Id;
          });
      }
    }
    if (popoverId) {
      let updated_members = [...members];
      let updated_popoverId = { ...popoverId };

      // Find the index of the object to replace in the array
      let indexToReplace = updated_members.findIndex(
        (member) =>
          member.teamId === updated_popoverId.teamId &&
          member.memberType === updated_popoverId.memberType
      );

      // Replace the object in the array
      if (indexToReplace !== -1) {
        updated_members[indexToReplace] = updated_popoverId;
      }

      dispatch({ type: MEMBER_MODIFICATIONS, payload: updated_members });
      setTemporarilyMovedItem(null);

      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const removeCircleFCoreOrExt = (elements, type) => {
    let popoverId = {};
    if (elements && elements.memberId.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === elements.memberId) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };
          if (arr && arr.teamId.length > 0) {
            popoverId = arr;
          }
          break;
        }
      }
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD === "XTD"
    ) {
      pFCcARDSub(elements, popoverId, "extend");
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD !== "XTD"
    ) {
      pFCcARDSub(elements, popoverId, "core");
    }
  };

  const pfCicleLead = (data) => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let flag = false;
      if (data.lead && data.lead.length > 0) {
        let objectId = peopleSorted[focused.index] ? focused.id : null;
        if (objectId === data.lead) {
          // flag = true;
          flag = "direct";
        } else {
          if (skillsSorted && skillsSorted.length > 0 && !flag) {
            for (let i = 0; i < skillsSorted.length; i++) {
              if (
                skillsSorted[i]._id === data.lead &&
                skillsSorted[i].owners &&
                skillsSorted[i].owners.length > 0 &&
                skillsSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
          if (rolesSorted && rolesSorted.length > 0 && !flag) {
            for (let i = 0; i < rolesSorted.length; i++) {
              if (
                rolesSorted[i]._id === data.lead &&
                rolesSorted[i].owners &&
                rolesSorted[i].owners.length > 0 &&
                rolesSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
        }
      }
      return flag;
    }
  };

  /// Projects
  var curPefProjects = [...PfoProjects]; // [...PfoProjects]

  if (
    focused &&
    focused.card.length > 0 &&
    membersInDatas &&
    membersInDatas.length > 0
  ) {
    // console.log("Current membersInDatas: ", membersInDatas);
    projectsSorted.map((e, index) => {
      if (!e) return;
      // console.log("Checking project: ", e);
      let inc = membersInDatas.includes(e._id);
      let duplicate = false;
      if (inc) {
        // If the e._id is in memberdatas
        // If the array is empty: Insert e into array
        if (curPefProjects.length === 0) {
          // console.log("Inserting project: ", e);
          curPefProjects.push(e);
        } else if (curPefProjects.length > 0) {
          for (let i = 0; i < curPefProjects.length; i++) {
            if (curPefProjects[i]._id === e._id) {
              // console.log("Duplicate found: ", curPefProjects[i]._id, e._id, curPefProjects[i]._id === e._id);
              duplicate = true; // Raise a flag to skip this project
              break; // break the loop
            }
          }

          if (!duplicate) {
            // If e is in membersInDatas array && duplicate is false: Insert e into array
            // console.log("Inserting project: ", e);
            curPefProjects.push(e);
          }
        }
      }
    });
  }

  let PfouProjects = [...projectsSorted];
  if (curPefProjects && curPefProjects.length > 0) {
    PfouProjects = PfouProjects.filter((e) => {
      if (!e) return;

      let flag = true;
      for (let i = 0; i < curPefProjects.length; i++) {
        if (e._id === curPefProjects[i]._id) {
          flag = false;
        }
      }
      if (flag) {
        return e;
      }
    });
  }

  const [projectsfSearch, setProjectsfSearch] = useState("");

  const fpPrref = useRef(null);
  useEffect(() => {
    if (projectsfSearch.length > 0) {
      fpPrref.current.focus();
    }
  }, [projectsfSearch]);

  var filteredPrFData = [];

  if (
    (projectsfSearch.length > 0 || allSearch.length > 0) &&
    PfouProjects.length > 0
  ) {
    filteredPrFData = PfouProjects.filter((value) => {
      const searchStr =
        projectsfSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPrFData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Projects");
    }
  }

  if (
    filteredPrFData.length === 0 &&
    projectsfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPrFData = [...PfouProjects];
    if (
      focused &&
      focused.card === "People" &&
      peopleSorted &&
      peopleSorted.length > 0
    ) {
      let foPeople = peopleSorted.find((p) => p._id === focused.id); //peopleSorted[focused.index];
      let leadeData = [];
      filteredPrFData = filteredPrFData.filter((value) => {
        if (value.lead && foPeople) {
          if (value.lead !== foPeople._id) {
            return value;
          } else if (value.lead === foPeople._id) {
            leadeData.push(value);
          }
        } else {
          return value;
        }
      });
      if (leadeData && leadeData.length > 0) {
        curPefProjects = [...leadeData, ...filteredPrFData];
      }
    }
  }

  let nonFiltePrFData = [];
  if (
    projectsfSearch.length > 0 &&
    filteredPrFData.length !== PfouProjects.length
  ) {
    nonFiltePrFData = [...projectsSorted];
    nonFiltePrFData = nonFiltePrFData.filter((value, key) => {
      let flag = true;
      for (let i = 0; i < filteredPrFData.length; i++) {
        if (value._id === filteredPrFData[i]._id) {
          flag = false;
          break;
        }
      }
      if (flag) {
        return value;
      }
    });
    if (filteredPrFData.length === 0) {
      nonFiltePrFData = [...projectsSorted];
    }
  }

  const pfProjectLead = (data) => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let flag = false;
      if (data.lead && data.lead.length > 0) {
        let objectId = focused.id;
        if (objectId === data.lead) {
          // flag = true;
          flag = "direct";
        } else {
          if (skillsSorted && skillsSorted.length > 0 && !flag) {
            for (let i = 0; i < skillsSorted.length; i++) {
              if (
                skillsSorted[i]._id === data.lead &&
                skillsSorted[i].owners &&
                skillsSorted[i].owners.length > 0 &&
                skillsSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
          if (rolesSorted && rolesSorted.length > 0 && !flag) {
            for (let i = 0; i < rolesSorted.length; i++) {
              if (
                rolesSorted[i]._id === data.lead &&
                rolesSorted[i].owners &&
                rolesSorted[i].owners.length > 0 &&
                rolesSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
        }
      }
      return flag;
    }
  };

  const isSkillRoleLead = (data, cardType) => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let focusedPerson = focused.id;
      let allCircles = dCircles
        .map((dc) => {
          return dc;
        })
        .concat(
          curPefCircles.map((c) => {
            return c;
          })
        );
      // console.log(allCircles);
      let flag = false;
      if (allCircles && allCircles.length > 0) {
        allCircles.map((thisCircle) => {
          if (
            thisCircle.lead &&
            thisCircle.lead === data._id &&
            thisCircle.lead === data._id &&
            data.owners.includes(focusedPerson)
          ) {
            flag = true;
            // console.log(thisCircle.circleName, thisCircle.lead, flag)
          }
        });
      }

      return flag;
    }
  };

  const getAdminTasks = (data) => {
    let adminTasks = [];
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let focusedPerson = peopleSorted[focused.index] ? focused.id : null;
      adminTasks = data.administration.filter(
        (admin) => admin.owner === focusedPerson
      );
    }
    return adminTasks;
  };

  //======================================PEOPLE FOCUS MODE======================================================

  const handleDragEndPF = (result) => {
    if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    }

    if (
      result.source.droppableId &&
      result.destination.droppableId &&
      result.source.droppableId === result.destination.droppableId
    ) {
      unfreezeWindow(false);
      return false;
    }

    // console.log(result);
    // console.log(`Dropped in ${result.destination.droppableId}: `);

    /* result.draggableId // - ID of the dragged item
    result.source.index // - source index of the dragged item
    result.destination.index // - destination index of the dragged item */

    if (result.source.droppableId.includes("skills")) {
      let skillDropped = skillsSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "skills-core") {
        // console.log(skillDropped);
        PSkillAdd(skillDropped);
      } else if (result.destination.droppableId === "skills-notmatched") {
        // console.log(skillDropped);
        PSkillSub(skillDropped);
      }
    }

    if (result.source.droppableId.includes("roles")) {
      let roleDropped = rolesSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "roles-core") {
        // console.log(roleDropped);
        PRolesAdd(roleDropped);
      } else if (result.destination.droppableId === "roles-notmatched") {
        // console.log(roleDropped);
        PRolesSub(roleDropped);
      }
    }

    if (result.source.droppableId.includes("domains")) {
      let domainDropped = domainsSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "domains-core") {
        // console.log(domainDropped);
        PDomainsAdd(domainDropped);
      } else if (result.destination.droppableId === "domains-notmatched") {
        // console.log(domainDropped);
        PDomainsSub(domainDropped);
      }
    }

    if (result.source.droppableId.includes("links")) {
      let linkDropped = linksSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "links-core") {
        // console.log(linkDropped);
        PLinksAdd(linkDropped);
      } else if (result.destination.droppableId === "links-notmatched") {
        // console.log(linkDropped);
        PLinksSub(linkDropped);
      }
    }

    if (result.source.droppableId.includes("circles")) {
      let circleDropped = circlesSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "circles-core") {
        // console.log(circleDropped);
        setTemporarilyMovedItem({ cardType: "Circle", card: circleDropped });
        pfcircleOrProjectModes(circleDropped, "Circles");
      } else if (result.destination.droppableId === "circles-notmatched") {
        // console.log(circleDropped);

        let MemData = [];
        for (let i = 0; i < memberInDatas.length; i++) {
          if (memberInDatas[i].memberId === circleDropped._id) {
            MemData.push(memberInDatas[i]);
          }
        }
        let flag = false;
        if (focused && focused.card.length > 0 && focused.card === "People") {
          flag = pfCicleLead(circleDropped);
        }
        let isAddAsP = false;
        if (MemData && MemData.length === 1) {
          if (MemData[0].cardType === "People") {
            isAddAsP = true;
          }
        }
        let curpOIndex = null;
        if (MemData && MemData.length > 0) {
          for (let i = 0; i < MemData.length; i++) {
            if (MemData[i].cardType == "People") {
              curpOIndex = i;
              break;
            }
          }
        }

        if (
          (MemData.length === 1 || isAddAsP || curpOIndex !== null) &&
          (!flag || isAddAsP)
        ) {
          removeCircleFCoreOrExt(MemData[curpOIndex !== null ? curpOIndex : 0]);
        }
      }
    }

    if (result.source.droppableId.includes("projects")) {
      let projectDropped = projectsSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "projects-core") {
        // console.log(projectDropped);
        setTemporarilyMovedItem({ cardType: "Project", card: projectDropped });
        pfcircleOrProjectModes(projectDropped, "Projects");
      } else if (result.destination.droppableId === "projects-notmatched") {
        // console.log(projectDropped);

        let MemData = [];
        for (let i = 0; i < memberInDatas.length; i++) {
          if (memberInDatas[i].memberId === projectDropped._id) {
            MemData.push(memberInDatas[i]);
          }
        }
        let flag = false;
        if (focused && focused.card.length > 0 && focused.card === "People") {
          flag = pfProjectLead(projectDropped);
        }
        let isAddAsP = false;
        if (MemData && MemData.length === 1) {
          if (MemData[0].cardType === "People") {
            isAddAsP = true;
          }
        }
        let curpOIndex = null;
        if (MemData && MemData.length > 0) {
          for (let i = 0; i < MemData.length; i++) {
            if (MemData[i].cardType == "People") {
              curpOIndex = i;
              break;
            }
          }
        }

        if (
          (MemData.length === 1 || isAddAsP || curpOIndex !== null) &&
          (!flag || isAddAsP)
        ) {
          removeCircleFCoreOrExt(MemData[curpOIndex !== null ? curpOIndex : 0]);
        }
      }
    }
  };

  const FPPeople = () => <FPPeopleList peoples={peoples} GetAliasesName={GetAliasesName} teams={teams} peopleSorted={peopleSorted} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} noteOnchange={noteOnchange} note={note} pnoteRef={pnoteRef} handleSave={handleSave} noteSaveData={noteSaveData} PFSotedData={PFSotedData} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} ScrollIntoView={ScrollIntoView} setPepole={setPepole} workloadToggle={workloadToggle} aggrigatedPersonFTE={aggrigatedPersonFTE} skills={skills} roles={roles} dRoles={dRoles} domains={domains} links={links} toggleTagCards={toggleTagCards} focused={focused} handelFocused={handelFocused} lFromState={lFromState} peopleSubmit={peopleSubmit} pref={pref} fName={fName} handleNameChange={handleNameChange} pfNameHandel={pfNameHandel} setFName={setFName} setPepoles={setPepoles} peopleFrom={peopleFrom} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} />;

  const FPSkills = () => <FPSkillsList peoples={peoples} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PfoSkills={PfoSkills} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} handleDragStart={handleDragStart} handleDragEndPF={handleDragEndPF} note={note} handleSave={handleSave} noteSaveData={noteSaveData} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} isSkillRoleLead={isSkillRoleLead} setSkill={setSkill} workloadToggle={workloadToggle} sumFTEBody_Focus={sumFTEBody_Focus} OwnerExist={OwnerExist} PSkillSub={PSkillSub} sPSref={sPSref} skillfSearch={skillfSearch} setSkillfSearch={setSkillfSearch} filteredSFData={filteredSFData} PSkillAdd={PSkillAdd} lFromState={lFromState} skillSubmit={skillSubmit} sref={sref} skill={skill} handleSkillChange={handleSkillChange} setSkills={setSkills} setSkillName={setSkillName} skillsFrom={skillsFrom} focused={focused} list={list} show={show} setShow={setShow} />;

  const FPRoles = () => <FPRolesList peoples={peoples} showGenericRole={showGenericRole} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PfoRoles={PfoRoles} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} handleDragStart={handleDragStart} handleDragEndPF={handleDragEndPF} noteOnchange={noteOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} isSkillRoleLead={isSkillRoleLead} setRole={setRole} workloadToggle={workloadToggle} sumFTEBody_Focus={sumFTEBody_Focus} focused={focused} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} PRolesSub={PRolesSub} SPFRole={SPFRole} fpRref={fpRref} rolefSearch={rolefSearch} setRolefSearch={setRolefSearch} filteredRFData={filteredRFData} PRolesAdd={PRolesAdd} DPFRole={DPFRole} lFromState={lFromState} roleSubmit={roleSubmit} rref={rref} role={role} handleRoleChange={handleRoleChange} setRoles={setRoles} setRoleName={setRoleName} rolesFrom={rolesFrom} list={list} show={show} setShow={setShow} />;

  const FPDomains = () => <FPDomainsList peoples={peoples} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PfoDomains={PfoDomains} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} handleDragStart={handleDragStart} handleDragEndPF={handleDragEndPF} note={note} handleSave={handleSave} noteSaveData={noteSaveData} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} dWhoIsOwnerType={dWhoIsOwnerType} dIsDirectMember={dIsDirectMember} peopleSorted={peopleSorted} focused={focused} setDomain={setDomain} workloadToggle={workloadToggle} sumFTEBody_Focus={sumFTEBody_Focus} cardOpensCfDomain={cardOpensCfDomain} toggleTagCards={toggleTagCards} PDomainsSub={PDomainsSub} fpDref={fpDref} domainfSearch={domainfSearch} setDomainfSearch={setDomainfSearch} filteredDFData={filteredDFData} PDomainsAdd={PDomainsAdd} lFromState={lFromState} domainSubmit={domainSubmit} dref={dref} domain={domain} handleDomainChange={handleDomainChange} setDomains={setDomains} setDomainName={setDomainName} domainsFrom={domainsFrom} list={list} show={show} setShow={setShow} />;

  const FPLinks = () => <FPLinksList peoples={peoples} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PfoLinks={PfoLinks} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} pCardHideOnchange={pCardHideOnchange} handleDragStart={handleDragStart} handleDragEndPF={handleDragEndPF} noteOnchange={noteOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} peopleSorted={peopleSorted} focused={focused} setLink={setLink} workloadToggle={workloadToggle} sumFTEBody_Focus={sumFTEBody_Focus} toggleTagCards={toggleTagCards} PLinksAdd={PLinksAdd} PLinksSub={PLinksSub} fpLref={fpLref} linkfSearch={linkfSearch} setLinkfSearch={setLinkfSearch} filteredLFData={filteredLFData} linksFrom={linksFrom} lFromState={lFromState} linkSubmit={linkSubmit} lref={lref} handleLinkChange={handleLinkChange} setLinks={setLinks} link={link} setLinkName={setLinkName} list={list} show={show} setShow={setShow} />;

  const FPCircles = () => <FPCirclesList peoples={peoples} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} curPefCircles={curPefCircles} getSortIcon={getSortIcon} isInfos={isInfos} setCircles={setCircles} pCardHideOnchange={pCardHideOnchange} handleDragStart={handleDragStart} handleDragEndPF={handleDragEndPF} note={note} handleSave={handleSave} noteSaveData={noteSaveData} toggleTagCards={toggleTagCards} temporarilyMovedItem={temporarilyMovedItem} dCircles={dCircles} PfToCAndP={PfToCAndP} removeCircleFCoreOrExt={removeCircleFCoreOrExt} fpCref={fpCref} circlefSearch={circlefSearch} setCirclefSearch={setCirclefSearch} filteredCFData={filteredCFData} pfcircleOrProjectModes={pfcircleOrProjectModes} lFromState={lFromState} circleSubmit={circleSubmit} cref={cref} circle={circle} handleCircleChange={handleCircleChange} setCircleName={setCircleName} circlesFrom={circlesFrom} isTag={isTag} memberInDatas={memberInDatas} focused={focused} pfCicleLead={pfCicleLead} getAdminTasks={getAdminTasks} ScrollIntoView={ScrollIntoView} setCircle={setCircle} cardOpens={cardOpens} />;

  const FPProjects = () => <FPProjectsList peoples={peoples} tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} curPefProjects={curPefProjects} getSortIcon={getSortIcon} isInfos={isInfos} setProjects={setProjects} pCardHideOnchange={pCardHideOnchange} handleDragStart={handleDragStart} handleDragEndPF={handleDragEndPF} note={note} handleSave={handleSave} noteSaveData={noteSaveData} isTag={isTag} tagFilteredObj={tagFilteredObj} memberInDatas={memberInDatas} focused={focused} pfProjectLead={pfProjectLead} getAdminTasks={getAdminTasks} ScrollIntoView={ScrollIntoView} setProject={setProject} toggleTagCards={toggleTagCards} temporarilyMovedItem={temporarilyMovedItem} fpPrref={fpPrref} projectsfSearch={projectsfSearch} setProjectsfSearch={setProjectsfSearch} filteredPrFData={filteredPrFData} pfcircleOrProjectModes={pfcircleOrProjectModes} lFromState={lFromState} projectSubmit={projectSubmit} prref={prref} handleProjectChange={handleProjectChange} setProjectName={setProjectName} projectsFrom={projectsFrom} cardOpens={cardOpens} PfToCAndP={PfToCAndP} removeCircleFCoreOrExt={removeCircleFCoreOrExt} project={project} />;

  //'''''''''''''''''''''''''''''''''''''''' Xls And Jpg Downlod ''''''''''''''''''''''''''''''''''''''''''''''''

  let peopleFXls = [];
  if (focused && focused.card.length > 0 && focused.card === "People") {
    if (
      peopleSorted[focused.index] ||
      PfoSkills.length > 0 ||
      PfoRoles.length > 0 ||
      PfoDomains.length > 0 ||
      PfoLinks.length > 0 ||
      curPefCircles.length > 0 ||
      curPefProjects.length > 0
    ) {
      let arr = [
        1,
        PfoSkills.length,
        PfoRoles.length,
        PfoDomains.length,
        PfoLinks.length,
        curPefCircles.length,
        curPefProjects.length,
      ];
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });

        // let xlsPeople = [peopleSorted[focused.index]];

        let xlsPeople = [peopleSorted.find((p) => p._id === focused.id)];

        for (let i = 0; i < max; i++) {
          let obj = {
            People:
              xlsPeople[i] && xlsPeople[i]._id
                ? `${xlsPeople[i].fname} ${xlsPeople[i].lname}`
                : "",
            Skill:
              PfoSkills[i] && PfoSkills[i]._id
                ? `${PfoSkills[i].skillName}`
                : "",
            Role:
              PfoRoles[i] && PfoRoles[i]._id ? `${PfoRoles[i].roleName}` : "",
            Domain:
              PfoDomains[i] && PfoDomains[i]._id
                ? `${PfoDomains[i].domainName}`
                : "",
            Link:
              PfoLinks[i] && PfoLinks[i]._id ? `${PfoLinks[i].linkName}` : "",
            Circle:
              curPefCircles[i] && curPefCircles[i]._id
                ? `${curPefCircles[i].circleName}`
                : "",
            Project:
              curPefProjects[i] && curPefProjects[i]._id
                ? `${curPefProjects[i].projectName}`
                : "",
          };

          peopleFXls.push(obj);
        }
      }
    }
  }

  useEffect(() => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      setXls(peopleFXls);
    }
  }, [focused]);

  let tagfiltered_xls_array = [];
  if (focused && focused.card.length === 0 && (isTag || isEXT || isNO)) {

    let sortedPeople = peopleSorted
      .filter((p) => TagWiseSorting(p, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "people"))
      .map((p) => `${p.fname} ${p.lname}`);

    let sortedSkill = skillsSorted
      .filter((s) => TagWiseSorting(s, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "skill"))
      .map((s) => `${s.skillName}`);

    let sortedRole = [...rolesSorted, ...dRoles]
      .filter((r) => TagWiseSorting(r, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "role"))
      .map((r) => `${r.roleName}`);

    let sortedDomain = [...domainsSorted, ...dDomains]
      .filter((d) => TagWiseSorting(d, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "domain"))
      .map((d) => `${d.domainName}`);

    let sortedLink = linksSorted
      .filter((l) => TagWiseSorting(l, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "link"))
      .map((l) => `${l.linkName}`);

    let sortedCircle = [...circlesSorted, ...dCircles]
      .filter((c) => TagWiseSorting(c, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "circle"))
      .map((c) => `${c.circleName}`);

    let sortedProject = projectsSorted
      .filter((pr) => TagWiseSorting(pr, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, "project"))
      .map((pr) => `${pr.projectName}`);

    // Determine the maximum length of all sorted arrays
    const maxLength = Math.max(
      sortedPeople.length,
      sortedSkill.length,
      sortedRole.length,
      sortedDomain.length,
      sortedLink.length,
      sortedCircle.length,
      sortedProject.length
    );

    for (let i = 0; i < maxLength; i++) {
      let obj = {
        People: sortedPeople[i] || "",
        Skill: sortedSkill[i] || "",
        Role: sortedRole[i] || "",
        Domain: sortedDomain[i] || "",
        Link: sortedLink[i] || "",
        Circle: sortedCircle[i] || "",
        Project: sortedProject[i] || ""
      };

      tagfiltered_xls_array.push(obj);
    }

    // console.log(tagfiltered_xls_array);
  }

  useEffect(() => {
    if (focused && focused.card.length == 0 && (isTag || isEXT || isNO)) {
      setXls(tagfiltered_xls_array);
    }
  }, [isTag, isEXT, isNO]);




  //::::::::::::::::::::::::::::::::::::::::::::::Popovers For Circle And Project:::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header className="popover-title">
        Add as
        <a onClick={() => setShow(!show)} className="close">
          ×
        </a>
      </Popover.Header>
      <Popover.Body>
        <div className="popover-content">
          <a
            onClick={() => cFPeopleIdHandel("Core")}
            className="pop-actions"
            id="people-core-971"
            href="#"
          >
            Core member
          </a>
          <a
            onClick={() => cFPeopleIdHandel("Extended")}
            className="pop-actions"
            id="people-extended-971"
            href="#"
          >
            Extended member
          </a>
        </div>
      </Popover.Body>
    </Popover>
  );

  //==============================================Popover Actions==============================================
  // const {members, memberMessagee, memberError, memberMessage} = useSelector(state => state.members)

  const [popoverId, setPopoverId] = useState({
    teamId: null,
    memberType: null,
    coreMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
      Projects: [],
    },
    extendedMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
    },
  });

  const [popoverIds, setPopoverIds] = useState({
    teamId: null,
    memberType: null,
    coreMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
      Projects: [],
    },
    extendedMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
    },
  });

  const [pfmemberId, setPfmemberId] = useState({});
  const [pfmemberIds, setPfmemberIds] = useState({});

  // pfmembers
  useEffect(() => {
    if (
      focused &&
      circlesSorted.length > 0 &&
      focused.card === "Circles" &&
      circlesSorted &&
      circlesSorted.length > 0
    ) {
      let currentCircle = focused.id;

      setPopoverId({
        ...popoverId,
        memberType: currentCircle,
        teamId: localStorage.getItem("teamId"),
      });

      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentCircle) {
          // console.log(10860, 'useEffect pfmembers', members[i]);
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };

          if (arr && arr.teamId.length > 0) {
            // console.log(10882, arr);
            setPopoverId(arr);
            localStorage.setItem("popoverId", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentCircle) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberId(arr);
            localStorage.setItem("pfmemberId", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberId"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentCircle,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          setPfmemberId(arr);
          localStorage.setItem("pfmemberId", JSON.stringify(arr));
        }
      }
    } else if (
      focused &&
      focused.card === "Projects" &&
      projectsSorted &&
      projectsSorted.length > 0
    ) {
      let currentProject = focused.id;

      setPopoverIds({
        ...popoverIds,
        memberType: currentProject,
        teamId: localStorage.getItem("teamId"),
      });

      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentProject) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
            },
          };
          if (arr && arr.teamId.length > 0) {
            setPopoverIds(arr);
            localStorage.setItem("popoverIds", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentProject) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberIds(arr);
            localStorage.setItem("pfmemberIds", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberIds"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentProject,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          setPfmemberIds(arr);
          localStorage.setItem("pfmemberIds", JSON.stringify(arr));
        }
      }
    }
  }, [focused]);

  // Circle and Project member set/ reset from members state/ redux
  const pAndCMemderSet = () => {
    // console.log('pAndCMemderSet');
    if (
      focused &&
      circlesSorted.length > 0 &&
      focused.card === "Circles" &&
      circlesSorted &&
      circlesSorted.length > 0
    ) {
      let currentCircle = focused.id;
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentCircle) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };

          if (arr && arr.teamId.length > 0) {
            setPopoverId(arr);
            localStorage.setItem("popoverId", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentCircle) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberId(arr);
            localStorage.setItem("pfmemberId", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberId"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentCircle,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          localStorage.setItem("pfmemberId", JSON.stringify(arr));
        }
      }
    } else if (
      focused &&
      focused.card === "Projects" &&
      projectsSorted &&
      projectsSorted.length > 0
    ) {
      let currentProject = focused.id;
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentProject) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
            },
          };
          if (arr && arr.teamId.length > 0) {
            setPopoverIds(arr);
            localStorage.setItem("popoverIds", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentProject) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberIds(arr);
            localStorage.setItem("pfmemberIds", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberIds"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentProject,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          localStorage.setItem("pfmemberIds", JSON.stringify(arr));
        }
      }
    }
  };

  // Run pAndCMemderSet function whenever the members state is updated using redux
  // Credit: Indranil
  useEffect(() => {
    // console.log("third", skillsMessagee, roleMessagee);
    if (skillsMessagee || roleMessagee) {
      // let obj = {};
      // obj.userId = myInfo.userId;
      // obj.teamId = pfmemberId.teamId;
      // dispatch(getPFMember(obj))
      // console.log(skillsMessagee, roleMessagee)

      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
    } else {
      pAndCMemderSet();
    }
  }, [members, pfmembers, skillsMessagee, roleMessagee]);

  const cFPeopleHandel = (e, type) => {
    // console.log('cFPeopleHandel');
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragCard", type);
    setTimeout(() => {
      const popoverElement = document.getElementById("popover-basic");
      if (popoverElement) {
        popoverElement.classList.remove("fade");
      }
    }, 300);
    // openPeopleModeTool();
  };

  const cFAction = (popoverId, actionType = "", cardId = "") => {
    // console.log('cFAction', popoverId);
    if (popoverId) {
      // console.log('popoverId', popoverId);
      setPopoverId(popoverId);
      localStorage.setItem("popoverId", JSON.stringify(popoverId));
      // localStorage.removeItem("popoverId");
      // console.log(11334, 'cFAction: ', popoverId);
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/create`;
      popoverId.actionType = actionType;
      popoverId.cardId = cardId;

      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        dispatch(getMember(obj)).then(() => {
          localStorage.removeItem("dragCard");
          localStorage.removeItem("dragData");
          localStorage.removeItem("dragType");
          unfreezeWindow();
        });
        // localStorage.removeItem("dragCard");
        // localStorage.removeItem("dragData");
        // localStorage.removeItem("dragType");
        // unfreezeWindow();
      });
    }
  };

  const cFActionState = (pfmemberId) => {
    // console.log('cFActionState');
    if (pfmemberId) {
      pfmemberId.adminId = myInfo.userId;
      pfmemberId.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = pfmemberId.teamId;
      dispatch(createAndUpdateMember(pfmemberId)).then(() => {
        dispatch(getPFMember(obj));
        localStorage.removeItem("dragPepleCard");
        unfreezeWindow();
      });
    }
  };

  const pFAction = (popoverIds) => {
    // console.log('pFAction');
    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj));
        localStorage.removeItem("dragCard");
        localStorage.removeItem("dragData");
        localStorage.removeItem("dragType");
        unfreezeWindow();
      });
    }
  };

  const pFActionState = (pfmemberIds) => {
    if (pfmemberIds) {
      pfmemberIds.adminId = myInfo.userId;
      pfmemberIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = pfmemberIds.teamId;
      dispatch(createAndUpdateMember(pfmemberIds)).then(() => {
        dispatch(getPFMember(obj));
        localStorage.removeItem("dragPepleCard");
        unfreezeWindow();
      });
    }
  };

  const cFPeopleIdHandel = (type, isPFCircle) => {
    let popoverValue = localStorage.getItem("dragCard");
    // console.log(popoverValue)
    let popoverMember = JSON.parse(localStorage.getItem("dragData"));
    // console.log(popoverMember)
    // console.log(focused)
    // console.log('cFPeopleIdHandel: ', popoverValue, popoverMember);
    if (focused.card === "Circles") {
      // console.log("popoverid", popoverId, popoverId.coreMembers);
      if (popoverValue === "People" && popoverMember) {
        // console.log("Adding People: ", popoverMember, popoverId);
        if (type === "Core") {
          popoverId.coreMembers.People = [
            ...new Set([...popoverId.coreMembers.People, popoverMember._id]),
          ];
        } else if (type === "Extended") {
          popoverId.extendedMembers.People = [
            ...new Set([
              ...popoverId.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
        } else if (type === "cToe") {
          popoverId.coreMembers.People = popoverId.coreMembers.People.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.People = [
            ...new Set([
              ...popoverId.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
        } else if (type === "eToc") {
          popoverId.extendedMembers.People =
            popoverId.extendedMembers.People.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.People = [
            ...new Set([...popoverId.coreMembers.People, popoverMember._id]),
          ];
        }

        if (popoverId) {
          cFAction(popoverId);
        }
      } else if (popoverValue === "Skills" && popoverMember) {
        // console.log("Adding Skill: ", popoverMember, popoverId);
        if (type === "Core") {
          popoverId.coreMembers.Skills = [
            ...new Set([...popoverId.coreMembers.Skills, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Skills = [
            ...new Set([
              ...popoverId.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);
        } else if (type === "cToe") {
          popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Skills = [
            ...new Set([
              ...popoverId.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Skills =
              pfmemberId.coreMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.extendedMembers.Skills = [
              ...new Set([
                ...pfmemberId.extendedMembers.Skills,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Skills =
            popoverId.extendedMembers.Skills.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Skills = [
            ...new Set([...popoverId.coreMembers.Skills, popoverMember._id]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.extendedMembers.Skills =
              pfmemberId.extendedMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Skills = [
              ...new Set([...pfmemberId.coreMembers.Skills, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Roles" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Roles = [
            ...new Set([...popoverId.coreMembers.Roles, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Roles = [
            ...new Set([...popoverId.extendedMembers.Roles, popoverMember._id]),
          ];
        } else if (type === "cToe") {
          popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Roles = [
            ...new Set([...popoverId.extendedMembers.Roles, popoverMember._id]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Roles = pfmemberId.coreMembers.Roles.filter(
              (e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              }
            );
            pfmemberId.extendedMembers.Roles = [
              ...new Set([
                ...pfmemberId.extendedMembers.Roles,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Roles =
            popoverId.extendedMembers.Roles.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Roles = [
            ...new Set([...popoverId.coreMembers.Roles, popoverMember._id]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.extendedMembers.Roles =
              pfmemberId.extendedMembers.Roles.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Roles = [
              ...new Set([...pfmemberId.coreMembers.Roles, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Domains" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Domains = [
            ...new Set([...popoverId.coreMembers.Domains, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Domains = [
            ...new Set([
              ...popoverId.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);
        } else if (type === "cToe") {
          popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Domains = [
            ...new Set([
              ...popoverId.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Domains =
              pfmemberId.coreMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.extendedMembers.Domains = [
              ...new Set([
                ...pfmemberId.extendedMembers.Domains,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Domains =
            popoverId.extendedMembers.Domains.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Domains = [
            ...new Set([...popoverId.coreMembers.Domains, popoverMember._id]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.extendedMembers.Domains =
              pfmemberId.extendedMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Domains = [
              ...new Set([...pfmemberId.coreMembers.Domains, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Links" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Links = [
            ...new Set([...popoverId.coreMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Links = [
            ...new Set([...popoverId.extendedMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "cToe") {
          popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Links = [
            ...new Set([...popoverId.extendedMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Links = pfmemberId.coreMembers.Links.filter(
              (e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              }
            );
            pfmemberId.extendedMembers.Links = [
              ...new Set([
                ...pfmemberId.extendedMembers.Links,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Links =
            popoverId.extendedMembers.Links.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Links = [
            ...new Set([...popoverId.coreMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = {};
            pfmemberId.extendedMembers.Links =
              pfmemberId.extendedMembers.Links.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Links = [
              ...new Set([...pfmemberId.coreMembers.Links, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Projects" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Projects = [
            ...new Set([...popoverId.coreMembers.Projects, popoverMember._id]),
          ];
          cFAction(popoverId);
        }
      }
    } else if (focused.card === "Projects") {
      if (popoverValue === "People" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.People = [
            ...new Set([...popoverIds.coreMembers.People, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.People = [
            ...new Set([
              ...popoverIds.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.People = popoverIds.coreMembers.People.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.People = [
            ...new Set([
              ...popoverIds.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "eToc") {
          popoverIds.extendedMembers.People =
            popoverIds.extendedMembers.People.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.People = [
            ...new Set([...popoverIds.coreMembers.People, popoverMember._id]),
          ];
          pFAction(popoverIds);
        }
      } else if (popoverValue === "Skills" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Skills = [
            ...new Set([...popoverIds.coreMembers.Skills, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Skills = [
            ...new Set([
              ...popoverIds.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Skills = popoverIds.coreMembers.Skills.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.Skills = [
            ...new Set([
              ...popoverIds.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Skills =
              pfmemberIds.coreMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Skills = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Skills,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Skills =
            popoverIds.extendedMembers.Skills.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Skills = [
            ...new Set([...popoverIds.coreMembers.Skills, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Skills =
              pfmemberIds.extendedMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Skills = [
              ...new Set([...pfmemberIds.coreMembers.Skills, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Roles" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Roles = [
            ...new Set([...popoverIds.coreMembers.Roles, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Roles = [
            ...new Set([
              ...popoverIds.extendedMembers.Roles,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Roles = popoverIds.coreMembers.Roles.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.Roles = [
            ...new Set([
              ...popoverIds.extendedMembers.Roles,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Roles =
              pfmemberIds.coreMembers.Roles.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Roles = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Roles,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Roles =
            popoverIds.extendedMembers.Roles.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Roles = [
            ...new Set([...popoverIds.coreMembers.Roles, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Roles =
              pfmemberIds.extendedMembers.Roles.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Roles = [
              ...new Set([...pfmemberIds.coreMembers.Roles, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Domains" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Domains = [
            ...new Set([...popoverIds.coreMembers.Domains, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Domains = [
            ...new Set([
              ...popoverIds.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Domains =
            popoverIds.coreMembers.Domains.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.extendedMembers.Domains = [
            ...new Set([
              ...popoverIds.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Domains =
              pfmemberIds.coreMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Domains = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Domains,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Domains =
            popoverIds.extendedMembers.Domains.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Domains = [
            ...new Set([...popoverIds.coreMembers.Domains, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Domains =
              pfmemberIds.extendedMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Domains = [
              ...new Set([...pfmemberIds.coreMembers.Domains, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Links" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Links = [
            ...new Set([...popoverIds.coreMembers.Links, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Links = [
            ...new Set([
              ...popoverIds.extendedMembers.Links,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Links = popoverIds.coreMembers.Links.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.Links = [
            ...new Set([
              ...popoverIds.extendedMembers.Links,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Links =
              pfmemberIds.coreMembers.Links.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Links = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Links,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Links =
            popoverIds.extendedMembers.Links.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Links = [
            ...new Set([...popoverIds.coreMembers.Links, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Links =
              pfmemberIds.extendedMembers.Links.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Links = [
              ...new Set([...pfmemberIds.coreMembers.Links, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Circles" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Circles = [
            ...new Set([...popoverIds.coreMembers.Circles, popoverMember._id]),
          ];
          pFAction(popoverIds);
        }
      }
    }


    setTimeout(() => {
      const latestCard = document.querySelector(`.card[data-card_id="${popoverMember._id}"]`);
      if (latestCard) {
        // console.log(latestCard.parentNode.parentNode.parentNode);
        latestCard.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling animation
          block: 'center',    // Align the element vertically in the center
          inline: 'nearest'   // Align horizontally if necessary
        });

        latestCard.classList.add('latest-created');

        // latestCard.parentNode.parentNode.parentNode.scrollTop
      } else {
        console.warn('No element with the class "latestCard" was found.');
      }
    }, 1000);
  };

  //=========================================Circle FOCUSED Action===================================================

  const cFpeopleSub = (data, type) => {
    // console.log(11802, 'Removing People: ', data, popoverId);
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.People
    ) {
      popoverId.coreMembers.People = popoverId.coreMembers.People.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.People
    ) {
      popoverId.extendedMembers.People =
        popoverId.extendedMembers.People.filter((e) => {
          return e !== data._id;
        });
    }
    // console.log(11808, 'Members after people is removed: ', data, popoverId);

    if (popoverId) {
      cFAction(popoverId);
    }
  };

  const cFskillSub = (data, type, isPFCircle) => {
    // console.log(11827, 'Removing Skill: ', data, popoverId);

    let updated_pfmemberId = { ...pfmemberId };

    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Skills
    ) {
      popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Skills
    ) {
      popoverId.extendedMembers.Skills =
        popoverId.extendedMembers.Skills.filter((e) => {
          return e !== data._id;
        });
    }

    if (
      type === "core" &&
      updated_pfmemberId &&
      updated_pfmemberId.coreMembers &&
      updated_pfmemberId.coreMembers.Skills &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberId.coreMembers,
          Skills: updated_pfmemberId.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          core: updated_pfmemberId.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Skills
    ) {
      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberId.extendedMembers,
          Skills: updated_pfmemberId.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          extended: updated_pfmemberId.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    }

    // console.log(11841, 'Members after skill is removed: ', data, popoverId);

    if (popoverId) {
      cFAction(popoverId);
    }

    if (updated_pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(updated_pfmemberId);
    }
  };

  const cFroleSub = (data, type, isPFCircle) => {
    // console.log(11827, 'Removing Skill: ', data, popoverId);

    let updated_pfmemberId = { ...pfmemberId };

    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Roles
    ) {
      popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter((e) => {
        return e !== data._id;
      });
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Roles
    ) {
      popoverId.extendedMembers.Roles = popoverId.extendedMembers.Roles.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }

    if (
      type === "core" &&
      updated_pfmemberId &&
      updated_pfmemberId.coreMembers &&
      updated_pfmemberId.coreMembers.Roles &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      // console.log(pfmemberId);

      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberId.coreMembers,
          Skills: updated_pfmemberId.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          core: updated_pfmemberId.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Roles
    ) {
      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberId.extendedMembers,
          Skills: updated_pfmemberId.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          extended: updated_pfmemberId.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    }

    if (popoverId) {
      cFAction(popoverId);
    }

    if (updated_pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(updated_pfmemberId);
    }
  };

  const cFdomainSub = (data, type, isPFCircle) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Domains
    ) {
      popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Domains
    ) {
      popoverId.extendedMembers.Domains =
        popoverId.extendedMembers.Domains.filter((e) => {
          return e !== data._id;
        });
    }

    if (
      type === "core" &&
      pfmemberId &&
      pfmemberId.coreMembers &&
      pfmemberId.coreMembers.Domains &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      pfmemberId.coreMembers.Domains = pfmemberId.coreMembers.Domains.filter(
        (e) => {
          return e.CardId !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Domains
    ) {
      pfmemberId.extendedMembers.Domains =
        pfmemberId.extendedMembers.Domains.filter((e) => {
          return e.CardId !== data._id;
        });
    }

    if (popoverId) {
      cFAction(popoverId);
    }

    if (pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(pfmemberId);
    }
  };

  const cFlinkSub = (data, type, isPFCircle) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Links
    ) {
      popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter((e) => {
        return e !== data._id;
      });
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Links
    ) {
      popoverId.extendedMembers.Links = popoverId.extendedMembers.Links.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }

    if (
      type === "core" &&
      pfmemberId &&
      pfmemberId.coreMembers &&
      pfmemberId.coreMembers.Links &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      pfmemberId.coreMembers.Links = pfmemberId.coreMembers.Links.filter(
        (e) => {
          return e.CardId !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Links
    ) {
      pfmemberId.extendedMembers.Links =
        pfmemberId.extendedMembers.Links.filter((e) => {
          return e.CardId !== data._id;
        });
    }

    if (popoverId) {
      cFAction(popoverId);
    }

    if (pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(pfmemberId);
    }
  };

  const cFprojectSub = (data, type) => {
    // console.log(10834, data._id)
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Projects
    ) {
      popoverId.coreMembers.Projects = popoverId.coreMembers.Projects.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }
    if (popoverId) {
      cFAction(popoverId, "remove", data._id);
    }
  };

  //=========================================Circle FOCUSED Drag and Drop===================================================

  //People
  const cFPonDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "People");
  };

  const cFPonDragOver = (e) => {
    e.preventDefault();
  };

  const cFPonDrop = (type) => {
    let cFPonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFPonDragType = localStorage.getItem("dragType");
    if (type === "cFpCore") {
      if (cFPonDragType === "cFpExtend") {
        cFPeopleIdHandel("eToc");
      } else if (cFPonDragType === "cFpDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "cFpExtend") {
      if (cFPonDragType === "cFpCore") {
        cFPeopleIdHandel("cToe");
      } else if (cFPonDragType === "cFpDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "cFpDefult") {
      if (cFPonDragType === "cFpExtend") {
        cFpeopleSub(cFPonDragData, "extend");
      } else if (cFPonDragType === "cFpCore") {
        cFpeopleSub(cFPonDragData, "core");
      }
    }
  };

  //Skills
  const cFSonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Skills");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFSonDragOver = (e) => {
    e.preventDefault();
  };

  const cFSonDrop = (type) => {
    let cFSonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFSonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    // console.log(cFSonDragData)
    if (type === "cFsCore") {
      if (cFSonDragType === "cFsExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFSonDragType === "cFsDefult") {
        // cFPeopleIdHandel("Core", isPFCircle);
        cfcircleOrProjectModes(cFSonDragData, "Skills", "Core");
      }
    } else if (type === "cFsExtend") {
      if (cFSonDragType === "cFsCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFSonDragType === "cFsDefult") {
        // cFPeopleIdHandel("Extended", isPFCircle);
        cfcircleOrProjectModes(cFSonDragData, "Skills", "Extended");
      }
    } else if (type === "cFsDefult") {
      if (cFSonDragType === "cFsExtend") {
        cFskillSub(cFSonDragData, "extend", isPFCircle);
      } else if (cFSonDragType === "cFsCore") {
        cFskillSub(cFSonDragData, "core", isPFCircle);
      }
    }
  };

  //Roles
  const cFRonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Roles");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFRonDragOver = (e) => {
    e.preventDefault();
  };

  const cFRonDrop = (type) => {
    let cFRonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFRonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "cFrCore") {
      if (cFRonDragType === "cFrExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFRonDragType === "cFrDefult") {
        // cFPeopleIdHandel("Core", isPFCircle);
        cfcircleOrProjectModes(cFRonDragData, "Roles", "Core");
      }
    } else if (type === "cFrExtend") {
      if (cFRonDragType === "cFrCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFRonDragType === "cFrDefult") {
        // cFPeopleIdHandel("Extended", isPFCircle);
        cfcircleOrProjectModes(cFRonDragData, "Roles", "Extended");
      }
    } else if (type === "cFrDefult") {
      if (cFRonDragType === "cFrExtend") {
        cFroleSub(cFRonDragData, "extend", isPFCircle);
      } else if (cFRonDragType === "cFrCore") {
        cFroleSub(cFRonDragData, "core", isPFCircle);
      }
    }
  };

  //Domains
  const cFDonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Domains");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFDonDragOver = (e) => {
    e.preventDefault();
  };

  const cFDonDrop = (type) => {
    let cFDonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFDonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    // console.log(type, cFDonDragData, cFDonDragType, isPFCircle);

    if (type === "cFdCore") {
      // A domain is dragged from notmatched to core
      if (cFDonDragType === "cFdExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFDonDragType === "cFdDefult") {
        cFPeopleIdHandel("Core", isPFCircle);
      }
    } else if (type === "cFdExtend") {
      if (cFDonDragType === "cFdCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFDonDragType === "cFdDefult") {
        cFPeopleIdHandel("Extended", isPFCircle);
      }
    } else if (type === "cFdDefult") {
      if (cFDonDragType === "cFdExtend") {
        cFdomainSub(cFDonDragData, "extend", isPFCircle);
      } else if (cFDonDragType === "cFdCore") {
        cFdomainSub(cFDonDragData, "core", isPFCircle);
      }
    }
  };

  //Links
  const cFLonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Links");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFLonDragOver = (e) => {
    e.preventDefault();
  };

  const cFLonDrop = (type) => {
    let cFLonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFLonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "cFlCore") {
      if (cFLonDragType === "cFlExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFLonDragType === "cFlDefult") {
        cFPeopleIdHandel("Core", isPFCircle);
      }
    } else if (type === "cFlExtend") {
      if (cFLonDragType === "cFlCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFLonDragType === "cFlDefult") {
        cFPeopleIdHandel("Extended", isPFCircle);
      }
    } else if (type === "cFlDefult") {
      if (cFLonDragType === "cFlExtend") {
        cFlinkSub(cFLonDragData, "extend", isPFCircle);
      } else if (cFLonDragType === "cFlCore") {
        cFlinkSub(cFLonDragData, "core", isPFCircle);
      }
    }
  };

  //Projects
  const cFPronDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Projects");
  };

  const cFPronDragOver = (e) => {
    e.preventDefault();
  };

  const cFPronDrop = (type) => {
    let cFPronDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFPronDragType = localStorage.getItem("dragType");
    if (type === "cFPrCore") {
      if (cFPronDragType === "cFPrDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "cFPrDefult") {
      if (cFPronDragType === "cFPrCore") {
        cFprojectSub(cFPronDragData, "core");
      }
    }
  };

  //=========================================Circle FOCUSED algorithm===================================================

  ///Skills
  const [cfSkillSearch, setCfSkillSearch] = useState("");

  const fcSref = useRef(null);
  useEffect(() => {
    if (cfSkillSearch.length > 0) {
      fcSref.current.focus();
    }
  }, [cfSkillSearch]);

  var filteredCFSData = [];

  if (
    (cfSkillSearch.length > 0 || allSearch.length > 0) &&
    CedeffoSkill.length > 0
  ) {
    filteredCFSData = CedeffoSkill.filter((value) => {
      const searchStr = cfSkillSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCFSData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Skills");
    }
  }

  if (
    filteredCFSData.length === 0 &&
    cfSkillSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFSData = [...CedeffoSkill];
  }

  let nonFilteSFPData = [];
  if (
    cfSkillSearch.length > 0 &&
    filteredCFSData.length !== CedeffoSkill.length
  ) {
    nonFilteSFPData = [...CedeffoSkill];
    for (let i = 0; i < filteredCFSData.length; i++) {
      nonFilteSFPData = nonFilteSFPData.filter((value, key) => {
        return value._id !== filteredCFSData[i]._id;
      });
    }
    if (filteredCFSData.length === 0) {
      nonFilteSFPData = [...CedeffoSkill];
    }
  }

  const isPFCircleAddS = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let cuPeoples = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Skills.length; j++) {
            if (pfmembers[i].coreMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Skills[j].peopleIds.toString()
                ) {
                  cuPeoples.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                  // return;
                }
              }
            }
          }
          return cuPeoples;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let cupPeople = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Skills.length; j++) {
            if (pfmembers[i].extendedMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Skills[j].peopleIds.toString()
                ) {
                  cupPeople.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                }
              }
            }
          }
          return cupPeople;
        }
      }
      return [];
    }
  };

  const OpenCFPeople = (Id) => {
    if (peopleSorted && peopleSorted.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        if (peopleSorted[i]._id.toString() === Id.toString()) {
          setPepole(peopleSorted[i]);
          break;
        }
      }
    }
  };

  // Edit detailsModel for people focused circle and project both

  const cfcircleOrProjectModes = (e, type, TypeLoc) => {
    if (focused && focused.card.length > 0 && focused.card === "Circles") {
      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      localStorage.setItem("fcData", focused.id);
      localStorage.setItem("fcDatas", JSON.stringify(focusedCircle));
      localStorage.setItem("dragData", JSON.stringify(e));
      localStorage.setItem("dragCard", type);
    } else if (
      focused &&
      focused.card.length > 0 &&
      focused.card === "Projects"
    ) {
      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      localStorage.setItem("fcData", focused.id);
      localStorage.setItem("fcDatas", JSON.stringify(focusedProject));
      localStorage.setItem("dragData", JSON.stringify(e));
      localStorage.setItem("dragCard", type);
    }
    if (TypeLoc && TypeLoc.length > 0) {
      localStorage.setItem("TypeLoc", TypeLoc);
    }
    pAndCMemderSet();
    openCircleModeTool();

    setTemporarilyMovedItem({ cardType: type, area: TypeLoc, card: e });
  };

  ///Roles
  const [cfRolesSearch, setCfRolesSearch] = useState("");

  const fcRref = useRef(null);
  useEffect(() => {
    if (cfRolesSearch.length > 0) {
      fcRref.current.focus();
    }
  }, [cfRolesSearch]);

  var filteredCFRData = [];

  if (
    (cfRolesSearch.length > 0 || allSearch.length > 0) &&
    CedeffoRoles.length > 0
  ) {
    filteredCFRData = CedeffoRoles.filter((value) => {
      const searchStr = cfRolesSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCFRData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Roles");
    }
  }

  if (
    filteredCFRData.length === 0 &&
    cfRolesSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFRData = [...CedeffoRoles];
  }

  let nonFilteRFCData = [];
  if (
    cfRolesSearch.length > 0 &&
    filteredCFRData.length !== CedeffoRoles.length
  ) {
    nonFilteRFCData = [...CedeffoRoles];
    for (let i = 0; i < filteredCFRData.length; i++) {
      nonFilteRFCData = nonFilteRFCData.filter((value, key) => {
        return value._id !== filteredCFRData[i]._id;
      });
    }
    if (filteredCFSData.length === 0) {
      nonFilteRFCData = [...CedeffoRoles];
    }
  }

  const isPFCircleAddR = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let cuPeoples = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Roles.length; j++) {
            if (pfmembers[i].coreMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Roles[j].peopleIds.toString() &&
                  !cuPeoples.some(
                    (cp) =>
                      cp.Id === peopleSorted[k]._id.toString() &&
                      cp.name ===
                      `${peopleSorted[k].fname} ${peopleSorted[k].lname}`
                  )
                ) {
                  cuPeoples.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                }
              }
            }
          }
          return cuPeoples;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let cuPeoples = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Roles.length; j++) {
            if (pfmembers[i].extendedMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Roles[
                    j
                  ].peopleIds.toString() &&
                  !cuPeoples.some(
                    (cp) =>
                      cp.Id === peopleSorted[k]._id.toString() &&
                      cp.name ===
                      `${peopleSorted[k].fname} ${peopleSorted[k].lname}`
                  )
                ) {
                  cuPeoples.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                }
              }
            }
          }
          return cuPeoples;
        }
      }
      return [];
    }
  };

  /// Domains
  const [cfDomainsSearch, setCfDomainsSearch] = useState("");

  const fcDref = useRef(null);
  useEffect(() => {
    if (cfDomainsSearch.length > 0) {
      fcDref.current.focus();
    }
  }, [cfDomainsSearch]);

  var filteredCFDData = [];

  if (
    (cfDomainsSearch.length > 0 || allSearch.length > 0) &&
    CedeffoDomains.length > 0
  ) {
    filteredCFDData = CedeffoDomains.filter((value) => {
      const searchStr = cfDomainsSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCFDData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Domains");
    }
  }

  if (
    filteredCFDData.length === 0 &&
    cfDomainsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFDData = [...CedeffoDomains];
  }

  let nonFilteDFCData = [];
  if (
    cfDomainsSearch.length > 0 &&
    filteredCFDData.length !== CedeffoDomains.length
  ) {
    nonFilteDFCData = [...CedeffoDomains];
    for (let i = 0; i < filteredCFDData.length; i++) {
      nonFilteDFCData = nonFilteDFCData.filter((value, key) => {
        return value._id !== filteredCFDData[i]._id;
      });
    }
    if (filteredCFDData.length === 0) {
      nonFilteDFCData = [...CedeffoDomains];
    }
  }

  let cfRAnfdSDomain = [];
  if (focused && focused.card === "Circles") {
    if (CcorfoSkill && CcorfoSkill.length > 0) {
      for (let i = 0; i < CcorfoSkill.length; i++) {
        if (CcorfoSkill[i].domains && CcorfoSkill[i].domains.length > 0) {
          for (let j = 0; j < CcorfoSkill[i].domains.length; j++) {
            if (CcorfoSkill[i].domains[j].domain) {
              cfRAnfdSDomain.push({
                cardId: CcorfoSkill[i]._id,
                cardName: CcorfoSkill[i].skillName,
                type: "Skill",
                domainId: CcorfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (CcorfoRoles && CcorfoRoles.length > 0) {
      for (let i = 0; i < CcorfoRoles.length; i++) {
        if (CcorfoRoles[i].domains && CcorfoRoles[i].domains.length > 0) {
          for (let j = 0; j < CcorfoRoles[i].domains.length; j++) {
            if (CcorfoRoles[i].domains[j].domain) {
              cfRAnfdSDomain.push({
                cardId: CcorfoRoles[i]._id,
                cardName: CcorfoRoles[i].roleName,
                type: "Role",
                domainId: CcorfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }

    // V3-772 domains disappear

    /* if (cfRAnfdSDomain && cfRAnfdSDomain.length > 0) {
      if (filteredCFDData && filteredCFDData.length > 0) {
        let addNewDoamin = [];
        filteredCFDData = filteredCFDData.filter((value, key) => {
          let flag = true;
          for (let i = 0; i < cfRAnfdSDomain.length; i++) {
            if (cfRAnfdSDomain[i].domainId === value._id) {
              console.log(11238, value);
              addNewDoamin.push(value);
              flag = false;
              break;
            }
          }
          if (flag) {
            return value;
          }
        });
        if (addNewDoamin && addNewDoamin.length > 0) {
          CcorfoDomains = [...CcorfoDomains, ...addNewDoamin];
        }
      }
    } */
  }

  let cfRAnfdSExDomain = [];
  if (focused && focused.card === "Circles") {
    if (CextfoSkill && CextfoSkill.length > 0) {
      for (let i = 0; i < CextfoSkill.length; i++) {
        if (CextfoSkill[i].domains && CextfoSkill[i].domains.length > 0) {
          for (let j = 0; j < CextfoSkill[i].domains.length; j++) {
            if (CextfoSkill[i].domains[j].domain) {
              cfRAnfdSExDomain.push({
                cardId: CextfoSkill[i]._id,
                cardName: CextfoSkill[i].skillName,
                type: "Skill",
                domainId: CextfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (CextfoRoles && CextfoRoles.length > 0) {
      for (let i = 0; i < CextfoRoles.length; i++) {
        if (CextfoRoles[i].domains && CextfoRoles[i].domains.length > 0) {
          for (let j = 0; j < CextfoRoles[i].domains.length; j++) {
            if (CextfoRoles[i].domains[j].domain) {
              cfRAnfdSExDomain.push({
                cardId: CextfoRoles[i]._id,
                cardName: CextfoRoles[i].roleName,
                type: "Role",
                domainId: CextfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    // if (cfRAnfdSExDomain && cfRAnfdSExDomain.length > 0) {
    //   if (filteredCFDData && filteredCFDData.length > 0) {
    //     let addNewDoamin = [];
    //     filteredCFDData = filteredCFDData.filter((value, key) => {
    //       let flag = true;
    //       for (let i = 0; i < cfRAnfdSExDomain.length; i++) {
    //         if (cfRAnfdSExDomain[i].domainId === value._id) {
    //           addNewDoamin.push(value);
    //           flag = false;
    //           break;
    //         }
    //       }
    //       if (flag) {
    //         return value;
    //       }
    //     });
    //     if (addNewDoamin && addNewDoamin.length > 0) {
    //       CextfoDomains = [...CextfoDomains, ...addNewDoamin];
    //     }
    //   }
    // }
  }

  const isPFCircleAddD = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Domains.length; j++) {
            if (pfmembers[i].coreMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (
            let j = 0;
            j < pfmembers[i].extendedMembers.Domains.length;
            j++
          ) {
            if (pfmembers[i].extendedMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  const cardOpensCfDomain = (datas) => {
    // console.log(12903, datas);
    if (datas.type === "Skill") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.cardId.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.type === "Role") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.cardId.toString()) {
          indexSet(value);
          openRole();
        }
      });
    }
  };

  //Links
  const [cfLinksSearch, setCfLinksSearch] = useState("");

  const fcLref = useRef(null);
  useEffect(() => {
    if (cfLinksSearch.length > 0) {
      fcLref.current.focus();
    }
  }, [cfLinksSearch]);

  var filteredCFLData = [];

  if (
    (cfLinksSearch.length > 0 || allSearch.length > 0) &&
    CedeffoLinks.length > 0
  ) {
    filteredCFLData = CedeffoLinks.filter((value) => {
      const searchStr = cfLinksSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCFLData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Links");
    }
  }

  if (
    filteredCFLData.length === 0 &&
    cfLinksSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFLData = [...CedeffoLinks];
  }

  let nonFilteLFCData = [];
  if (
    cfLinksSearch.length > 0 &&
    filteredCFLData.length !== CedeffoLinks.length
  ) {
    nonFilteLFCData = [...CedeffoLinks];
    for (let i = 0; i < filteredCFLData.length; i++) {
      nonFilteLFCData = nonFilteLFCData.filter((value, key) => {
        return value._id !== filteredCFLData[i]._id;
      });
    }
    if (filteredCFLData.length === 0) {
      nonFilteLFCData = [...CedeffoLinks];
    }
  }

  const isPFCircleAddL = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Links.length; j++) {
            if (pfmembers[i].coreMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (let j = 0; j < pfmembers[i].extendedMembers.Links.length; j++) {
            if (pfmembers[i].extendedMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  // Circles
  let CFCircleSorted = [...circlesSorted];

  // Project
  const [cfProjectsSearch, setCfProjectsSearch] = useState("");

  const fcPrref = useRef(null);
  useEffect(() => {
    if (cfProjectsSearch.length > 0) {
      fcPrref.current.focus();
    }
  }, [cfProjectsSearch]);

  var filteredCFPrData = [];

  if (
    (cfProjectsSearch.length > 0 || allSearch.length > 0) &&
    CedeffoProjects.length > 0
  ) {
    filteredCFPrData = CedeffoProjects.filter((value) => {
      const searchStr = cfProjectsSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredCFPrData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Projects");
    }
  }

  if (
    filteredCFPrData.length === 0 &&
    cfProjectsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFPrData = [...CedeffoProjects];
  }

  let nonFilteCFPrData = [];
  if (
    cfProjectsSearch.length > 0 &&
    filteredCFPrData.length !== CedeffoProjects.length
  ) {
    nonFilteCFPrData = [...CedeffoProjects];
    for (let i = 0; i < filteredCFPrData.length; i++) {
      nonFilteCFPrData = nonFilteCFPrData.filter((value, key) => {
        return value._id !== filteredCFPrData[i]._id;
      });
    }
    if (filteredCFPrData.length === 0) {
      nonFilteCFPrData = [...CedeffoProjects];
    }
  }

  //People
  const [cfPeopleSearch, setCfPeopleSearch] = useState("");
  const fcPref = useRef(null);
  useEffect(() => {
    if (cfPeopleSearch.length > 0) {
      fcPref.current.focus();
    }
  }, [cfPeopleSearch]);

  var filteredCFPData = [];

  if (
    (cfPeopleSearch.length > 0 || allSearch.length > 0) &&
    CedeffoPeople.length > 0
  ) {
    filteredCFPData = CedeffoPeople.filter((value) => {
      const searchStr = cfPeopleSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.fname} ${value.lname}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFPData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("People");
    }
  }

  if (
    filteredCFPData.length === 0 &&
    cfPeopleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFPData = [...CedeffoPeople];
  }

  let nonFilteCFPData = [];
  if (
    cfPeopleSearch.length > 0 &&
    filteredCFPData.length !== CedeffoPeople.length
  ) {
    nonFilteCFPData = [...CedeffoPeople];
    for (let i = 0; i < filteredCFPData.length; i++) {
      nonFilteCFPData = nonFilteCFPData.filter((value, key) => {
        return value._id !== filteredCFPData[i]._id;
      });
    }
    if (filteredCFPData.length === 0) {
      nonFilteCFPData = [...CedeffoPeople];
    }
  }

  let curPFMemberState = {};
  if (focused && focused.card === "Circles" && focused.id) {
    let cFDataId = focused.id;
    if (cFDataId && pfmembers && pfmembers.length > 0) {
      for (let i = 0; i < pfmembers.length; i++) {
        if (
          pfmembers[i].memberType &&
          pfmembers[i].memberType.toString() === cFDataId.toString()
        ) {
          curPFMemberState = pfmembers[i];
          break;
        }
      }
    }
  }

  let CFPeoplePills = [];
  let CFPeoplePillsE = [];
  if (
    focused &&
    focused.card === "Circles" &&
    curPFMemberState &&
    Object.keys(curPFMemberState).length > 0
  ) {
    if (
      CcorfoSkill &&
      CcorfoSkill.length > 0 &&
      Object.keys(curPFMemberState.coreMembers).length > 0 &&
      curPFMemberState.coreMembers.Skills &&
      curPFMemberState.coreMembers.Skills.length > 0
    ) {
      for (let i = 0; i < CcorfoSkill.length; i++) {
        for (let j = 0; j < curPFMemberState.coreMembers.Skills.length; j++) {
          if (
            CcorfoSkill[i]._id === curPFMemberState.coreMembers.Skills[j].CardId
          ) {
            let targetSkill = skills.find((ss) => ss._id == CcorfoSkill[i]._id);
            if (targetSkill && targetSkill.owners.includes(curPFMemberState.coreMembers.Skills[j].peopleIds)) {
              CFPeoplePills.push({
                peopleId: curPFMemberState.coreMembers.Skills[j].peopleIds,
                Id: CcorfoSkill[i]._id,
                name: `${CcorfoSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                cardType: "Skills",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (
      CextfoSkill &&
      CextfoSkill.length > 0 &&
      Object.keys(curPFMemberState.extendedMembers).length > 0 &&
      curPFMemberState.extendedMembers.Skills &&
      curPFMemberState.extendedMembers.Skills.length > 0
    ) {
      for (let i = 0; i < CextfoSkill.length; i++) {
        for (
          let j = 0;
          j < curPFMemberState.extendedMembers.Skills.length;
          j++
        ) {
          if (
            CextfoSkill[i]._id ===
            curPFMemberState.extendedMembers.Skills[j].CardId
          ) {
            let targetSkill = skills.find((ss) => ss._id == CextfoSkill[i]._id);
            if (targetSkill && targetSkill.owners.includes(curPFMemberState.extendedMembers.Skills[j].peopleIds)) {
              CFPeoplePillsE.push({
                peopleId: curPFMemberState.extendedMembers.Skills[j].peopleIds,
                Id: CextfoSkill[i]._id,
                name: `${CextfoSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                cardType: "Skills",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (
      CcorfoRoles &&
      CcorfoRoles.length > 0 &&
      Object.keys(curPFMemberState.coreMembers).length > 0 &&
      curPFMemberState.coreMembers.Roles &&
      curPFMemberState.coreMembers.Roles.length > 0
    ) {
      for (let i = 0; i < CcorfoRoles.length; i++) {
        for (let j = 0; j < curPFMemberState.coreMembers.Roles.length; j++) {
          if (
            CcorfoRoles[i]._id === curPFMemberState.coreMembers.Roles[j].CardId
          ) {
            let targetRole = roles.find((rr) => rr._id == CcorfoRoles[i]._id);
            if (targetRole.owners.includes(curPFMemberState.coreMembers.Roles[j].peopleIds)) {
              CFPeoplePills.push({
                peopleId: curPFMemberState.coreMembers.Roles[j].peopleIds,
                Id: CcorfoRoles[i]._id,
                name: `${CcorfoRoles[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                cardType: "Roles",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (
      CextfoRoles &&
      CextfoRoles.length > 0 &&
      Object.keys(curPFMemberState.extendedMembers).length > 0 &&
      curPFMemberState.extendedMembers.Roles &&
      curPFMemberState.extendedMembers.Roles.length > 0
    ) {
      for (let i = 0; i < CextfoRoles.length; i++) {
        for (
          let j = 0;
          j < curPFMemberState.extendedMembers.Roles.length;
          j++
        ) {
          if (
            CextfoRoles[i]._id &&
            CextfoRoles[i]._id ===
            curPFMemberState.extendedMembers.Roles[j].CardId
          ) {
            let targetRole = roles.find((rr) => rr._id == CextfoRoles[i]._id);
            if (targetRole.owners.includes(curPFMemberState.extendedMembers.Roles[j].peopleIds)) {
              CFPeoplePillsE.push({
                peopleId: curPFMemberState.extendedMembers.Roles[j].peopleIds,
                Id: CextfoRoles[i]._id,
                name: `${CextfoRoles[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                cardType: "Roles",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (CcorfoDomains && CcorfoDomains.length > 0) {
      for (let i = 0; i < CcorfoDomains.length; i++) {
        if (CcorfoDomains[i].owners && CcorfoDomains[i].owners.owner) {
          let targetDomain = domains.find((sd) => sd._id == CcorfoDomains[i]._id);
          if (targetDomain.owners.owner == CcorfoDomains[i].owners.owner) {
            CFPeoplePills.push({
              peopleId: CcorfoDomains[i].owners.owner,
              Id: CcorfoDomains[i]._id,
              name: `${CcorfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
              cardType: "Domains",
              standIn: false,
            });
          }
        }
        if (CcorfoDomains[i].standIn) {
          CFPeoplePills.push({
            peopleId: CcorfoDomains[i].standIn,
            Id: CcorfoDomains[i]._id,
            name: `${CcorfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (CextfoDomains && CextfoDomains.length > 0) {
      for (let i = 0; i < CextfoDomains.length; i++) {
        if (CextfoDomains[i].owners && CextfoDomains[i].owners.owner) {
          let targetDomain = domains.find((sd) => sd._id == CextfoDomains[i]._id);
          if (targetDomain.owners.owner == CextfoDomains[i].owners.owner) {
            CFPeoplePillsE.push({
              peopleId: CextfoDomains[i].owners.owner,
              Id: CextfoDomains[i]._id,
              name: `${CextfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
              cardType: "Domains",
              standIn: false,
            });
          }
        }
        if (CextfoDomains[i].standIn) {
          CFPeoplePillsE.push({
            peopleId: CextfoDomains[i].standIn,
            Id: CextfoDomains[i]._id,
            name: `${CextfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (CcorfoLinks && CcorfoLinks.length > 0) {
      for (let i = 0; i < CcorfoLinks.length; i++) {
        if (CcorfoLinks[i].owner) {
          let targetLink = links.find((sl) => sl._id == CcorfoLinks[i]._id);
          if (targetLink.owner == CcorfoLinks[i].owner) {
            CFPeoplePills.push({
              peopleId: CcorfoLinks[i].owner,
              Id: CcorfoLinks[i]._id,
              name: `${CcorfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
              cardType: "Links",
              standIn: false,
            });
          }
        }
        if (CcorfoLinks[i].standIn) {
          CFPeoplePills.push({
            peopleId: CcorfoLinks[i].standIn,
            Id: CcorfoLinks[i]._id,
            name: `${CcorfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }

    if (CextfoLinks && CextfoLinks.length > 0) {
      for (let i = 0; i < CextfoLinks.length; i++) {
        if (CextfoLinks[i].owner) {
          let targetLink = links.find((sl) => sl._id == CextfoLinks[i]._id);
          if (targetLink.owner == CextfoLinks[i].owner) {
            CFPeoplePillsE.push({
              peopleId: CextfoLinks[i].owner,
              Id: CextfoLinks[i]._id,
              name: `${CextfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
              cardType: "Links",
              standIn: false,
            });
          }
        }
        if (CextfoLinks[i].standIn) {
          CFPeoplePillsE.push({
            peopleId: CextfoLinks[i].standIn,
            Id: CextfoLinks[i]._id,
            name: `${CextfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }
  }

  // console.log(13820, "CcorfoPeople", CcorfoPeople);
  let CcorfoPeoples = [...CcorfoPeople];
  let CExtfoPeoples = [...CextfoPeople];
  let filteredCFPDatas = [...filteredCFPData];

  if (focused && focused.card === "Circles") {
    if (CFPeoplePills && CFPeoplePills.length > 0) {
      let pillsData = [];
      for (let i = 0; i < CFPeoplePills.length; i++) {
        pillsData = [...new Set([...pillsData, CFPeoplePills[i].peopleId])];
      }
      if (pillsData && pillsData.length > 0) {
        for (let i = 0; i < pillsData.length; i++) {
          filteredCFPDatas = filteredCFPDatas.filter((value) => {
            if (value._id.toString() === pillsData[i].toString()) {
              CcorfoPeoples = [...new Set([...CcorfoPeoples, value])];
              return false;
            } else {
              return true;
            }
          });
        }
      }
    }

    if (CFPeoplePillsE && CFPeoplePillsE.length > 0) {
      let pillsDatas = [];
      for (let i = 0; i < CFPeoplePillsE.length; i++) {
        pillsDatas = [...new Set([...pillsDatas, CFPeoplePillsE[i].peopleId])];
      }
      if (pillsDatas && pillsDatas.length > 0) {
        for (let i = 0; i < pillsDatas.length; i++) {
          filteredCFPDatas = filteredCFPDatas.filter((value) => {
            if (value._id.toString() === pillsDatas[i].toString()) {
              CExtfoPeoples = [...new Set([...CExtfoPeoples, value])];
              return false;
            } else {
              return true;
            }
          });
          if (CcorfoPeoples && CcorfoPeoples.length > 0) {
            for (let j = 0; j < CcorfoPeoples.length; j++) {
              if (CcorfoPeoples[j]._id === pillsDatas[i].toString()) {
                CExtfoPeoples = [
                  ...new Set([...CExtfoPeoples, CcorfoPeoples[j]]),
                ];
                break;
              }
            }
          }
        }
      }
    }
  } else if (
    focused &&
    focused.card === "Circles" &&
    CFPeoplePills.length === 0 &&
    CFPeoplePillsE.length === 0
  ) {
    filteredCFPDatas = [...filteredCFPData];
  }

  if (focused && focused.card === "Circles") {
    // console.log(CcorfoPeoples, CextfoPeople);
    let curCFDatas = [...CcorfoPeoples, ...CextfoPeople];
    if (curCFDatas && curCFDatas.length > 0) {
      for (let i = 0; i < curCFDatas.length; i++) {
        filteredCFPDatas = filteredCFPDatas.filter((value) => {
          return value._id.toString() !== curCFDatas[i]._id.toString();
        });
      }
    }
  }

  const cardOpensCf = (datas) => {
    if (datas.cardType === "People") {
      peopleSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openPeople();
        }
      });
    } else if (datas.cardType === "Skills") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.cardType === "Roles") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openRole();
        }
      });
    } else if (datas.cardType === "Links") {
      linksSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openLink();
        }
      });
    } else if (datas.cardType === "Domains") {
      domainsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openDomain();
        }
      });
    }
  };

  const cFPeopleCard = (e) => {
    if (Object.keys(e).length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        if (peopleSorted[i]._id.toString() === e._id.toString()) {
          setPepole(e, i);
          break;
        }
      }
    }
  };

  const isAddedAsPersonCF = (Id, type) => {
    if (type === "Core") {
      if (
        curCoreMembers &&
        curCoreMembers.People &&
        curCoreMembers.People.length > 0
      ) {
        let inc = curCoreMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    } else if (type === "Extended") {
      if (
        curExtendedMembers &&
        curExtendedMembers.People &&
        curExtendedMembers.People.length > 0
      ) {
        let inc = curExtendedMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    }
    return false;
  };

  const cfIsDuplicatePerson = (data) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      CcorfoPeoples &&
      CcorfoPeoples.length > 0
    ) {
      let flag = true;
      for (let i = 0; i < CcorfoPeoples.length; i++) {
        if (CcorfoPeoples[i]._id === data._id) {
          flag = false;
          break;
        }
      }
      return flag;
    }
  };

  // Check if all owners was clicked/ marked (used in circle and project focus modes both)
  const isAllOwnersMarked = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      // console.log('first')
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          if (pfmembers[i].allOwnersFlag.core.includes(data)) {
            return true;
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          if (pfmembers[i].allOwnersFlag.core.includes(data)) {
            return true;
          }
        }
      }
    } else if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      // console.log('second')
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          if (pfmembers[i].allOwnersFlag.core.includes(data)) {
            return true;
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          if (pfmembers[i].allOwnersFlag.extended.includes(data)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  // Check if any circle already has a project associated to it's members
  const IsAlreadyAssociated = (data) => {
    // console.log(data, members);
    let associated = false;
    members.map((member) => {
      if (
        member.coreMembers &&
        member.coreMembers.Projects &&
        member.coreMembers.Projects.includes(data._id)
      ) {
        associated = true;
      }
    });
    return associated;
  };

  /* ========================================= Circle focus mode =================================================== */
  const handleDragEndCF = (result) => {
    if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    }

    if (
      result.source.droppableId &&
      result.destination.droppableId &&
      result.source.droppableId === result.destination.droppableId
    ) {
      unfreezeWindow(false);
      return false;
    }

    // console.log(result);
    // console.log(`Dropped in ${result.destination.droppableId}: `);

    /* result.draggableId // - ID of the dragged item
    result.source.index // - source index of the dragged item
    result.destination.index // - destination index of the dragged item */
    // setTemporarilyMovedItem({cardType: 'Circle', card: circleDropped });
  };

  // { console.log(CcorfoPeoples) }

  const FCPeople = () => <FCPeopleList tagFilteredMegedData={tagFilteredMegedData} GetAliasesName={GetAliasesName} teams={teams} CcorfoPeoples={CcorfoPeoples} CExtfoPeoples={CExtfoPeoples} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} pnoteRef={pnoteRef} handleSave={handleSave} noteSaveData={noteSaveData} cFPonDrop={cFPonDrop} cFPonDragOver={cFPonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} focused={focused} circlesSorted={circlesSorted} skillsSorted={skillsSorted} rolesSorted={rolesSorted} CFPeoplePills={CFPeoplePills} isAddedAsPersonCF={isAddedAsPersonCF} cFPonDrag={cFPonDrag} ScrollIntoView={ScrollIntoView} setPepole={setPepole} cardOpensCf={cardOpensCf} toggleTagCards={toggleTagCards} PfToCAndP={PfToCAndP} cFpeopleSub={cFpeopleSub} CFPeoplePillsE={CFPeoplePillsE} cfIsDuplicatePerson={cfIsDuplicatePerson} CextfoPeople={CextfoPeople} filteredCFPDatas={filteredCFPDatas} OverlayTrigger={OverlayTrigger} popover={popover} cFPeopleHandel={cFPeopleHandel} lFromState={lFromState} peopleSubmit={peopleSubmit} pref={pref} fName={fName} handleNameChange={handleNameChange} pfNameHandel={pfNameHandel} fcPref={fcPref} cfPeopleSearch={cfPeopleSearch} setCfPeopleSearch={setCfPeopleSearch} cuPeopleId={cuPeopleId} setFName={setFName} setPepoles={setPepoles} peopleFrom={peopleFrom} />;

  const FCSkills = () => <FCSkillsList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} CcorfoSkill={CcorfoSkill} CextfoSkill={CextfoSkill} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} cFSonDrop={cFSonDrop} cFSonDragOver={cFSonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} circlesSorted={circlesSorted} focused={focused} IsOwnerExternal={IsOwnerExternal} isPFCircleAddS={isPFCircleAddS} isAllOwnersMarked={isAllOwnersMarked} cFSonDrag={cFSonDrag} setSkill={setSkill} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} cFskillSub={cFskillSub} OpenCFPeople={OpenCFPeople} temporarilyMovedItem={temporarilyMovedItem} fcSref={fcSref} cfSkillSearch={cfSkillSearch} setCfSkillSearch={setCfSkillSearch} filteredCFSData={filteredCFSData} cfcircleOrProjectModes={cfcircleOrProjectModes} lFromState={lFromState} skillSubmit={skillSubmit} sref={sref} handleSkillChange={handleSkillChange} setSkills={setSkills} setSkillName={setSkillName} skillsFrom={skillsFrom} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} skill={skill} />;

  const FCRoles = () => <FCRolesList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} CcorfoRoles={CcorfoRoles} CextfoRoles={CextfoRoles} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} cFRonDrop={cFRonDrop} cFRonDragOver={cFRonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} circlesSorted={circlesSorted} focused={focused} IsOwnerExternal={IsOwnerExternal} isPFCircleAddR={isPFCircleAddR} isAllOwnersMarked={isAllOwnersMarked} cFRonDrag={cFRonDrag} setRole={setRole} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} cFroleSub={cFroleSub} rolesFrom={rolesFrom} OpenCFPeople={OpenCFPeople} temporarilyMovedItem={temporarilyMovedItem} fcRref={fcRref} cfRolesSearch={cfRolesSearch} setCfRolesSearch={setCfRolesSearch} lFromState={lFromState} filteredCFRData={filteredCFRData} cfcircleOrProjectModes={cfcircleOrProjectModes} rref={rref} />;

  const FCDomains = () => <FCDomainsList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} CcorfoDomains={CcorfoDomains} CextfoDomains={CextfoDomains} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} cFDonDrop={cFDonDrop} cFDonDragOver={cFDonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} IsOwnerExternal={IsOwnerExternal} isPFCircleAddD={isPFCircleAddD} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} lFromState={lFromState} OverlayTrigger={OverlayTrigger} popover={popover} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} dWhoIsOwnerType={dWhoIsOwnerType} cfRAnfdSDomain={cfRAnfdSDomain} curCoreMembers={curCoreMembers} setDomain={setDomain} filteredCFDData={filteredCFDData} domainSubmit={domainSubmit} domain={domain} handleDomainChange={handleDomainChange} setDomains={setDomains} setDomainName={setDomainName} domainsFrom={domainsFrom} fcDref={fcDref} cfDomainsSearch={cfDomainsSearch} setCfDomainsSearch={setCfDomainsSearch} cFPeopleHandel={cFPeopleHandel} cardOpensCfDomain={cardOpensCfDomain} cFdomainSub={cFdomainSub} cfRAnfdSExDomain={cfRAnfdSExDomain} curExtendedMembers={curExtendedMembers} list={list} dref={dref} cFDonDrag={cFDonDrag} />;

  const FCLinks = () => <FCLinksList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} CcorfoLinks={CcorfoLinks} CextfoLinks={CextfoLinks} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} cFLonDrop={cFLonDrop} cFLonDragOver={cFLonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} isPFCircleAddL={isPFCircleAddL} cFLonDrag={cFLonDrag} setLink={setLink} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} cFlinkSub={cFlinkSub} fcLref={fcLref} cfLinksSearch={cfLinksSearch} setCfLinksSearch={setCfLinksSearch} filteredCFLData={filteredCFLData} OverlayTrigger={OverlayTrigger} list={list} popover={popover} cFPeopleHandel={cFPeopleHandel} lFromState={lFromState} linkSubmit={linkSubmit} lref={lref} handleLinkChange={handleLinkChange} setLinks={setLinks} setLinkName={setLinkName} linksFrom={linksFrom} link={link} />;

  const FCCircles = () => <FCCirclesList handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} circlesSorted={circlesSorted} getSortIcon={getSortIcon} isInfos={isInfos} setCircles={setCircles} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} CFCircleSorted={CFCircleSorted} isTag={isTag} isEXT={isEXT} isNO={isNO} focused={focused} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} ScrollIntoView={ScrollIntoView} setCircle={setCircle} focusListRef={focusListRef} setFocusedRef={setFocusedRef} toggleTagCards={toggleTagCards} handelFocused={handelFocused} lFromState={lFromState} circleSubmit={circleSubmit} cref={cref} circle={circle} handleCircleChange={handleCircleChange} setCircleName={setCircleName} circlesFrom={circlesFrom} />;

  const FCProjects = () => <FCProjectsList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} CcorfoProjects={CcorfoProjects} getSortIcon={getSortIcon} isInfos={isInfos} setProjects={setProjects} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} cFPronDrop={cFPronDrop} cFPronDragOver={cFPronDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} cFPronDrag={cFPronDrag} ScrollIntoView={ScrollIntoView} setProject={setProject} toggleTagCards={toggleTagCards} PfToCAndP={PfToCAndP} cFprojectSub={cFprojectSub} fcPrref={fcPrref} cfProjectsSearch={cfProjectsSearch} setCfProjectsSearch={setCfProjectsSearch} filteredCFPrData={filteredCFPrData} IsAlreadyAssociated={IsAlreadyAssociated} cFPeopleHandel={cFPeopleHandel} cFPeopleIdHandel={cFPeopleIdHandel} lFromState={lFromState} projectSubmit={projectSubmit} prref={prref} project={project} handleProjectChange={handleProjectChange} setProjectName={setProjectName} projectsFrom={projectsFrom} />;

  //'''''''''''''''''''''''''''''''''''''''' Xls And Jpg Downlod ''''''''''''''''''''''''''''''''''''''''''''''''
  let circleFXls = [];
  let xlsCPeople = [...CcorfoPeople, ...CextfoPeople];
  let xlsCSkill = [...CcorfoSkill, ...CextfoSkill];
  let xlsCRole = [...CcorfoRoles, ...CextfoRoles];
  let xlsCDomain = [...CcorfoDomains, ...CextfoDomains];
  let xlsCLink = [...CcorfoLinks, ...CextfoLinks];

  if (focused && focused.card.length > 0 && focused.card === "Circles") {
    if (
      xlsCPeople.length > 0 ||
      xlsCSkill.length > 0 ||
      xlsCRole.length > 0 ||
      xlsCDomain.length > 0 ||
      xlsCLink.length > 0 ||
      circlesSorted[focused.index] ||
      CcorfoProjects.length > 0
    ) {
      let arr = [
        xlsCPeople.length,
        xlsCSkill.length,
        xlsCRole.length,
        xlsCDomain.length,
        xlsCLink.length,
        1,
        CcorfoProjects.length,
      ];

      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });

        let xlsCircle = circlesSorted.filter((c) => c._id === focused.id); // [circlesSorted[focused.index]];
        if (xlsCircle) {
          for (let i = 0; i < max; i++) {
            let obj = {
              People:
                xlsCPeople[i] && xlsCPeople[i]._id
                  ? `${xlsCPeople[i].fname} ${xlsCPeople[i].lname}`
                  : "",
              Skill:
                xlsCSkill[i] && xlsCSkill[i]._id
                  ? `${xlsCSkill[i].skillName}`
                  : "",
              Role:
                xlsCRole[i] && xlsCRole[i]._id ? `${xlsCRole[i].roleName}` : "",
              Domain:
                xlsCDomain[i] && xlsCDomain[i]._id
                  ? `${xlsCDomain[i].domainName}`
                  : "",
              Link:
                xlsCLink[i] && xlsCLink[i]._id ? `${xlsCLink[i].linkName}` : "",
              Circle:
                xlsCircle[i] && xlsCircle[i]._id
                  ? `${xlsCircle[i].circleName}`
                  : "",
              Project:
                CcorfoProjects[i] && CcorfoProjects[i]._id
                  ? `${CcorfoProjects[i].projectName}`
                  : "",
            };
            console.log(obj);
            circleFXls.push(obj);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (focused && focused.card.length > 0 && focused.card === "Circles") {
      console.log('circle focus');
      setXls(circleFXls);
    }
  }, [focused]);
  /* Circle Focus - End */

  //========================================= Project focus Action ===================================================
  const pFpeopleSub = (data, type) => {
    // console.log('pFpeopleSub', data, type === "core");
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.People
    ) {
      popoverIds.coreMembers.People = popoverIds.coreMembers.People.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.People
    ) {
      popoverIds.extendedMembers.People =
        popoverIds.extendedMembers.People.filter((e) => {
          return e !== data._id;
        });
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const pFskillSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Skills
    ) {
      popoverIds.coreMembers.Skills = popoverIds.coreMembers.Skills.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Skills
    ) {
      popoverIds.extendedMembers.Skills =
        popoverIds.extendedMembers.Skills.filter((e) => {
          return e !== data._id;
        });
    }

    let updated_pfmemberIds = { ...pfmemberIds };

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.coreMembers.Skills &&
      updated_pfmemberIds.coreMembers.Skills.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberIds.coreMembers,
          Skills: updated_pfmemberIds.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          core: updated_pfmemberIds.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.extendedMembers.Skills &&
      updated_pfmemberIds.extendedMembers.Skills.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberIds.extendedMembers,
          Skills: updated_pfmemberIds.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          extended: updated_pfmemberIds.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }

    if (updated_pfmemberIds) {
      updated_pfmemberIds.adminId = myInfo.userId;
      updated_pfmemberIds.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = updated_pfmemberIds.teamId;
      dispatch(createAndUpdateMember(updated_pfmemberIds)).then(() => {
        dispatch(getPFMember(obj));
        unfreezeWindow();
      });
    }
  };

  const pFroleSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Roles
    ) {
      popoverIds.coreMembers.Roles = popoverIds.coreMembers.Roles.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Roles
    ) {
      popoverIds.extendedMembers.Roles =
        popoverIds.extendedMembers.Roles.filter((e) => {
          return e !== data._id;
        });
    }

    let updated_pfmemberIds = { ...pfmemberIds };

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.coreMembers.Roles &&
      updated_pfmemberIds.coreMembers.Roles.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberIds.coreMembers,
          Skills: updated_pfmemberIds.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          core: updated_pfmemberIds.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.extendedMembers.Roles &&
      updated_pfmemberIds.extendedMembers.Roles.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberIds.extendedMembers,
          Skills: updated_pfmemberIds.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          extended: updated_pfmemberIds.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }

    if (updated_pfmemberIds) {
      updated_pfmemberIds.adminId = myInfo.userId;
      updated_pfmemberIds.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = updated_pfmemberIds.teamId;
      dispatch(createAndUpdateMember(updated_pfmemberIds)).then(() => {
        dispatch(getPFMember(obj));
        unfreezeWindow();
      });
    }
  };

  const pFdomainSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Domains
    ) {
      popoverIds.coreMembers.Domains = popoverIds.coreMembers.Domains.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Domains
    ) {
      popoverIds.extendedMembers.Domains =
        popoverIds.extendedMembers.Domains.filter((e) => {
          return e !== data._id;
        });
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const pFlinkSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Links
    ) {
      popoverIds.coreMembers.Links = popoverIds.coreMembers.Links.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Links
    ) {
      popoverIds.extendedMembers.Links =
        popoverIds.extendedMembers.Links.filter((e) => {
          return e !== data._id;
        });
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const pFcircleSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Circles
    ) {
      popoverIds.coreMembers.Circles = popoverIds.coreMembers.Circles.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      popoverIds.actionType = "remove";
      popoverIds.cardId = data._id;

      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  //========================================= Project focus Drag and Drop ===================================================

  //People
  const pFPonDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "People");
  };

  const pFPonDragOver = (e) => {
    e.preventDefault();
  };

  const pFPonDrop = (type) => {
    let pFPonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFPonDragType = localStorage.getItem("dragType");
    if (type === "pFpCore") {
      if (pFPonDragType === "pFpExtend") {
        cFPeopleIdHandel("eToc");
      } else if (pFPonDragType === "pFpDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFpExtend") {
      if (pFPonDragType === "pFpCore") {
        cFPeopleIdHandel("cToe");
      } else if (pFPonDragType === "pFpDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "pFpDefult") {
      if (pFPonDragType === "pFpExtend") {
        cFpeopleSub(pFPonDragData, "extend");
      } else if (pFPonDragType === "pFpCore") {
        cFpeopleSub(pFPonDragData, "core");
      }
    }
  };

  //Skills
  const pFSonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Skills");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFSonDragOver = (e) => {
    e.preventDefault();
  };

  const pFSonDrop = (type) => {
    // console.log('pFSonDrop', type);
    let pFSonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFSonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "pFsCore") {
      if (pFSonDragType === "pFsExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (pFSonDragType === "pFsDefult") {
        // cFPeopleIdHandel("Core");
        cfcircleOrProjectModes(pFSonDragData, "Skills", "Core");
      }
    } else if (type === "pFsExtend") {
      if (pFSonDragType === "pFsCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (pFSonDragType === "pFsDefult") {
        // cFPeopleIdHandel("Extended");
        cfcircleOrProjectModes(pFSonDragData, "Skills", "Extended");
      }
    } else if (type === "pFsDefult") {
      if (pFSonDragType === "pFsExtend") {
        pFskillSub(pFSonDragData, "extend");
      } else if (pFSonDragType === "pFsCore") {
        pFskillSub(pFSonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Roles
  const pFRonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Roles");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFRonDragOver = (e) => {
    e.preventDefault();
  };

  const pFRonDrop = (type) => {
    let pFRonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFRonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "pFrCore") {
      if (pFRonDragType === "pFrExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (pFRonDragType === "pFrDefult") {
        // cFPeopleIdHandel("Core");
        cfcircleOrProjectModes(pFRonDragData, "Roles", "Core");
      }
    } else if (type === "pFrExtend") {
      if (pFRonDragType === "pFrCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (pFRonDragType === "pFrDefult") {
        // cFPeopleIdHandel("Extended");
        cfcircleOrProjectModes(pFRonDragData, "Roles", "Extended");
      }
    } else if (type === "pFrDefult") {
      if (pFRonDragType === "pFrExtend") {
        pFroleSub(pFRonDragData, "extend");
      } else if (pFRonDragType === "pFrCore") {
        pFroleSub(pFRonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Domains
  const pFDonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Domains");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFDonDragOver = (e) => {
    e.preventDefault();
  };

  const pFDonDrop = (type) => {
    let pFDonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFDonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "pFdCore") {
      if (pFDonDragType === "pFdExtend") {
        cFPeopleIdHandel("eToc");
      } else if (pFDonDragType === "pFdDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFdExtend") {
      if (pFDonDragType === "pFdCore") {
        cFPeopleIdHandel("cToe");
      } else if (pFDonDragType === "pFdDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "pFdDefult") {
      if (pFDonDragType === "pFdExtend") {
        pFdomainSub(pFDonDragData, "extend");
      } else if (pFDonDragType === "pFdCore") {
        pFdomainSub(pFDonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Links
  const pFLonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Links");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFLonDragOver = (e) => {
    e.preventDefault();
  };

  const pFLonDrop = (type) => {
    let pFLonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFLonDragType = localStorage.getItem("dragType");
    if (type === "pFlCore") {
      if (pFLonDragType === "pFlExtend") {
        // console.log("drag");
        cFPeopleIdHandel("eToc");
      } else if (pFLonDragType === "pFlDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFlExtend") {
      if (pFLonDragType === "pFlCore") {
        cFPeopleIdHandel("cToe");
      } else if (pFLonDragType === "pFlDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "pFlDefult") {
      if (pFLonDragType === "pFlExtend") {
        cFlinkSub(pFLonDragData, "extend");
      } else if (pFLonDragType === "pFlCore") {
        cFlinkSub(pFLonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Circles
  const pFConDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Circles");
  };

  const pFConDragOver = (e) => {
    e.preventDefault();
  };

  const pFConDrop = (type) => {
    let pFConDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFConDragType = localStorage.getItem("dragType");
    if (type === "pFcCore") {
      if (pFConDragType === "pFcDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFcDefult") {
      if (pFConDragType === "pFcCore") {
        pFcircleSub(pFConDragData, "core");
      }
    }
  };

  // cFpExtend

  //=========================================Circle focus algorithm===================================================

  // Skills
  const [pfSkillsSearch, setPfSkillsSearch] = useState("");

  const fpSref = useRef(null);

  useEffect(() => {
    if (pfSkillsSearch.length > 0) {
      fpSref.current.focus();
    }
  }, [pfSkillsSearch]);

  var filteredPFSData = [];
  if (
    (pfSkillsSearch.length > 0 || allSearch.length > 0) &&
    PredeffoSkill.length > 0
  ) {
    filteredPFSData = PredeffoSkill.filter((value) => {
      const searchStr = pfSkillsSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPFSData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Skills");
    }
  }

  if (
    filteredPFSData.length === 0 &&
    pfSkillsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFSData = [...PredeffoSkill];
  }

  let nonFiltePFSData = [];
  if (
    pfSkillsSearch.length > 0 &&
    filteredPFSData.length !== PredeffoSkill.length
  ) {
    nonFiltePFSData = [...PredeffoSkill];
    for (let i = 0; i < filteredPFSData.length; i++) {
      nonFiltePFSData = nonFiltePFSData.filter((value, key) => {
        return value._id !== filteredPFSData[i]._id;
      });
    }
    if (filteredPFSData.length === 0) {
      nonFiltePFSData = [...PredeffoSkill];
    }
  }

  const isPFProjectsAddS = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Skills.length; j++) {
            if (pfmembers[i].coreMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Skills[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Skills.length; j++) {
            if (pfmembers[i].extendedMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Skills[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        }
      }
      return [];
    }
  };

  // Roles
  const [pfRolesSearch, setPfRolesSearch] = useState("");

  const fprRref = useRef(null);

  useEffect(() => {
    if (pfRolesSearch.length > 0) {
      fprRref.current.focus();
    }
  }, [pfRolesSearch]);

  var filteredPFRData = [];

  if (
    (pfRolesSearch.length > 0 || allSearch.length > 0) &&
    PredeffoRoles.length > 0
  ) {
    filteredPFRData = PredeffoRoles.filter((value) => {
      const searchStr = pfRolesSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPFRData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Roles");
    }
  }

  if (
    filteredPFRData.length === 0 &&
    pfRolesSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFRData = [...PredeffoRoles];
  }

  let nonFiltePFRData = [];
  if (
    pfRolesSearch.length > 0 &&
    filteredPFSData.length !== PredeffoRoles.length
  ) {
    nonFiltePFRData = [...PredeffoRoles];
    for (let i = 0; i < filteredPFRData.length; i++) {
      nonFiltePFRData = nonFiltePFRData.filter((value, key) => {
        return value._id !== filteredPFRData[i]._id;
      });
    }
    if (filteredPFRData.length === 0) {
      nonFiltePFRData = [...PredeffoRoles];
    }
  }

  const isPFProjectsAddR = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Roles.length; j++) {
            if (pfmembers[i].coreMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Roles[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Roles.length; j++) {
            if (pfmembers[i].extendedMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Roles[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        }
      }
      return [];
    }
  };

  // Domain
  const [pfDomainsSearch, setPfDomainsSearch] = useState("");

  const fprDref = useRef(null);

  useEffect(() => {
    if (pfDomainsSearch.length > 0) {
      fprDref.current.focus();
    }
  }, [pfDomainsSearch]);

  var filteredPFFDData = [];

  if (
    (pfDomainsSearch.length > 0 || allSearch.length > 0) &&
    PredeffoDomains.length > 0
  ) {
    filteredPFFDData = PredeffoDomains.filter((value) => {
      const searchStr = pfDomainsSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPFFDData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Domains");
    }
  }

  if (
    filteredPFFDData.length === 0 &&
    pfDomainsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFFDData = [...PredeffoDomains];
  }

  let nonFiltePFDData = [];
  if (
    pfDomainsSearch.length > 0 &&
    filteredPFFDData.length !== PredeffoDomains.length
  ) {
    nonFiltePFDData = [...PredeffoDomains];
    for (let i = 0; i < filteredPFFDData.length; i++) {
      nonFiltePFDData = nonFiltePFDData.filter((value, key) => {
        return value._id !== filteredPFFDData[i]._id;
      });
    }
    if (filteredPFFDData.length === 0) {
      nonFiltePFDData = [...PredeffoDomains];
    }
  }

  let prfRAnfdSDomain = [];
  if (focused && focused.card === "Projects") {
    if (PrcorfoSkill && PrcorfoSkill.length > 0) {
      for (let i = 0; i < PrcorfoSkill.length; i++) {
        if (PrcorfoSkill[i].domains && PrcorfoSkill[i].domains.length > 0) {
          for (let j = 0; j < PrcorfoSkill[i].domains.length; j++) {
            if (PrcorfoSkill[i].domains[j].domain) {
              prfRAnfdSDomain.push({
                cardId: PrcorfoSkill[i]._id,
                cardName: PrcorfoSkill[i].skillName,
                type: "Skill",
                domainId: PrcorfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (PrcorfoRoles && PrcorfoRoles.length > 0) {
      for (let i = 0; i < PrcorfoRoles.length; i++) {
        if (PrcorfoRoles[i].domains && PrcorfoRoles[i].domains.length > 0) {
          for (let j = 0; j < PrcorfoRoles[i].domains.length; j++) {
            if (PrcorfoRoles[i].domains[j].domain) {
              prfRAnfdSDomain.push({
                cardId: PrcorfoRoles[i]._id,
                cardName: PrcorfoRoles[i].roleName,
                type: "Role",
                domainId: PrcorfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    // if (prfRAnfdSDomain && prfRAnfdSDomain.length > 0) {
    //   if (filteredPFFDData && filteredPFFDData.length > 0) {
    //     let addNewDoamin = [];
    //     filteredPFFDData = filteredPFFDData.filter((value, key) => {
    //       let flag = true;
    //       for (let i = 0; i < prfRAnfdSDomain.length; i++) {
    //         if (prfRAnfdSDomain[i].domainId === value._id) {
    //           addNewDoamin.push(value);
    //           flag = false;
    //           break;
    //         }
    //       }
    //       if (flag) {
    //         return value;
    //       }
    //     });
    //     if (addNewDoamin && addNewDoamin.length > 0) {
    //       PrcorfoDomains = [...PrcorfoDomains, ...addNewDoamin];
    //     }
    //   }
    // }
  }

  let prfRAnfdSExDomain = [];
  if (focused && focused.card === "Projects") {
    if (PrextfoSkill && PrextfoSkill.length > 0) {
      for (let i = 0; i < PrextfoSkill.length; i++) {
        if (PrextfoSkill[i].domains && PrextfoSkill[i].domains.length > 0) {
          for (let j = 0; j < PrextfoSkill[i].domains.length; j++) {
            if (PrextfoSkill[i].domains[j].domain) {
              prfRAnfdSExDomain.push({
                cardId: PrextfoSkill[i]._id,
                cardName: PrextfoSkill[i].skillName,
                type: "Skill",
                domainId: PrextfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (PrextfoRoles && PrextfoRoles.length > 0) {
      for (let i = 0; i < PrextfoRoles.length; i++) {
        if (PrextfoRoles[i].domains && PrextfoRoles[i].domains.length > 0) {
          for (let j = 0; j < PrextfoRoles[i].domains.length; j++) {
            if (PrextfoRoles[i].domains[j].domain) {
              prfRAnfdSExDomain.push({
                cardId: PrextfoRoles[i]._id,
                cardName: PrextfoRoles[i].roleName,
                type: "Role",
                domainId: PrextfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    // if (prfRAnfdSExDomain && prfRAnfdSExDomain.length > 0) {
    //   if (filteredPFFDData && filteredPFFDData.length > 0) {
    //     let addNewDoamin = [];
    //     filteredPFFDData = filteredPFFDData.filter((value, key) => {
    //       let flag = true;
    //       for (let i = 0; i < prfRAnfdSExDomain.length; i++) {
    //         if (prfRAnfdSExDomain[i].domainId === value._id) {
    //           addNewDoamin.push(value);
    //           flag = false;
    //           break;
    //         }
    //       }
    //       if (flag) {
    //         return value;
    //       }
    //     });
    //     if (addNewDoamin && addNewDoamin.length > 0) {
    //       PrextfoDomains = [...PrextfoDomains, ...addNewDoamin];
    //     }
    //   }
    // }
  }

  const isPFProjectsAddD = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Domains.length; j++) {
            if (pfmembers[i].coreMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (
            let j = 0;
            j < pfmembers[i].extendedMembers.Domains.length;
            j++
          ) {
            if (pfmembers[i].extendedMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  // Links
  const [pfLinksSearch, setPfLinksSearch] = useState("");

  const fsprLref = useRef(null);

  useEffect(() => {
    if (pfLinksSearch.length > 0) {
      fsprLref.current.focus();
    }
  }, [pfLinksSearch]);

  var filteredPFLData = [];

  if (
    (pfLinksSearch.length > 0 || allSearch.length > 0) &&
    PredeffoLinks.length > 0
  ) {
    filteredPFLData = PredeffoLinks.filter((value) => {
      const searchStr = pfLinksSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPFLData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Links");
    }
  }

  if (
    filteredPFLData.length === 0 &&
    pfLinksSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFLData = [...PredeffoLinks];
  }

  let nonFiltePFLData = [];
  if (
    pfLinksSearch.length > 0 &&
    filteredPFLData.length !== PredeffoLinks.length
  ) {
    nonFiltePFLData = [...PredeffoLinks];
    for (let i = 0; i < filteredPFLData.length; i++) {
      nonFiltePFLData = nonFiltePFLData.filter((value, key) => {
        return value._id !== filteredPFLData[i]._id;
      });
    }
    if (filteredPFLData.length === 0) {
      nonFiltePFLData = [...PredeffoLinks];
    }
  }

  const isPFProjectsAddL = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Links.length; j++) {
            if (pfmembers[i].coreMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (let j = 0; j < pfmembers[i].extendedMembers.Links.length; j++) {
            if (pfmembers[i].extendedMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  // Circles
  const [pfCirclesSearch, setPfCirclesSearch] = useState("");

  const fprCref = useRef(null);

  useEffect(() => {
    if (pfCirclesSearch.length > 0) {
      fprCref.current.focus();
    }
  }, [pfCirclesSearch]);

  var filteredPFCData = [];

  if (
    (pfCirclesSearch.length > 0 || allSearch.length > 0) &&
    PredeffoCircles.length > 0
  ) {
    filteredPFCData = PredeffoCircles.filter((value) => {
      const searchStr = pfCirclesSearch.toLowerCase() || allSearch.toLowerCase();
      return isMatchFound(searchStr, value);
    });
    if (allSearch && allSearch.length > 0 && filteredPFCData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Circles");
    }
  }

  if (
    filteredPFCData.length === 0 &&
    pfCirclesSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFCData = [...PredeffoCircles];
  }

  let nonFiltePrFCData = [];
  if (
    pfCirclesSearch.length > 0 &&
    filteredPFCData.length !== PredeffoCircles.length
  ) {
    nonFiltePrFCData = [...PredeffoCircles];
    for (let i = 0; i < filteredPFCData.length; i++) {
      nonFiltePrFCData = nonFiltePrFCData.filter((value, key) => {
        return value._id !== filteredPFCData[i]._id;
      });
    }
    if (filteredPFCData.length === 0) {
      nonFiltePrFCData = [...PredeffoCircles];
    }
  }

  // Projects
  let PrFCircleSorted = [...projectsSorted];

  // People
  const [pfPeopleSearch, setPfPeopleSearch] = useState("");

  const fpPref = useRef(null);

  useEffect(() => {
    if (pfPeopleSearch.length > 0) {
      fpPref.current.focus();
    }
  }, [pfPeopleSearch]);

  var filteredPFPData = [];

  if (
    (pfPeopleSearch.length > 0 || allSearch.length > 0) &&
    PredeffoPeople.length > 0
  ) {
    filteredPFPData = PredeffoPeople.filter((value) => {
      const searchStr = pfPeopleSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.fname} ${value.lname}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFPData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("People");
    }
  }

  if (
    filteredPFPData.length === 0 &&
    pfPeopleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFPData = [...PredeffoPeople];
  }

  let nonFiltePFPData = [];
  if (
    pfPeopleSearch.length > 0 &&
    filteredPFPData.length !== PredeffoPeople.length
  ) {
    nonFiltePFPData = [...PredeffoPeople];
    for (let i = 0; i < filteredPFPData.length; i++) {
      nonFiltePFPData = nonFiltePFPData.filter((value, key) => {
        return value._id !== filteredPFPData[i]._id;
      });
    }
    if (filteredPFPData.length === 0) {
      nonFiltePFPData = [...PredeffoPeople];
    }
  }

  let curPrFMemberState = {};
  if (
    focused &&
    focused.card === "Projects" &&
    projectsSorted &&
    projectsSorted.length > 0
  ) {
    let cFDataId =
      projectsSorted[focused.index] && focused.id ? focused.id : null;
    if (cFDataId && pfmembers && pfmembers.length > 0) {
      for (let i = 0; i < pfmembers.length; i++) {
        if (
          pfmembers[i].memberType &&
          pfmembers[i].memberType.toString() === cFDataId.toString()
        ) {
          curPrFMemberState = pfmembers[i];
          break;
        }
      }
    }
  }

  let PrFPeoplePills = [];
  let PrFPeoplePillsE = [];
  if (focused && focused.card === "Projects" && curPrFMemberState) {
    if (
      curPrFMemberState.coreMembers &&
      curPrFMemberState.coreMembers.Skills &&
      curPrFMemberState.coreMembers.Skills.length > 0
    ) {
      for (let i = 0; i < PrcorfoSkill.length; i++) {
        for (let j = 0; j < curPrFMemberState.coreMembers.Skills.length; j++) {
          if (
            PrcorfoSkill[i]._id.toString() ===
            curPrFMemberState.coreMembers.Skills[j].CardId.toString()
          ) {
            let targetSkill = skills.find((ss) => ss._id == PrcorfoSkill[i]._id);
            if (targetSkill && targetSkill.owners.includes(curPrFMemberState.coreMembers.Skills[j].peopleIds)) {
              PrFPeoplePills.push({
                peopleId: curPrFMemberState.coreMembers.Skills[j].peopleIds,
                Id: PrcorfoSkill[i]._id,
                name: `${PrcorfoSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                cardType: "Skills",
                standIn: false,
              });
            }
          }
        }
      }
    }
    if (
      curPrFMemberState.extendedMembers &&
      curPrFMemberState.extendedMembers.Skills &&
      curPrFMemberState.extendedMembers.Skills.length > 0
    ) {
      for (let i = 0; i < PrextfoSkill.length; i++) {
        for (
          let j = 0;
          j < curPrFMemberState.extendedMembers.Skills.length;
          j++
        ) {
          if (
            PrextfoSkill[i]._id.toString() ===
            curPrFMemberState.extendedMembers.Skills[j].CardId.toString()
          ) {
            let targetSkill = skills.find((ss) => ss._id == PrextfoSkill[i]._id);
            if (targetSkill && targetSkill.owners.includes(curPrFMemberState.extendedMembers.Skills[j].peopleIds)) {
              PrFPeoplePillsE.push({
                peopleId: curPrFMemberState.extendedMembers.Skills[j].peopleIds,
                Id: PrextfoSkill[i]._id,
                name: `${PrextfoSkill[i].skillName} (${GetAliasesName(teams, "Skills")})`,
                cardType: "Skills",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (
      curPrFMemberState.coreMembers &&
      curPrFMemberState.coreMembers.Roles &&
      curPrFMemberState.coreMembers.Roles.length > 0
    ) {
      for (let i = 0; i < PrcorfoRoles.length; i++) {
        for (let j = 0; j < curPrFMemberState.coreMembers.Roles.length; j++) {
          if (
            PrcorfoRoles[i]._id.toString() ===
            curPrFMemberState.coreMembers.Roles[j].CardId.toString()
          ) {
            let targetRole = roles.find((ss) => ss._id == PrcorfoRoles[i]._id);
            if (targetRole.owners.includes(curPrFMemberState.coreMembers.Roles[j].peopleIds)) {
              PrFPeoplePills.push({
                peopleId: curPrFMemberState.coreMembers.Roles[j].peopleIds,
                Id: PrcorfoRoles[i]._id,
                name: `${PrcorfoRoles[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                cardType: "Roles",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (
      curPrFMemberState.extendedMembers &&
      curPrFMemberState.extendedMembers.Roles &&
      curPrFMemberState.extendedMembers.Roles.length > 0
    ) {
      for (let i = 0; i < PrextfoRoles.length; i++) {
        for (
          let j = 0;
          j < curPrFMemberState.extendedMembers.Roles.length;
          j++
        ) {
          if (
            PrextfoRoles[i]._id.toString() ===
            curPrFMemberState.extendedMembers.Roles[j].CardId
          ) {
            let targetRole = roles.find((ss) => ss._id == PrextfoRoles[i]._id);
            if (targetRole.owners.includes(curPrFMemberState.extendedMembers.Roles[j].peopleIds)) {
              PrFPeoplePillsE.push({
                peopleId: curPrFMemberState.extendedMembers.Roles[j].peopleIds,
                Id: PrextfoRoles[i]._id,
                name: `${PrextfoRoles[i].roleName} (${GetAliasesName(teams, "Roles")})`,
                cardType: "Roles",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (PrcorfoDomains && PrcorfoDomains.length > 0) {
      for (let i = 0; i < PrcorfoDomains.length; i++) {
        if (PrcorfoDomains[i].owners && PrcorfoDomains[i].owners.owner) {
          let targetDomain = domains.find((ss) => ss._id == PrcorfoDomains[i]._id);
          if (targetDomain.owners.owner == PrcorfoDomains[i].owners.owner) {
            PrFPeoplePills.push({
              peopleId: PrcorfoDomains[i].owners.owner,
              Id: PrcorfoDomains[i]._id,
              name: `${PrcorfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
              cardType: "Domains",
              standIn: false,
            });
          }
        }
        if (PrcorfoDomains[i].standIn) {
          PrFPeoplePills.push({
            peopleId: PrcorfoDomains[i].standIn,
            Id: PrcorfoDomains[i]._id,
            name: `${PrcorfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (PrextfoDomains && PrextfoDomains.length > 0) {
      for (let i = 0; i < PrextfoDomains.length; i++) {
        if (PrextfoDomains[i].owners && PrextfoDomains[i].owners.owner) {
          let targetDomain = domains.find((ss) => ss._id == PrextfoDomains[i]._id);
          if (targetDomain.owners.owner == PrextfoDomains[i].owners.owner) {
            PrFPeoplePillsE.push({
              peopleId: PrextfoDomains[i].owners.owner,
              Id: PrextfoDomains[i]._id,
              name: `${PrextfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
              cardType: "Domains",
              standIn: false,
            });
          }
        }
        if (PrextfoDomains[i].standIn) {
          PrFPeoplePillsE.push({
            peopleId: PrextfoDomains[i].standIn,
            Id: PrextfoDomains[i]._id,
            name: `${PrextfoDomains[i].domainName} (${GetAliasesName(teams, "Domains")})`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (PrcorfoLinks && PrcorfoLinks.length > 0) {
      for (let i = 0; i < PrcorfoLinks.length; i++) {
        if (PrcorfoLinks[i].owner) {
          let targetLink = links.find((ss) => ss._id == PrcorfoLinks[i]._id);
          if (targetLink.owner == PrcorfoLinks[i].owner) {
            PrFPeoplePills.push({
              peopleId: PrcorfoLinks[i].owner,
              Id: PrcorfoLinks[i]._id,
              name: `${PrcorfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
              cardType: "Links",
              standIn: false,
            });
          }
        }
        if (PrcorfoLinks[i].standIn) {
          PrFPeoplePills.push({
            peopleId: PrcorfoLinks[i].standIn,
            Id: PrcorfoLinks[i]._id,
            name: `${PrcorfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }

    if (
      PrextfoLinks &&
      PrextfoLinks.length > 0 &&
      curPrFMemberState.extendedMembers &&
      curPrFMemberState.extendedMembers.Links &&
      curPrFMemberState.extendedMembers.Links.length > 0
    ) {
      for (let i = 0; i < PrextfoLinks.length; i++) {
        for (
          let j = 0;
          j < curPrFMemberState.extendedMembers.Links.length;
          j++
        ) {
          if (
            PrextfoLinks[i]._id.toString() ===
            curPrFMemberState.extendedMembers.Links[j].CardId.toString()
          ) {
            let targetLink = links.find((ss) => ss._id == PrextfoLinks[i]._id);
            if (targetLink.owner == curPrFMemberState.extendedMembers.Links[j].peopleIds) {
              PrFPeoplePills.push({
                peopleId: curPrFMemberState.extendedMembers.Links[j].peopleIds,
                Id: PrextfoLinks[i]._id,
                name: `${PrextfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
                cardType: "Links",
                standIn: false,
              });
            }
          }
        }
      }
    }

    if (PrextfoLinks && PrextfoLinks.length > 0) {
      for (let i = 0; i < PrextfoLinks.length; i++) {
        if (PrextfoLinks[i].owner) {
          let targetLink = links.find((ss) => ss._id == PrextfoLinks[i]._id);
          if (targetLink.owner == PrextfoLinks[i].owner) {
            PrFPeoplePillsE.push({
              peopleId: PrextfoLinks[i].owner,
              Id: PrextfoLinks[i]._id,
              name: `${PrextfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
              cardType: "Links",
              standIn: false,
            });
          }
        }
        if (PrextfoLinks[i].standIn) {
          PrFPeoplePillsE.push({
            peopleId: PrextfoLinks[i].standIn,
            Id: PrextfoLinks[i]._id,
            name: `${PrextfoLinks[i].linkName} (${GetAliasesName(teams, "Links")})`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }
  }

  let PrcorfoPeoples = [...PrcorfoPeople];
  let PrExtfoPeoples = [...PrextfoPeople];
  let filteredPFPDatas = [...filteredPFPData];
  if (focused && focused.card === "Projects") {
    if (PrFPeoplePills && PrFPeoplePills.length > 0) {
      let pillsData = [];
      for (let i = 0; i < PrFPeoplePills.length; i++) {
        pillsData = [...new Set([...pillsData, PrFPeoplePills[i].peopleId])];
      }
      for (let i = 0; i < PrcorfoPeoples.length; i++) {
        pillsData = [...new Set([...pillsData, PrcorfoPeoples[i]._id])];
      }
      filteredPFPDatas = filteredPFPDatas.filter((value) => {
        let flag = true;
        for (let i = 0; i < pillsData.length; i++) {
          if (value._id.toString() === pillsData[i].toString()) {
            PrcorfoPeoples = [...new Set([...PrcorfoPeoples, value])];
            flag = true;
            break;
          }
        }
        if (flag) {
          return value;
        }
      });
    }
    if (PrFPeoplePillsE && PrFPeoplePillsE.length > 0) {
      let pillsDatas = [];
      for (let i = 0; i < PrFPeoplePillsE.length; i++) {
        pillsDatas = [...new Set([...pillsDatas, PrFPeoplePillsE[i].peopleId])];
      }
      if (pillsDatas && pillsDatas.length > 0) {
        for (let i = 0; i < pillsDatas.length; i++) {
          filteredPFPDatas = filteredPFPDatas.filter((value) => {
            if (value._id.toString() === pillsDatas[i].toString()) {
              PrExtfoPeoples = [...new Set([...PrExtfoPeoples, value])];
              return false;
            } else {
              return true;
            }
          });
          if (PrcorfoPeoples && PrcorfoPeoples.length > 0) {
            for (let j = 0; j < PrcorfoPeoples.length; j++) {
              if (PrcorfoPeoples[j]._id === pillsDatas[i].toString()) {
                PrExtfoPeoples = [
                  ...new Set([...PrExtfoPeoples, PrcorfoPeoples[j]]),
                ];
                break;
              }
            }
          }
        }
      }
    }
  }

  if (focused && focused.card === "Projects") {
    let curPrFDatas = [...PrcorfoPeoples, ...PrextfoPeople];
    if (curPrFDatas && curPrFDatas.length > 0) {
      for (let i = 0; i < curPrFDatas.length; i++) {
        filteredPFPDatas = filteredPFPDatas.filter((value) => {
          return value._id.toString() !== curPrFDatas[i]._id.toString();
        });
      }
    }
  }

  const cardOpensPf = (datas) => {
    if (datas.cardType === "People") {
      peopleSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openPeople();
        }
      });
    } else if (datas.cardType === "Skills") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.cardType === "Roles") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openRole();
        }
      });
    } else if (datas.cardType === "Links") {
      linksSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openLink();
        }
      });
    } else if (datas.cardType === "Domains") {
      domainsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openDomain();
        }
      });
    }
  };

  const isAddedAsPersonPrF = (Id, type) => {
    if (type === "Core") {
      if (
        curPCoreMembers &&
        curPCoreMembers.People &&
        curPCoreMembers.People.length > 0
      ) {
        let inc = curPCoreMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    } else if (type === "Extended") {
      if (
        curPExtendedMembers &&
        curPExtendedMembers.People &&
        curPExtendedMembers.People.length > 0
      ) {
        let inc = curPExtendedMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    }
    return false;
  };

  const prfIsDuplicatePerson = (data) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      PrcorfoPeoples &&
      PrcorfoPeoples.length > 0
    ) {
      let flag = true;
      for (let i = 0; i < PrcorfoPeoples.length; i++) {
        if (PrcorfoPeoples[i]._id === data._id) {
          flag = false;
          break;
        }
      }
      return flag;
    }
  };

  //================================Project focus mode==================================================================================

  const FPrPeople = () => <FPrPeopleList tagFilteredMegedData={tagFilteredMegedData} GetAliasesName={GetAliasesName} teams={teams} PrcorfoPeople={PrcorfoPeople} PrExtfoPeoples={PrExtfoPeoples} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} pnoteRef={pnoteRef} handleSave={handleSave} noteSaveData={noteSaveData} pFPonDrop={pFPonDrop} pFPonDragOver={pFPonDragOver} focused={focused} projectsSorted={projectsSorted} skillsSorted={skillsSorted} rolesSorted={rolesSorted} PrFPeoplePills={PrFPeoplePills} isAddedAsPersonPrF={isAddedAsPersonPrF} setPepole={setPepole} cardOpensPf={cardOpensPf} toggleTagCards={toggleTagCards} PfToCAndP={PfToCAndP} pFpeopleSub={pFpeopleSub} prfIsDuplicatePerson={prfIsDuplicatePerson} ScrollIntoView={ScrollIntoView} PrextfoPeople={PrextfoPeople} pfPeopleSearch={pfPeopleSearch} setPfPeopleSearch={setPfPeopleSearch} filteredPFPDatas={filteredPFPDatas} OverlayTrigger={OverlayTrigger} popover={popover} cFPeopleHandel={cFPeopleHandel} lFromState={lFromState} peopleSubmit={peopleSubmit} pref={pref} fName={fName} handleNameChange={handleNameChange} pfNameHandel={pfNameHandel} setFName={setFName} setPepoles={setPepoles} peopleFrom={peopleFrom} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} PrFPeoplePillsE={PrFPeoplePillsE} fpPref={fpPref} PrcorfoPeoples={PrcorfoPeoples} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} pFPonDrag={pFPonDrag} />;

  const FPrSkills = () => <PrSkillsList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PrcorfoSkill={PrcorfoSkill} PrextfoSkill={PrextfoSkill} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} pFSonDrop={pFSonDrop} pFSonDragOver={pFSonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} isPFProjectsAddS={isPFProjectsAddS} isAllOwnersMarked={isAllOwnersMarked} focused={focused} projectsSorted={projectsSorted} pFSonDrag={pFSonDrag} setSkill={setSkill} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} pFskillSub={pFskillSub} OpenCFPeople={OpenCFPeople} temporarilyMovedItem={temporarilyMovedItem} fpSref={fpSref} pfSkillsSearch={pfSkillsSearch} setPfSkillsSearch={setPfSkillsSearch} filteredPFSData={filteredPFSData} OverlayTrigger={OverlayTrigger} popover={popover} cfcircleOrProjectModes={cfcircleOrProjectModes} lFromState={lFromState} skillSubmit={skillSubmit} sref={sref} skill={skill} handleSkillChange={handleSkillChange} setSkills={setSkills} setSkillName={setSkillName} skillsFrom={skillsFrom} />;

  const FPrRoles = () => <PrRolesList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PrcorfoRoles={PrcorfoRoles} PrextfoRoles={PrextfoRoles} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} pFRonDrop={pFRonDrop} pFRonDragOver={pFRonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} IsOwnerExternal={IsOwnerExternal} isPFProjectsAddR={isPFProjectsAddR} isAllOwnersMarked={isAllOwnersMarked} focused={focused} projectsSorted={projectsSorted} pFRonDrag={pFRonDrag} setRole={setRole} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} pFroleSub={pFroleSub} OpenCFPeople={OpenCFPeople} temporarilyMovedItem={temporarilyMovedItem} fprRref={fprRref} pfRolesSearch={pfRolesSearch} setPfRolesSearch={setPfRolesSearch} filteredPFRData={filteredPFRData} roleSubmit={roleSubmit} rref={rref} role={role} handleRoleChange={handleRoleChange} setRoles={setRoles} setRoleName={setRoleName} rolesFrom={rolesFrom} lFromState={lFromState} popover={popover} cfcircleOrProjectModes={cfcircleOrProjectModes} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} OverlayTrigger={OverlayTrigger} />;

  const FPrDomains = () => <PrDomainsList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PrcorfoDomains={PrcorfoDomains} PrextfoDomains={PrextfoDomains} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} pFDonDrop={pFDonDrop} pFDonDragOver={pFDonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} IsOwnerExternal={IsOwnerExternal} dWhoIsOwnerType={dWhoIsOwnerType} isPFProjectsAddD={isPFProjectsAddD} prfRAnfdSDomain={prfRAnfdSDomain} curPCoreMembers={curPCoreMembers} pFDonDrag={pFDonDrag} setDomain={setDomain} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} cardOpensCfDomain={cardOpensCfDomain} pFdomainSub={pFdomainSub} fprDref={fprDref} pfDomainsSearch={pfDomainsSearch} setPfDomainsSearch={setPfDomainsSearch} filteredPFFDData={filteredPFFDData} OverlayTrigger={OverlayTrigger} list={list} popover={popover} cFPeopleHandel={cFPeopleHandel} lFromState={lFromState} domainSubmit={domainSubmit} dref={dref} domain={domain} handleDomainChange={handleDomainChange} setDomains={setDomains} setDomainName={setDomainName} domainsFrom={domainsFrom} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} prfRAnfdSExDomain={prfRAnfdSExDomain} curPExtendedMembers={curPExtendedMembers} />;

  const FPrLinks = () => <PrLinksList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PrcorfoLinks={PrcorfoLinks} PrextfoLinks={PrextfoLinks} clickedOnList={clickedOnList} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} isInfos={isInfos} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} pFLonDrop={pFLonDrop} pFLonDragOver={pFLonDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} IsOwnerExternal={IsOwnerExternal} isPFProjectsAddL={isPFProjectsAddL} pFLonDrag={pFLonDrag} setLink={setLink} OwnerExist={OwnerExist} toggleTagCards={toggleTagCards} pFlinkSub={pFlinkSub} fsprLref={fsprLref} pfLinksSearch={pfLinksSearch} setPfLinksSearch={setPfLinksSearch} filteredPFLData={filteredPFLData} OverlayTrigger={OverlayTrigger} list={list} popover={popover} cFPeopleHandel={cFPeopleHandel} lFromState={lFromState} linkSubmit={linkSubmit} lref={lref} handleLinkChange={handleLinkChange} setLinks={setLinks} setLinkName={setLinkName} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} link={link} />;

  const FPrCircles = () => <PrCirclesList tagFilteredMegedData={tagFilteredMegedData} handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} PrcorfoCircles={PrcorfoCircles} getSortIcon={getSortIcon} isInfos={isInfos} setCircles={setCircles} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} pFConDrop={pFConDrop} pFConDragOver={pFConDragOver} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} pFConDrag={pFConDrag} ScrollIntoView={ScrollIntoView} setCircle={setCircle} toggleTagCards={toggleTagCards} PfToCAndP={PfToCAndP} pFcircleSub={pFcircleSub} fprCref={fprCref} pfCirclesSearch={pfCirclesSearch} setPfCirclesSearch={setPfCirclesSearch} pfLinksSearch={pfLinksSearch} setPfLinksSearch={setPfLinksSearch} filteredPFCData={filteredPFCData} circleSubmit={circleSubmit} cref={cref} circle={circle} handleCircleChange={handleCircleChange} setCircleName={setCircleName} circlesFrom={circlesFrom} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} cFPeopleHandel={cFPeopleHandel} cFPeopleIdHandel={cFPeopleIdHandel} lFromState={lFromState} />;

  const FPrProjects = () => <PrProjectsList handleAliesChange={handleAliesChange} GetAliasesName={GetAliasesName} teams={teams} projectsSorted={projectsSorted} getSortIcon={getSortIcon} isInfos={isInfos} setProjects={setProjects} pCardHideOnchange={pCardHideOnchange} note={note} handleSave={handleSave} noteSaveData={noteSaveData} PrFCircleSorted={PrFCircleSorted} isTag={isTag} isEXT={isEXT} isNO={isNO} focused={focused} TagWiseSorting={TagWiseSorting} ScrollIntoView={ScrollIntoView} setProject={setProject} focusListRef={focusListRef} setFocusedRef={setFocusedRef} toggleTagCards={toggleTagCards} handelFocused={handelFocused} lFromState={lFromState} projectSubmit={projectSubmit} prref={prref} project={project} handleProjectChange={handleProjectChange} setProjectName={setProjectName} projectsFrom={projectsFrom} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} />;

  /* '''''''''''''''''''''''''''''''''''''''' Project Focus - XLS And JPEG Downlod '''''''''''''''''''''''''''''''''''''''''''''''' */
  let peojectFXls = [];
  let xlsPPeople = [...PrcorfoPeople, ...PrextfoPeople];
  let xlsPSkill = [...PrcorfoSkill, ...PrextfoSkill];
  let xlsPRole = [...PrcorfoRoles, ...PrextfoRoles];
  let xlsPDomain = [...PrcorfoDomains, ...PrextfoDomains];
  let xlsPLink = [...PrcorfoLinks, ...PrextfoLinks];

  if (focused && focused.card.length > 0 && focused.card === "Projects") {
    if (
      xlsPPeople.length > 0 ||
      xlsPSkill.length > 0 ||
      xlsPRole.length > 0 ||
      xlsPDomain.length > 0 ||
      xlsPLink.length > 0 ||
      PrcorfoCircles.length > 0 ||
      projectsSorted[focused.index]
    ) {
      let arr = [
        xlsPPeople.length,
        xlsPSkill.length,
        xlsPRole.length,
        xlsPDomain.length,
        xlsPLink.length,
        PrcorfoCircles.length,
        1,
      ];

      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });

        let xlsProject = [projectsSorted.find((p) => p._id === focused.id)]; // [projectsSorted[focused.index]];

        for (let i = 0; i < max; i++) {
          let obj = {
            People:
              xlsPPeople[i] && xlsPPeople[i]._id
                ? `${xlsPPeople[i].fname} ${xlsPPeople[i].lname}`
                : "",
            Skill:
              xlsPSkill[i] && xlsPSkill[i]._id
                ? `${xlsPSkill[i].skillName}`
                : "",
            Role:
              xlsPRole[i] && xlsPRole[i]._id ? `${xlsPRole[i].roleName}` : "",
            Domain:
              xlsPDomain[i] && xlsPDomain[i]._id
                ? `${xlsPDomain[i].domainName}`
                : "",
            Link:
              xlsPLink[i] && xlsPLink[i]._id ? `${xlsPLink[i].linkName}` : "",
            Circle:
              PrcorfoCircles[i] && PrcorfoCircles[i]._id
                ? `${PrcorfoCircles[i].circleName}`
                : "",
            Project:
              xlsProject[i] && xlsProject[i]._id
                ? `${xlsProject[i].projectName}`
                : "",
          };
          peojectFXls.push(obj);
        }
      }
    }
  }

  useEffect(() => {
    if (focused && focused.card.length > 0 && focused.card === "Projects") {
      setXls(peojectFXls);
    }
  }, [focused]);

  /* ============================== Meetings actions ================================================ */

  const cAndpADatas = (meetingsData) => {
    let cursMeetings = [];
    for (let i = 0; i < meetings.length; i++) {
      let inc = meetingsData.includes(meetings[i]._id);
      if (inc) {
        cursMeetings.push(meetings[i]);
      }
    }
    return cursMeetings;
  };

  /* ============================== Mode MODELS Action ============================================== */

  /* ============================== Circle mode actions ============================================= */

  const cMmembes = (curCoresMembers, curExtendedsMembers, data) => {
    let res = { people: null, rAndS: null, curType: null };
    let cardData = {};
    if (data && data.lead && data.lead.length > 0) {
      if (
        res &&
        res.rAndS === null &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: skillsSorted[i]._id,
              ["curType"]: "Skills",
            };
            cardData = skillsSorted[i];
            break;
          }
        }
      }
      if (
        res &&
        res.rAndS === null &&
        Object.keys(cardData).length === 0 &&
        rolesSorted &&
        rolesSorted.length > 0
      ) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: rolesSorted[i]._id,
              ["curType"]: "Roles",
            };
            cardData = rolesSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === data.lead) {
              res = {
                ...res,
                ["people"]: peopleSorted[i]._id,
                ["curType"]: "People",
              };
              break;
            }
          }
        }
      } else if (
        res &&
        res.rAndS &&
        res.rAndS.length > 0 &&
        cardData &&
        Object.keys(cardData).length > 0
      ) {
        if (
          (cardData.ownerRole === "Single owner" ||
            cardData.ownerType === "Single owner") &&
          cardData.owners &&
          cardData.owners.length > 0
        ) {
          if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < peopleSorted.length; i++) {
              if (peopleSorted[i]._id === cardData.owners[0]) {
                res = { ...res, ["people"]: peopleSorted[i]._id };
                break;
              }
            }
          }
        }
      }
    }

    //People
    let CcorfoPeople = [];
    if (curCoresMembers && curCoresMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curCoresMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CcorfoPeople.push(peopleSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.people &&
      res.people.length > 0
    ) {
      let flag = true;
      if (CcorfoPeople && CcorfoPeople.length > 0) {
        for (let i = 0; i < CcorfoPeople.length; i++) {
          if (CcorfoPeople[i]._id === res.people) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === res.people) {
              CcorfoPeople.push(peopleSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoPeople = [];
    if (curExtendedsMembers && curExtendedsMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curExtendedsMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CextfoPeople.push(peopleSorted[i]);
        }
      }
    }

    //Skills
    let CcorfoSkill = [];
    if (curCoresMembers && curCoresMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curCoresMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CcorfoSkill.push(skillsSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Skills"
    ) {
      let flag = true;
      if (CcorfoSkill && CcorfoSkill.length > 0) {
        for (let i = 0; i < CcorfoSkill.length; i++) {
          if (CcorfoSkill[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (skillsSorted && skillsSorted.length > 0) {
          for (let i = 0; i < skillsSorted.length; i++) {
            if (skillsSorted[i]._id === res.rAndS) {
              CcorfoSkill.push(skillsSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoSkill = [];
    if (curExtendedsMembers && curExtendedsMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curExtendedsMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CextfoSkill.push(skillsSorted[i]);
        }
      }
    }

    //Roles
    let CcorfoRoles = [];
    if (curCoresMembers && curCoresMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curCoresMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CcorfoRoles.push(rolesSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Roles"
    ) {
      let flag = true;
      if (CcorfoRoles && CcorfoRoles.length > 0) {
        for (let i = 0; i < CcorfoRoles.length; i++) {
          if (CcorfoRoles[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (rolesSorted && rolesSorted.length > 0) {
          for (let i = 0; i < rolesSorted.length; i++) {
            if (rolesSorted[i]._id === res.rAndS) {
              CcorfoRoles.push(rolesSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoRoles = [];
    if (curExtendedsMembers && curExtendedsMembers.Roles && curExtendedsMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curExtendedsMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CextfoRoles.push(rolesSorted[i]);
        }
      }
    }

    //Links
    let CcorfoLinks = [];
    if (curCoresMembers && curCoresMembers.Links && curCoresMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curCoresMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CcorfoLinks.push(linksSorted[i]);
        }
      }
    }

    let CextfoLinks = [];
    if (curExtendedsMembers && curExtendedsMembers.Links && curExtendedsMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curExtendedsMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CextfoLinks.push(linksSorted[i]);
        }
      }
    }

    //Domains
    let CcorfoDomains = [];
    if (curCoresMembers && curCoresMembers.Domains && curCoresMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curCoresMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CcorfoDomains.push(domainsSorted[i]);
        }
      }
    }

    let CextfoDomains = [];
    if (curExtendedsMembers && curExtendedsMembers.Domains && curExtendedsMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curExtendedsMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CextfoDomains.push(domainsSorted[i]);
        }
      }
    }

    //Projects
    let CcorfoProjects = [];
    if (curCoresMembers && curCoresMembers.Projects && curCoresMembers.Projects.length > 0) {
      for (let i = 0; i < projectsSorted.length; i++) {
        let inc = curCoresMembers.Projects.includes(projectsSorted[i]._id);
        if (inc) {
          CcorfoProjects.push(projectsSorted[i]);
        }
      }
    }

    let curData = {};
    if (
      CcorfoPeople.length > 0 ||
      CextfoPeople.length > 0 ||
      CcorfoSkill.length > 0 ||
      CextfoSkill.length > 0 ||
      CcorfoRoles.length > 0 ||
      CextfoRoles.length > 0 ||
      CcorfoLinks.length > 0 ||
      CextfoLinks.length > 0 ||
      CcorfoDomains.length > 0 ||
      CextfoDomains.length > 0 ||
      CcorfoProjects.length > 0
    ) {
      curData = {
        coreMembers: {
          People: CcorfoPeople,
          Skills: CcorfoSkill,
          Roles: CcorfoRoles,
          Links: CcorfoLinks,
          Domains: CcorfoDomains,
          Projects: CcorfoProjects,
        },
        extendedMembers: {
          People: CextfoPeople,
          Skills: CextfoSkill,
          Roles: CextfoRoles,
          Links: CextfoLinks,
          Domains: CextfoDomains,
        },
      };
    }
    return curData;
  };

  var curCModeData = [];

  if (focusMode.length > 0 && focusMode === "Circle") {
    let allCircles = [...circles, ...dCircles];
    // console.log(allCircles,members);
    for (let j = 0; j < allCircles.length; j++) {
      let cId = allCircles[j]._id;
      for (let i = 0; i < members.length; i++) {
        let curCoresMembers = {};
        let curExtendedsMembers = {};
        if (members[i].memberType === cId) {
          curCoresMembers = members[i].coreMembers;
          curExtendedsMembers = members[i].extendedMembers;
          let res = cMmembes(
            curCoresMembers,
            curExtendedsMembers,
            allCircles[j]
          );
          if (res) {
            curCModeData.push({ ...allCircles[j], ...res });
            break;
          }
        }
      }
      // console.log({curCModeData});
      let allCircleWithMember = curCModeData.map((sc) => sc._id);
      // console.log(allCircleWithMember,cId,allCircleWithMember.includes(cId));
      if (!allCircleWithMember.includes(cId)) {
        let curData = {
          coreMembers: {
            People: [],
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
            Projects: [],
          },
          extendedMembers: {
            People: [],
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
        };
        curCModeData.push({ ...allCircles[j], ...curData });
      }
    }
    // console.log({curCModeData});

    // if (dCircles && dCircles.length > 0) {
    //   for (let i = 0; i < dCircles.length; i++) {
    //     let curData = {
    //       coreMembers: {
    //         People: [],
    //         Skills: [],
    //         Roles: [],
    //         Links: [],
    //         Domains: [],
    //         Projects: [],
    //       },
    //       extendedMembers: {
    //         People: [],
    //         Skills: [],
    //         Roles: [],
    //         Links: [],
    //         Domains: [],
    //       },
    //     };
    //     curCModeData.push({ ...dCircles[i], ...curData });
    //     console.log(curCModeData);
    //   }
    // }
  }

  //People As Action
  var peopleAsCData = [...curCModeData];

  if (
    focusMode.length > 0 &&
    curCModeData &&
    curCModeData.length > 0 &&
    focusMode === "Circle" &&
    isModeSwitched
  ) {
    for (let i = 0; i < curCModeData.length; i++) {
      let totalPeopleCircleData = [];
      // Core
      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.People &&
        curCModeData[i].coreMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (let j = 0; j < curCModeData[i].coreMembers.People.length; j++) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curCModeData[i].coreMembers.People[j]._id === peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname} (Person)`,
              });
              // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname} (Person)` });

              /* totalPeopleCircleData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname} (Person)`,
                  obj: curCModeData[i].coreMembers.People[j],
                  cardType: "People",
                  Id: curCModeData[i].coreMembers.People[j]._id,
                },
              }); */
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsCData[i].coreMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Skills &&
        curCModeData[i].coreMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Skills.length; j++) {
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          // console.log('Circle name ', curCModeData[i].circleName);

          if (curCModeData[i].coreMembers.Skills[j].owners.length > 0) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curCModeData[i].coreMembers.Skills[j].owners.includes(
                peopleSorted[l]._id
              );

              /* if (inc) {
                console.log(curCModeData[i].coreMembers.Skills[j].skillName + ' has ' + peopleSorted[l].fname + ' as owners.')
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                    obj: curCModeData[i].coreMembers.Skills[j],
                    cardType: "Skills",
                    Id: curCModeData[i].coreMembers.Skills[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                // console.log('currentCirclePfmember', currentCirclePfmember);
                let incpf = currentCirclePfmember.coreMembers.Skills.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[l]._id.toString()
                );
                if (inc && incpf) {
                  // console.log(currentCirclePfmember.coreMembers.Skills, ' has ' + peopleSorted[l].fname);
                  // console.log(curCModeData[i].coreMembers.Skills[j].skillName + ' has ' + peopleSorted[l].fname + ' as owners.')
                  curCircleSkill.push({
                    ...peopleSorted[l],
                    field: `${curCModeData[i].coreMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                  });
                  // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[l],
                    field: {
                      name: `${curCModeData[i].coreMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                      obj: curCModeData[i].coreMembers.Skills[j],
                      cardType: "Skills",
                      Id: curCModeData[i].coreMembers.Skills[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }
        }
        if (curCircleSkill.length > 0) {
          // console.log('curCircleSkill', curCircleSkill)
          // console.log("before change skill", peopleAsCData[i].coreMembers.Skills);
          peopleAsCData[i].coreMembers.Skills = curCircleSkill;
          // peopleAsCData.push(curCircleSkill)
          // console.log("after change skill", peopleAsCData[i].coreMembers.Skills);
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Roles &&
        curCModeData[i].coreMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        // console.log({curCircleRole,length: curCircleRole.length});
        // console.log('curCModeData['+i+'].coreMembers.Roles', curCModeData[i].coreMembers.Roles);
        // console.log("curCModeData[0].coreMembers.Roles[1]---",curCModeData[0].coreMembers.Roles[1]);
        for (let j = 0; j < curCModeData[i].coreMembers.Roles.length; j++) {
          // console.log(curCModeData[i].coreMembers.Roles[j])
          // console.log('curCModeData['+i+'].coreMembers.Roles['+j+']', curCModeData[i].coreMembers.Roles[j].roleName)
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          // console.log('Circle name: ', curCModeData[i].circleName, 'Role name: ', curCModeData[i].coreMembers.Roles[j].roleName);
          if (
            curCModeData[i].coreMembers.Roles[j].owners &&
            curCModeData[i].coreMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // console.log("k-----",k,{curCircleRole,length: curCircleRole.length});
              let inc = curCModeData[i].coreMembers.Roles[j].owners.includes(
                peopleSorted[k]._id
              );

              // console.log({curCircleRole,length: curCircleRole.length});
              // console.log(curCModeData[i].coreMembers.Roles[j], peopleSorted[k]._id);
              /* if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                    obj: curCModeData[i].coreMembers.Roles[j],
                    cardType: "Roles",
                    Id: curCModeData[i].coreMembers.Roles[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                let incpf = currentCirclePfmember.coreMembers.Roles.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[k]._id.toString()
                );
                // console.log(currentCirclePfmember.coreMembers.Roles, ' has ' + peopleSorted[k].fname);
                // console.log(curCModeData[i].coreMembers.Roles[j].roleName + ' has ' + peopleSorted[k].fname + ' as owners.')
                // console.log(inc, incpf)
                // console.log({curCircleRole,length: curCircleRole.length});

                if (inc && incpf) {
                  // console.log("inside inc && incpf",{curCircleRole,length: curCircleRole.length});
                  curCircleRole.push({
                    ...peopleSorted[k],
                    field: `${curCModeData[i].coreMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                  });
                  // console.log({curCircleRole,length: curCircleRole.length});

                  // totalPeopleCircleData.push({ ...peopleSorted[k], field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[k],
                    field: {
                      name: `${curCModeData[i].coreMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                      obj: curCModeData[i].coreMembers.Roles[j],
                      cardType: "Roles",
                      Id: curCModeData[i].coreMembers.Roles[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }

          // if (curCircleRole.length > 0) {
          //   console.log("inside if late")
          //   console.log("curCircleRole", curCircleRole);
          //   curCircleRole.forEach((element, index) => {
          //       console.log(`Element at index ${index}:`, element);
          //   });
          //   // console.log("curCircleRole", JSON.stringify(curCircleRole, null, 2));

          //   // console.log("before change role", peopleAsCData[i].coreMembers.Roles);
          //   peopleAsCData[i].coreMembers.Roles = curCircleRole;
          //   // console.log("after change role",peopleAsCData[i].coreMembers.Roles);
          // }
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Domains &&
        curCModeData[i].coreMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Domains.length; j++) {
          if (
            curCModeData[i].coreMembers.Domains[j].owners.owner &&
            curCModeData[i].coreMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].coreMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curCModeData[i].coreMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Domains[j].domainName} (Domain)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                    obj: curCModeData[i].coreMembers.Domains[j],
                    cardType: "Domains",
                    Id: curCModeData[i].coreMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleDomain.length > 0) {
            peopleAsCData[i].coreMembers.Domains = curCircleDomain;
          }
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Links &&
        curCModeData[i].coreMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Links.length; j++) {
          if (
            curCModeData[i].coreMembers.Links[j].owner &&
            curCModeData[i].coreMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].coreMembers.Links[j].owner.includes(peopleSorted[k]._id)
              if (
                curCModeData[i].coreMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Links[j].linkName} (Link)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                    obj: curCModeData[i].coreMembers.Links[j],
                    cardType: "Links",
                    Id: curCModeData[i].coreMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleLink.length > 0) {
            peopleAsCData[i].coreMembers.Links = curCircleLink;
          }
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Projects &&
        curCModeData[i].coreMembers.Projects.length > 0
      ) {
        let curCircleProject = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Projects.length; j++) {
          if (
            curCModeData[i].coreMembers.Projects[j].lead &&
            curCModeData[i].coreMembers.Projects[j].lead.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curCModeData[i].coreMembers.Projects[j].lead.includes(
                peopleSorted[k]._id
              );
              if (inc) {
                curCircleProject.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Projects[j].projectName} (${GetAliasesName(teams, "Projects")})`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Projects[j].projectName} (Project)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Projects[j].projectName} (${GetAliasesName(teams, "Projects")})`,
                    obj: curCModeData[i].coreMembers.Projects[j],
                    cardType: "Projects",
                    Id: curCModeData[i].coreMembers.Projects[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleProject.length > 0) {
            peopleAsCData[i].coreMembers.Projects = curCircleProject;
          }
        }
      }

      //External
      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.People &&
        curCModeData[i].extendedMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (
          let j = 0;
          j < curCModeData[i].extendedMembers.People.length;
          j++
        ) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curCModeData[i].extendedMembers.People[j]._id ===
              peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname} (Person)`,
              });
              // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname} (Person)` });

              totalPeopleCircleData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname} (Person)`,
                  obj: curCModeData[i].extendedMembers.People[j],
                  cardType: "People",
                  Id: curCModeData[i].extendedMembers.People[j]._id,
                },
              });
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsCData[i].extendedMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Skills &&
        curCModeData[i].extendedMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (
          let j = 0;
          j < curCModeData[i].extendedMembers.Skills.length;
          j++
        ) {
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          // console.log('currentCirclePfmember', currentCirclePfmember);
          if (curCModeData[i].extendedMembers.Skills[j].owners.length > 0) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curCModeData[i].extendedMembers.Skills[
                j
              ].owners.includes(peopleSorted[l]._id);

              /* if (inc) {
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                    obj: curCModeData[i].extendedMembers.Skills[j],
                    cardType: "Skills",
                    Id: curCModeData[i].extendedMembers.Skills[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                let incpf = currentCirclePfmember.extendedMembers.Skills.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[l]._id.toString()
                );
                if (inc && incpf) {
                  curCircleSkill.push({
                    ...peopleSorted[l],
                    field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                  });
                  // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[l],
                    field: {
                      name: `${curCModeData[i].extendedMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                      obj: curCModeData[i].extendedMembers.Skills[j],
                      cardType: "Skills",
                      Id: curCModeData[i].extendedMembers.Skills[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }
        }
        peopleAsCData[i].extendedMembers.Skills = curCircleSkill;
        // peopleAsCData.push(curCircleSkill)
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Roles &&
        curCModeData[i].extendedMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        for (let j = 0; j < curCModeData[i].extendedMembers.Roles.length; j++) {
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          if (
            curCModeData[i].extendedMembers.Roles[j].owners &&
            curCModeData[i].extendedMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curCModeData[i].extendedMembers.Roles[
                j
              ].owners.includes(peopleSorted[k]._id);
              /* if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                    obj: curCModeData[i].extendedMembers.Roles[j].roleName,
                    cardType: "Roles",
                    Id: curCModeData[i].extendedMembers.Roles[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                let incpf = currentCirclePfmember.extendedMembers.Roles.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[k]._id.toString()
                );
                if (inc && incpf) {
                  curCircleRole.push({
                    ...peopleSorted[k],
                    field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                  });
                  // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[k],
                    field: {
                      name: `${curCModeData[i].extendedMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                      obj: curCModeData[i].extendedMembers.Roles[j].roleName,
                      cardType: "Roles",
                      Id: curCModeData[i].extendedMembers.Roles[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }
          peopleAsCData[i].extendedMembers.Roles = curCircleRole;
        }
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Domains &&
        curCModeData[i].extendedMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (
          let j = 0;
          j < curCModeData[i].extendedMembers.Domains.length;
          j++
        ) {
          if (
            curCModeData[i].extendedMembers.Domains[j].owners.owner &&
            curCModeData[i].extendedMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].extendedMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curCModeData[i].extendedMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].extendedMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Domains[j].domainName} (Domain)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                    obj: curCModeData[i].extendedMembers.Domains[j],
                    cardType: "Domains",
                    Id: curCModeData[i].extendedMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          peopleAsCData[i].extendedMembers.Domains = curCircleDomain;
        }
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Links &&
        curCModeData[i].extendedMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curCModeData[i].extendedMembers.Links.length; j++) {
          if (
            curCModeData[i].extendedMembers.Links[j].owner &&
            curCModeData[i].extendedMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              if (
                curCModeData[i].extendedMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].extendedMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                });

                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Links[j].linkName} (Link)` } });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                    obj: curCModeData[i].extendedMembers.Links[j],
                    cardType: "Links",
                    Id: curCModeData[i].extendedMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          peopleAsCData[i].extendedMembers.Links = curCircleLink;
        }
      }

      let peopleAsData = [];

      if (totalPeopleCircleData.length > 0) {
        for (let i = 0; i < totalPeopleCircleData.length; i++) {
          peopleAsData.push(totalPeopleCircleData[i]._id);
        }
      }

      let peopleAsDataSet = [...new Set([...peopleAsData])];

      let PeopleAsFilterData = [];

      if (totalPeopleCircleData.length > 0 && peopleAsDataSet.length > 0) {
        for (let i = 0; i < peopleAsDataSet.length; i++) {
          let inc = [];
          for (let j = 0; j < totalPeopleCircleData.length; j++) {
            if (totalPeopleCircleData[j]._id === peopleAsDataSet[i]) {
              inc.push(totalPeopleCircleData[j].field);
            }
          }
          if (inc.length > 0) {
            PeopleAsFilterData.push({ id: peopleAsDataSet[i], FildName: inc });
          }
        }
      }

      let PeopleAsAllFilterData = [];

      if (PeopleAsFilterData.length > 0) {
        for (let k = 0; k < PeopleAsFilterData.length; k++) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              PeopleAsFilterData[k].id.toString() ===
              peopleSorted[l]._id.toString()
            ) {
              PeopleAsAllFilterData.push({
                ...peopleSorted[l],
                FildName: PeopleAsFilterData[k].FildName,
              });
            }
          }
        }
      }
      peopleAsCData[i].pepoleData = PeopleAsAllFilterData;
    }
  }

  let curCModeDatas = [...curCModeData];
  if (focusMode === "Circle" && !isModeSwitched) {
    let curCMode = [];
    curCModeDatas = curCModeData.filter((e) => {
      return !fList[e.circleName];
    });
    // console.log(26862,{curCModeDatas});
    // console.log(26863,{focusList});
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < curCModeDatas.length; j++) {
          if (focusList[i].id === curCModeDatas[j]._id) {
            curCMode.push(curCModeDatas[j]);
          }
        }
      }
    }
    if (curCMode.length > 0) {
      curCModeDatas = curCMode;
    }
  }

  const cmLeadVerify = (data) => {
    if (data && data.lead && data.lead.length > 0) {
      let res = { people: null, rAndS: null, curType: null };
      let cardData = {};
      if (
        res &&
        res.rAndS === null &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: skillsSorted[i]._id,
              ["curType"]: "Skills",
            };
            cardData = skillsSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null && rolesSorted && rolesSorted.length > 0) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: rolesSorted[i]._id,
              ["curType"]: "Roles",
            };
            cardData = rolesSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === data.lead) {
              res = {
                ...res,
                ["people"]: peopleSorted[i]._id,
                ["curType"]: "People",
              };
              break;
            }
          }
        }
      } else if (
        res &&
        res.rAndS &&
        res.rAndS.length > 0 &&
        cardData &&
        Object.keys(cardData).length > 0
      ) {
        if (
          (cardData.ownerRole === "Single owner" ||
            cardData.ownerType === "Single owner") &&
          cardData.owners &&
          cardData.owners.length > 0
        ) {
          if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < peopleSorted.length; i++) {
              if (peopleSorted[i]._id === cardData.owners[0]) {
                res = { ...res, ["people"]: peopleSorted[i]._id };
                break;
              }
            }
          }
        }
      }
      return res;
    }
  };

  // rolesSorted skillsSorted
  const cmHandelFocused = (index) => {
    setFocusedMode("");
    let indexedCard = circlesSorted[index];
    handelFocused({ card: "Circles", index: index, id: indexedCard._id });
  };

  /*
   * Get the owner names to show in blue text pills 
   @parameters 
   # data: skill/ role card data 
   # cpid: circle/ project id
   # type: core/ extended
   */
  const getAllOwnerFCM = (data, cpId = false, type = false) => {
    let allData = [];
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.owners &&
      data.owners.length > 0
    ) {
      let currentPfmembers = pfmembers.find((pfm) => pfm.memberType === cpId);
      if (currentPfmembers) {
        // If the skill/ role is in the allOwners flag array
        if (currentPfmembers.allOwnersFlag[type].includes(data._id)) {
          // console.log('all owners');
          /* if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < data.owners.length; i++) {
              for (let j = 0; j < peopleSorted.length; j++) {
                if (data.owners[i] === peopleSorted[j]._id) {
                  allData.push(peopleSorted[j])
                  break;
                }
              }
            }
          } */
        }
        // If the skill/ role is not in the allOwners flag array
        else {
          // console.log('not all owners');
          for (let i = 0; i < data.owners.length; i++) {
            for (let j = 0; j < peopleSorted.length; j++) {
              if (type === "core") {
                let item = currentPfmembers.coreMembers.Skills.filter(
                  (sk) =>
                    sk.CardId === data._id &&
                    sk.peopleIds === peopleSorted[j]._id
                );
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }

                item =
                  item.length === 0
                    ? currentPfmembers.coreMembers.Roles.filter(
                      (rk) =>
                        rk.CardId === data._id &&
                        rk.peopleIds === peopleSorted[j]._id
                    )
                    : [];
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }
              }

              if (type === "extended") {
                let item = currentPfmembers.extendedMembers.Skills.filter(
                  (sk) =>
                    sk.CardId === data._id &&
                    sk.peopleIds === peopleSorted[j]._id
                );
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }

                item =
                  item.length === 0
                    ? currentPfmembers.extendedMembers.Roles.filter(
                      (rk) =>
                        rk.CardId === data._id &&
                        rk.peopleIds === peopleSorted[j]._id
                    )
                    : [];
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }
              }
            }
          }
        }
      }
    }

    return allData;
  };

  const getOwnerTypeFCM = (data) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.owners &&
      data.owners.type
    ) {
      let curdata = { type: null, cardId: null, name: null };
      if (skillsSorted && skillsSorted.length > 0) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.owners.type) {
            curdata = {
              ...curdata,
              ["type"]: "Skill",
              ["cardId"]: data.owners.type,
              ["name"]: `${skillsSorted[i].skillName}`,
            };
          }
        }
      }
      if (curdata.type === null && rolesSorted && rolesSorted.length > 0) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.owners.type) {
            curdata = {
              ...curdata,
              ["type"]: "Role",
              ["cardId"]: data.owners.type,
              ["name"]: `${rolesSorted[i].roleName}`,
            };
          }
        }
      }
      return curdata;
    }
  };

  const getAllPeopleFD = () => {
    if (
      focusMode.length > 0 &&
      focusMode === "Circle" &&
      peopleSorted &&
      peopleSorted.length > 0
    ) {
      return peopleSorted;
    }
  };

  /* '''''''''''''''''''''''''''''''''''''''' Circle Mode XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */
  let xlsCMData = [];
  let xlsCMList = [];

  if (focusMode.length > 0 && focusMode === "Circle" && !isModeSwitched) {
    let obj = {};
    for (let i = 0; i < curCModeData.length; i++) {
      let curCircleData = [];

      if (curCModeData[i].coreMembers) {
        if (
          curCModeData[i].coreMembers.People &&
          curCModeData[i].coreMembers.People.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.People.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Skills &&
          curCModeData[i].coreMembers.Skills.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Skills.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Roles &&
          curCModeData[i].coreMembers.Roles.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Roles.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Links &&
          curCModeData[i].coreMembers.Links.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Links.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Domains &&
          curCModeData[i].coreMembers.Domains.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Domains.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Domains[j].domainName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Projects &&
          curCModeData[i].coreMembers.Projects.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].coreMembers.Projects.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Projects[j].projectName}`
            );
          }
        }
      }
      if (curCModeData[i].extendedMembers) {
        if (
          curCModeData[i].extendedMembers.People &&
          curCModeData[i].extendedMembers.People.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.People.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Skills &&
          curCModeData[i].extendedMembers.Skills.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Skills.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Roles &&
          curCModeData[i].extendedMembers.Roles.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Roles.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Links &&
          curCModeData[i].extendedMembers.Links.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Links.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Domains &&
          curCModeData[i].extendedMembers.Domains.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Domains.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Domains[j].domainName}`
            );
          }
        }
      }
      if (
        curCircleData &&
        curCircleData.length > 0 &&
        curCModeData[i] &&
        curCModeData[i].circleName &&
        curCModeData[i].circleName.length > 0
      ) {
        xlsCMList.push(curCModeData[i].circleName);
        obj[i] = curCircleData;
      }
    }
    // console.log(27276, curCModeData);

    if (Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        if (obj[l] === "" || obj[l]) {
          arr.push(obj[l].length);
        }
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            if (obj[b] === "" || obj[b]) {
              xlsObj[b] = obj[b][k] ? obj[b][k] : "";
            }
          }
          xlsCMData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Circle" &&
      !isModeSwitched
    ) {
      setXlsCM(xlsCMData);
      setXlsName(xlsCMList);
    }
  }, [focusMode, isModeSwitched]);

  // // ============================== Project mode actions ================================================
  const pMmembes = (curCoresMembers, curExtendedsMembers, data) => {
    let res = { people: null, rAndS: null, curType: null };
    let cardData = {};
    if (data && data.lead && data.lead.length > 0) {
      if (
        res &&
        res.rAndS === null &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: skillsSorted[i]._id,
              ["curType"]: "Skills",
            };
            cardData = skillsSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null && rolesSorted && rolesSorted.length > 0) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: rolesSorted[i]._id,
              ["curType"]: "Roles",
            };
            cardData = rolesSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === data.lead) {
              res = {
                ...res,
                ["people"]: peopleSorted[i]._id,
                ["curType"]: "People",
              };
              break;
            }
          }
        }
      } else if (
        res &&
        res.rAndS &&
        res.rAndS.length > 0 &&
        cardData &&
        Object.keys(cardData).length > 0
      ) {
        if (
          (cardData.ownerRole === "Single owner" ||
            cardData.ownerType === "Single owner") &&
          cardData.owners &&
          cardData.owners.length > 0
        ) {
          if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < peopleSorted.length; i++) {
              if (peopleSorted[i]._id === cardData.owners[0]) {
                res = { ...res, ["people"]: peopleSorted[i]._id };
                break;
              }
            }
          }
        }
      }
    }

    //People
    let CcorfoPeople = [];
    if (curCoresMembers && curCoresMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curCoresMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CcorfoPeople.push(peopleSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.people &&
      res.people.length > 0
    ) {
      let flag = true;
      if (CcorfoPeople && CcorfoPeople.length > 0) {
        for (let i = 0; i < CcorfoPeople.length; i++) {
          if (CcorfoPeople[i]._id === res.people) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === res.people) {
              CcorfoPeople.push(peopleSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoPeople = [];
    if (curExtendedsMembers && curExtendedsMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curExtendedsMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CextfoPeople.push(peopleSorted[i]);
        }
      }
    }

    //Skills
    let CcorfoSkill = [];
    if (curCoresMembers && curCoresMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curCoresMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CcorfoSkill.push(skillsSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Skills"
    ) {
      let flag = true;
      if (CcorfoSkill && CcorfoSkill.length > 0) {
        for (let i = 0; i < CcorfoSkill.length; i++) {
          if (CcorfoSkill[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (skillsSorted && skillsSorted.length > 0) {
          for (let i = 0; i < skillsSorted.length; i++) {
            // console.log(21183, skillsSorted[i]._id === res.rAndS)
            if (skillsSorted[i]._id === res.rAndS) {
              CcorfoSkill.push(skillsSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoSkill = [];
    if (curExtendedsMembers && curExtendedsMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curExtendedsMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CextfoSkill.push(skillsSorted[i]);
        }
      }
    }

    //Roles
    let CcorfoRoles = [];
    if (curCoresMembers && curCoresMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curCoresMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CcorfoRoles.push(rolesSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Roles"
    ) {
      let flag = true;
      if (CcorfoRoles && CcorfoRoles.length > 0) {
        for (let i = 0; i < CcorfoRoles.length; i++) {
          if (CcorfoRoles[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (rolesSorted && rolesSorted.length > 0) {
          for (let i = 0; i < rolesSorted.length; i++) {
            if (rolesSorted[i]._id === res.rAndS) {
              CcorfoRoles.push(rolesSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoRoles = [];

    if (curExtendedsMembers && curExtendedsMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curExtendedsMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CextfoRoles.push(rolesSorted[i]);
        }
      }
    }

    //Links
    let CcorfoLinks = [];

    if (curCoresMembers && curCoresMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curCoresMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CcorfoLinks.push(linksSorted[i]);
        }
      }
    }

    let CextfoLinks = [];

    if (curExtendedsMembers && curExtendedsMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curExtendedsMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CextfoLinks.push(linksSorted[i]);
        }
      }
    }

    //Domains
    let CcorfoDomains = [];

    if (curCoresMembers && curCoresMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curCoresMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CcorfoDomains.push(domainsSorted[i]);
        }
      }
    }

    let CextfoDomains = [];

    if (curExtendedsMembers && curExtendedsMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curExtendedsMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CextfoDomains.push(domainsSorted[i]);
        }
      }
    }

    //Circles
    let PrcorfoCircles = [];

    if (curCoresMembers && curCoresMembers.Circles.length > 0) {
      for (let i = 0; i < circlesSorted.length; i++) {
        let inc = curCoresMembers.Circles.includes(circlesSorted[i]._id);
        if (inc) {
          PrcorfoCircles.push(circlesSorted[i]);
        }
      }
    }

    let curData = {};

    if (
      CcorfoPeople.length > 0 ||
      CextfoPeople.length > 0 ||
      CcorfoSkill.length > 0 ||
      CextfoSkill.length > 0 ||
      CcorfoRoles.length > 0 ||
      CextfoRoles.length > 0 ||
      CcorfoLinks.length > 0 ||
      CextfoLinks.length > 0 ||
      CcorfoDomains.length > 0 ||
      CextfoDomains.length > 0 ||
      PrcorfoCircles.length > 0
    ) {
      curData = {
        coreMembers: {
          People: CcorfoPeople,
          Skills: CcorfoSkill,
          Roles: CcorfoRoles,
          Links: CcorfoLinks,
          Domains: CcorfoDomains,
          Circles: PrcorfoCircles,
        },
        extendedMembers: {
          People: CextfoPeople,
          Skills: CextfoSkill,
          Roles: CextfoRoles,
          Links: CextfoLinks,
          Domains: CextfoDomains,
        },
      };
    }
    return curData;
  };

  var curPModeData = [];
  if (focusMode.length > 0 && focusMode === "Project") {
    for (let j = 0; j < projects.length; j++) {
      let cId = projects[j]._id;
      for (let i = 0; i < members.length; i++) {
        let curCoresMembers = {};
        let curExtendedsMembers = {};
        if (members[i].memberType === cId) {
          curCoresMembers = members[i].coreMembers;
          curExtendedsMembers = members[i].extendedMembers;
          let res = pMmembes(curCoresMembers, curExtendedsMembers, projects[j]);
          if (res) {
            curPModeData.push({ ...projects[j], ...res });
            break;
          }
        }
      }
    }
  }

  //People As Action
  var peopleAsPData = [...curPModeData];
  if (
    focusMode.length > 0 &&
    curPModeData &&
    curPModeData.length > 0 &&
    focusMode === "Project" &&
    isModeSwitched
  ) {
    for (let i = 0; i < curPModeData.length; i++) {
      let totalPeopleProjectData = [];
      // Core
      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.People &&
        curPModeData[i].coreMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (let j = 0; j < curPModeData[i].coreMembers.People.length; j++) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curPModeData[i].coreMembers.People[j]._id === peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname} (Person)`,
              });
              // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname} (Person)` });

              totalPeopleProjectData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname} (Person)`,
                  obj: curPModeData[i].coreMembers.People[j],
                  cardType: "People",
                  Id: curPModeData[i].coreMembers.People[j]._id,
                },
              });
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsPData[i].coreMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Skills &&
        curPModeData[i].coreMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Skills.length; j++) {
          if (
            curPModeData[i].coreMembers.Skills[j].owners &&
            curPModeData[i].coreMembers.Skills[j].owners.length > 0
          ) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curPModeData[i].coreMembers.Skills[j].owners.includes(
                peopleSorted[l]._id
              );
              if (inc) {
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curPModeData[i].coreMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].coreMembers.Skills[j].skillName} (Skill)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curPModeData[i].coreMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                    obj: curPModeData[i].coreMembers.Skills[j],
                    cardType: "Skills",
                    Id: curPModeData[i].coreMembers.Skills[j]._id,
                  },
                });
              }
            }
          }
        }
        if (curCircleSkill.length > 0) {
          peopleAsPData[i].coreMembers.Skills = curCircleSkill;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Roles &&
        curPModeData[i].coreMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Roles.length; j++) {
          if (
            curPModeData[i].coreMembers.Roles[j].owners &&
            curPModeData[i].coreMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curPModeData[i].coreMembers.Roles[j].owners.includes(
                peopleSorted[k]._id
              );
              if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Roles[j].roleName} (Role)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                    obj: curPModeData[i].coreMembers.Roles[j],
                    cardType: "Roles",
                    Id: curPModeData[i].coreMembers.Roles[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleRole.length > 0) {
            peopleAsPData[i].coreMembers.Roles = curCircleRole;
          }
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Domains &&
        curPModeData[i].coreMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Domains.length; j++) {
          // console.log(curPModeData[i].coreMembers.Domains)
          if (
            curPModeData[i].coreMembers &&
            curPModeData[i].coreMembers.Domains[j] &&
            curPModeData[i].coreMembers.Domains[j].owners &&
            curPModeData[i].coreMembers.Domains[j].owners.owner &&
            curPModeData[i].coreMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curPModeData[i].coreMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].coreMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Domains[j].domainName} (Domain)`});
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                    obj: curPModeData[i].coreMembers.Domains[j],
                    cardType: "Domains",
                    Id: curPModeData[i].coreMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleDomain.length > 0) {
            peopleAsPData[i].coreMembers.Domains = curCircleDomain;
          }
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Links &&
        curPModeData[i].coreMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Links.length; j++) {
          // console.log(curCModeData[i].coreMembers.Links)
          if (
            curPModeData[i].coreMembers.Links[j].owner &&
            curPModeData[i].coreMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].coreMembers.Links[j].owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].coreMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Links[j].linkName} (Link)` });
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                    obj: curPModeData[i].coreMembers.Links[j],
                    cardType: "Links",
                    Id: curPModeData[i].coreMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleLink.length > 0) {
            peopleAsPData[i].coreMembers.Links = curCircleLink;
          }
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Circles &&
        curPModeData[i].coreMembers.Circles.length > 0
      ) {
        let curCircleProject = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Circles.length; j++) {
          // console.log(curCModeData[i].coreMembers.Projects)
          if (
            curPModeData[i].coreMembers.Circles[j].lead &&
            curPModeData[i].coreMembers.Circles[j].lead.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curPModeData[i].coreMembers.Circles[j].lead.includes(
                peopleSorted[k]._id
              );
              if (inc) {
                curCircleProject.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Circles[j].circleName} (${GetAliasesName(teams, "Circles")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Circles[j].circleName} (Circle)`, });
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Circles[j].circleName} (${GetAliasesName(teams, "Circles")})`,
                    obj: curPModeData[i].coreMembers.Circles[j],
                    cardType: "Circles",
                    Id: curPModeData[i].coreMembers.Circles[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleProject.length > 0) {
            peopleAsPData[i].coreMembers.Circles = curCircleProject;
          }
        }
      }

      //External
      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.People &&
        curPModeData[i].extendedMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (
          let j = 0;
          j < curPModeData[i].extendedMembers.People.length;
          j++
        ) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curPModeData[i].extendedMembers.People[j]._id ===
              peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname} (Person)`,
              });

              // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname} (Person)` });

              totalPeopleProjectData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname} (Person)`,
                  obj: curPModeData[i].extendedMembers.People[j],
                  cardType: "People",
                  Id: curPModeData[i].extendedMembers.People[j]._id,
                },
              });
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsPData[i].extendedMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Skills &&
        curPModeData[i].extendedMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (
          let j = 0;
          j < curPModeData[i].extendedMembers.Skills.length;
          j++
        ) {
          // console.log(curCModeData[i].extendedMembers.Skills)
          if (curPModeData[i].extendedMembers.Skills[j].owners.length > 0) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curPModeData[i].extendedMembers.Skills[
                j
              ].owners.includes(peopleSorted[l]._id);
              if (inc) {
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curPModeData[i].extendedMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].extendedMembers.Skills[j].skillName} (Skill)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Skills[j].skillName} (${GetAliasesName(teams, "Skills")})`,
                    obj: curPModeData[i].extendedMembers.Skills[j],
                    cardType: "Skills",
                    Id: curPModeData[i].extendedMembers.Skills[j]._id,
                  },
                });
              }
            }
          }
        }
        peopleAsPData[i].extendedMembers.Skills = curCircleSkill;
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Roles &&
        curPModeData[i].extendedMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        for (let j = 0; j < curPModeData[i].extendedMembers.Roles.length; j++) {
          // console.log(curCModeData[i].extendedMembers.Roles)
          if (
            curPModeData[i].extendedMembers.Roles[j].owners &&
            curPModeData[i].extendedMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curPModeData[i].extendedMembers.Roles[
                j
              ].owners.includes(peopleSorted[k]._id);
              if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].extendedMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].extendedMembers.Roles[j].roleName} (Role)`, });
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Roles[j].roleName} (${GetAliasesName(teams, "Roles")})`,
                    obj: curPModeData[i].extendedMembers.Roles[j],
                    cardType: "Roles",
                    Id: curPModeData[i].extendedMembers.Roles[j]._id,
                  },
                });
              }
            }
          }
          peopleAsPData[i].extendedMembers.Roles = curCircleRole;
        }
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Domain &&
        curPModeData[i].extendedMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (
          let j = 0;
          j < curPModeData[i].extendedMembers.Domains.length;
          j++
        ) {
          // console.log(curCModeData[i].extendedMembers.Domains)
          if (
            curPModeData[i].extendedMembers.Domains[j].owners.owner &&
            curPModeData[i].extendedMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].extendedMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].extendedMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].extendedMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].extendedMembers.Domains[j].domainName} (Domain)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Domains[j].domainName} (${GetAliasesName(teams, "Domains")})`,
                    obj: curPModeData[i].extendedMembers.Domains[j],
                    cardType: "Domains",
                    Id: curPModeData[i].extendedMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          peopleAsPData[i].extendedMembers.Domains = curCircleDomain;
        }
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Links &&
        curPModeData[i].extendedMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curPModeData[i].extendedMembers.Links.length; j++) {
          // console.log(curCModeData[i].extendedMembers.Links)
          if (
            curPModeData[i].extendedMembers.Links[j].owner &&
            curPModeData[i].extendedMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].extendedMembers.Links[j].owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].extendedMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].extendedMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                });

                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].extendedMembers.Links[j].linkName} (Link)` });

                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Links[j].linkName} (${GetAliasesName(teams, "Links")})`,
                    obj: curPModeData[i].extendedMembers.Links[j],
                    cardType: "Links",
                    Id: curPModeData[i].extendedMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          peopleAsPData[i].extendedMembers.Links = curCircleLink;
        }
      }

      let peopleAsData = [];
      if (totalPeopleProjectData.length > 0) {
        for (let i = 0; i < totalPeopleProjectData.length; i++) {
          peopleAsData.push(totalPeopleProjectData[i]._id);
        }
      }

      let peopleAsDataSet = [...new Set([...peopleAsData])];

      let PeopleAsFilterData = [];
      if (totalPeopleProjectData.length > 0 && peopleAsDataSet.length > 0) {
        for (let i = 0; i < peopleAsDataSet.length; i++) {
          let inc = [];
          for (let j = 0; j < totalPeopleProjectData.length; j++) {
            if (totalPeopleProjectData[j]._id === peopleAsDataSet[i]) {
              inc.push(totalPeopleProjectData[j].field);
            }
          }
          if (inc.length > 0) {
            PeopleAsFilterData.push({ id: peopleAsDataSet[i], FildName: inc });
          }
        }
      }

      let PeopleAsAllFilterData = [];
      if (PeopleAsFilterData.length > 0) {
        for (let k = 0; k < PeopleAsFilterData.length; k++) {
          // console.log('dev:', PeopleAsFilterData);
          for (let l = 0; l < peopleSorted.length; l++) {
            // console.log(peopleSorted);
            if (
              PeopleAsFilterData[k].id.toString() ===
              peopleSorted[l]._id.toString()
            ) {
              PeopleAsAllFilterData.push({
                ...peopleSorted[l],
                FildName: PeopleAsFilterData[k].FildName,
              });
            }
          }
        }
      }
      peopleAsPData[i].pepoleData = PeopleAsAllFilterData;
    }
  }

  /* ==================================== Project mode FOCUSED MODE ============================================= */
  let curPModeDatas = [...curPModeData];
  if (focusMode === "Project" && !isModeSwitched) {
    let curPMode = [];
    curPModeDatas = curPModeData.filter((e) => {
      // console.log(fList[e.projectName]);
      return !fList[e.projectName];
    });
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < curPModeDatas.length; j++) {
          if (focusList[i].id === curPModeDatas[j]._id) {
            curPMode.push(curPModeDatas[j]);
          }
        }
      }
    }
    if (curPMode.length > 0) {
      curPModeDatas = curPMode;
    }
  }

  const prmHandelFocused = (index) => {
    setFocusedMode("");
    let indexedCard = peopleSorted[index];
    handelFocused({ card: "Projects", index: index, id: indexedCard._id });
  };

  /* ============================== Circle mode and Project mode UI ================================================ */
  const CircleMode = () => <CircleModeList curCModeDatas={curCModeDatas} cAndpADatas={cAndpADatas} cmLeadVerify={cmLeadVerify} getAllPeopleFD={getAllPeopleFD} Get_ShowHideColumn_Project_Circle_Mode={Get_ShowHideColumn_Project_Circle_Mode} tagFilteredMegedData={tagFilteredMegedData} specialModeDataObj={specialModeDataObj} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} setCircles={setCircles} GetAliasesName={GetAliasesName} teams={teams} cmHandelFocused={cmHandelFocused} isEXT={isEXT} isNO={isNO} isTag={isTag} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} getAllOwnerFCM={getAllOwnerFCM} setPepole={setPepole} peopleSorted={peopleSorted} getOwnerTypeFCM={getOwnerTypeFCM} setSkill={setSkill} cardOpensCfDomain={cardOpensCfDomain} setDomain={setDomain} setLink={setLink} OpenCFPeople={OpenCFPeople} toggleTagCards={toggleTagCards} setRole={setRole} setProject={setProject} />;

  const ProjectMode = () => <ProjectModeList curPModeDatas={curPModeDatas} cAndpADatas={cAndpADatas} cmLeadVerify={cmLeadVerify} Get_ShowHideColumn_Project_Circle_Mode={Get_ShowHideColumn_Project_Circle_Mode} tagFilteredMegedData={tagFilteredMegedData} specialModeDataObj={specialModeDataObj} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} setProjects={setProjects} GetAliasesName={GetAliasesName} teams={teams} prmHandelFocused={prmHandelFocused} isEXT={isEXT} isNO={isNO} isTag={isTag} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} IsOwnerExternal={IsOwnerExternal} getAllOwnerFCM={getAllOwnerFCM} setSkill={setSkill} OpenCFPeople={OpenCFPeople} peopleSorted={peopleSorted} getOwnerTypeFCM={getOwnerTypeFCM} cardOpensCfDomain={cardOpensCfDomain} setDomain={setDomain} setLink={setLink} setPepole={setPepole} toggleTagCards={toggleTagCards} setRole={setRole} setProject={setProject} />;

  /* '''''''''''''''''''''''''''''''''''''''' Project Mode XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */
  let xlsPMData = [];
  let xlsPMList = [];

  if (focusMode.length > 0 && focusMode === "Project" && !isModeSwitched) {
    let obj = {};
    for (let i = 0; i < curPModeData.length; i++) {
      let curProjectData = [];

      if (curPModeData[i].coreMembers) {
        if (
          curPModeData[i].coreMembers.People &&
          curPModeData[i].coreMembers.People.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.People.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Skills &&
          curPModeData[i].coreMembers.Skills.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Skills.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Roles &&
          curPModeData[i].coreMembers.Roles.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Roles.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Links &&
          curPModeData[i].coreMembers.Links.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Links.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Domains &&
          curPModeData[i].coreMembers.Domains.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Domains.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Domains[j].domainName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Projects &&
          curPModeData[i].coreMembers.Projects.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].coreMembers.Projects.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Projects[j].projectName}`
            );
          }
        }
      }
      if (curPModeData[i].extendedMembers) {
        if (
          curPModeData[i].extendedMembers.People &&
          curPModeData[i].extendedMembers.People.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.People.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Skills &&
          curPModeData[i].extendedMembers.Skills.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Skills.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Roles &&
          curPModeData[i].extendedMembers.Roles.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Roles.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Links &&
          curPModeData[i].extendedMembers.Links.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Links.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Domains &&
          curPModeData[i].extendedMembers.Domains.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Domains.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Domains[j].domainName}`
            );
          }
        }
      }
      if (
        curProjectData &&
        curProjectData.length > 0 &&
        curPModeData[i] &&
        curPModeData[i].projectName &&
        curPModeData[i].projectName.length > 0
      ) {
        xlsPMList.push(curPModeData[i].projectName);
        obj[i] = curProjectData;
      }
    }

    if (Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        if (obj[l] && obj[l].length > -1) {
          arr.push(obj[l].length);
        }
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            if (obj[b] && obj[b].length > -1) {
              xlsObj[b] = obj[b][k] ? obj[b][k] : "";
            }
          }
          xlsPMData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Project" &&
      !isModeSwitched
    ) {
      setXlsCM(xlsPMData);
      setXlsName(xlsPMList);
    }
  }, [focusMode, isModeSwitched]);

  /* ============================================== Circle As People Model ========================================== */

  let peopleAsCDatas = [...peopleAsCData];

  if (focusMode === "Circle" && isModeSwitched) {
    // console.log('Circle mode is switched to People');

    let curpeopleAsC = [];
    peopleAsCDatas = peopleAsCData.filter((e) => {
      return !fList[e.circleName];
    });
    // console.log('peopleAsCDatas', peopleAsCDatas)
    // console.log('focusList', focusList)
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < peopleAsCDatas.length; j++) {
          if (focusList[i].id === peopleAsCDatas[j]._id) {
            curpeopleAsC.push(peopleAsCDatas[j]);
          }
        }
      }
    };
    if (curpeopleAsC.length > 0) {
      peopleAsCDatas = curpeopleAsC;
    }
  }

  // console.log({ peopleAsCDatas })
  const CircleAsPeopleMode = () => <CircleAsPeopleModeList peopleAsCDatas={peopleAsCDatas} cAndpADatas={cAndpADatas} cmLeadVerify={cmLeadVerify} Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people={Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people} tagFilteredMegedData={tagFilteredMegedData} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} setCircles={setCircles} GetAliasesName={GetAliasesName} teams={teams} cmHandelFocused={cmHandelFocused} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} toggleTagCards={toggleTagCards} cardOpens={cardOpens} setPepole={setPepole} specialModeDataObj={specialModeDataObj} />;

  /* '''''''''''''''''''''''''''''''''''''''' XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */

  let xlsCMPASData = [];
  let xlsCMPAsList = [];

  if (focusMode.length > 0 && focusMode === "Circle" && isModeSwitched) {
    let obj = {};
    let count = 0;
    for (let i = 0; i < peopleAsCData.length; i++) {
      let xlsCMPAsName = [];
      if (
        peopleAsCData[i].pepoleData &&
        peopleAsCData[i].pepoleData.length > 0
      ) {
        for (let j = 0; j < peopleAsCData[i].pepoleData.length; j++) {
          xlsCMPAsName.push(
            `${peopleAsCData[i].pepoleData[j].fname} ${peopleAsCData[i].pepoleData[j].lname}`
          );
        }
        if (
          xlsCMPAsName.length > 0 &&
          peopleAsCData[i] &&
          peopleAsCData[i].circleName
        ) {
          xlsCMPAsList.push(peopleAsCData[i].circleName);
          obj[count] = xlsCMPAsName;
          count++;
        }
      }
    }
    if (Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        arr.push(obj[l].length);
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            xlsObj[b] = obj[b][k] ? obj[b][k] : "";
          }
          xlsCMPASData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Circle" &&
      isModeSwitched
    ) {
      setXlsCM(xlsCMPASData);
      setXlsName(xlsCMPAsList);
    }
  }, [focusMode, isModeSwitched]);

  let peopleAsPDatas = [...peopleAsPData];

  if (focusMode === "Project" && isModeSwitched) {
    let curpeopleAsP = [];
    peopleAsPDatas = peopleAsPData.filter((e) => {
      return !fList[e.projectName];
    });
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < peopleAsPDatas.length; j++) {
          if (focusList[i].id === peopleAsPDatas[j]._id) {
            curpeopleAsP.push(peopleAsPDatas[j]);
          }
        }
      }
    }
    if (curpeopleAsP.length > 0) {
      peopleAsPDatas = curpeopleAsP;
    }
  }

  const ProjectAsPeopleMode = () => <ProjectAsPeopleModeList peopleAsPDatas={peopleAsPDatas} cAndpADatas={cAndpADatas} Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people={Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people} tagFilteredMegedData={tagFilteredMegedData} toggleEXTnNOCards={toggleEXTnNOCards} getSortIcon={getSortIcon} setProjects={setProjects} GetAliasesName={GetAliasesName} teams={teams} prmHandelFocused={prmHandelFocused} isTag={isTag} isEXT={isEXT} isNO={isNO} TagWiseSorting={TagWiseSorting} tagFilteredObj={tagFilteredObj} extNoFilteredObj={extNoFilteredObj} toggleTagCards={toggleTagCards} cardOpens={cardOpens} setPepole={setPepole} specialModeDataObj={specialModeDataObj} />;

  /* '''''''''''''''''''''''''''''''''''''''' XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */

  let xlsPMPASData = [];
  let xlsPMPAsList = [];

  if (focusMode.length > 0 && focusMode === "Project" && isModeSwitched) {
    let obj = {};
    let count = 0;
    for (let i = 0; i < peopleAsPData.length; i++) {
      let xlsPMPAsName = [];
      if (
        peopleAsPData[i].pepoleData &&
        peopleAsPData[i].pepoleData.length > 0
      ) {
        for (let j = 0; j < peopleAsPData[i].pepoleData.length; j++) {
          xlsPMPAsName.push(
            `${peopleAsPData[i].pepoleData[j].fname} ${peopleAsPData[i].pepoleData[j].lname}`
          );
        }
        if (
          xlsPMPAsName.length > 0 &&
          peopleAsPData[i] &&
          peopleAsPData[i].projectName
        ) {
          xlsPMPAsList.push(peopleAsPData[i].projectName);
          obj[count] = xlsPMPAsName;
          count++;
        }
      }
    }
    if (Object.keys(obj) && Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        arr.push(obj[l].length);
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            xlsObj[b] = obj[b][k] ? obj[b][k] : "";
          }
          xlsPMPASData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Project" &&
      isModeSwitched
    ) {
      setXlsCM(xlsPMPASData);
      setXlsName(xlsPMPAsList);
    }
  }, [focusMode, isModeSwitched]);

  /* ................................................................................................................... */

  let ItemsData = localStorage.getItem("items");
  if (ItemsData) {
    ItemsData = ItemsData.split(",");
  }

  let listData = [];
  if (ItemsData && ItemsData.length > 0) {
    listData = ItemsData;
  } else {
    listData = list;
  }

  let cusDatas = [People, Skills, Roles, Domains, Links, Circles, Projects];
  let cuFPDatas = [
    FPPeople,
    FPSkills,
    FPRoles,
    FPDomains,
    FPLinks,
    FPCircles,
    FPProjects,
  ];
  let cuFCDatas = [
    FCPeople,
    FCSkills,
    FCRoles,
    FCDomains,
    FCLinks,
    FCCircles,
    FCProjects,
  ];
  let cuFPrDatas = [
    FPrPeople,
    FPrSkills,
    FPrRoles,
    FPrDomains,
    FPrLinks,
    FPrCircles,
    FPrProjects,
  ];
  let circleModes = [CircleMode, CircleAsPeopleMode];
  let projectModes = [ProjectMode, ProjectAsPeopleMode];

  // useEffect(() => {
  if (listHide && Object.keys(listHide).length > 0) {
    let listArray = [];
    if (listData && listData.length > 0) {
      for (let i = 0; i < listData.length; i++) {
        if (listHide[listData[i]] === false) {
          listArray.push(listData[i]);
        }
      }
    }
    if (listArray) {
      list = [...new Set(listArray)];
    }
  }
  // }, [listHide, setListHide])

  var curFocused = [];
  if (focusMode && focusMode.length > 0) {
    if (focusMode === "Circle") {
      if (!isModeSwitched) {
        curFocused.push(circleModes[0]); // Load added as
      } else if (isModeSwitched) {
        curFocused.push(circleModes[1]); // Load people
      }
    }

    if (focusMode === "Project") {
      if (!isModeSwitched) {
        curFocused.push(projectModes[0]); // Load added as
      } else if (isModeSwitched) {
        curFocused.push(projectModes[1]); // Load people
      }
    }
  }

  let cuDatas = [];
  if (focused && focused.card.length > 0) {
    if (focused.card === "People") {
      cuDatas = cuFPDatas;
      ListData.push("People");
    }
    if (focused.card === "Circles") {
      cuDatas = cuFCDatas;
      ListData.push("Circles");
    }
    if (focused.card === "Projects") {
      cuDatas = cuFPrDatas;
      ListData.push("Projects");
    }
  } else {
    cuDatas = cusDatas;
  }

  if (allSearch && allSearch.length > 0) {
    let set = [...new Set(ListData)];
    let SearchList = [];
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < set.length; j++) {
        let inc = set.includes(list[i]);
        if (inc) {
          SearchList.push(list[i]);
        }
      }
    }
    list = [...new Set(SearchList)];
  }

  let allData = [];
  if (list.length > 0) {
    for (let i = 0; i < 7; i++) {
      switch (list[i]) {
        case "People":
          allData.push(cuDatas[0]);
          break;
        case "Skills":
          allData.push(cuDatas[1]);
          break;
        case "Roles":
          allData.push(cuDatas[2]);
          break;
        case "Domains":
          allData.push(cuDatas[3]);
          break;
        case "Links":
          allData.push(cuDatas[4]);
          break;
        case "Circles":
          allData.push(cuDatas[5]);
          break;
        case "Projects":
          allData.push(cuDatas[6]);
          break;
        default:
          break;
      }
    }
  }

  const onDragEndSP = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    allData = reorder(allData, result.source.index, result.destination.index);
  };

  const onDragEndSPCM = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    curFocused = reorder(
      curFocused,
      result.source.index,
      result.destination.index
    );
  };

  /* Remove the .latest-created class from the element after 3 seconds if exists */
  useEffect(() => {
    let elem = document.querySelector(".latest-created");
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
      });

      setTimeout(() => {
        elem.classList.remove("latest-created");
        localStorage.setItem("latest-created", "");
      }, 3000);
    }
  }, [document.querySelector(".latest-created")]);

  useEffect(() => {
    const container = document.getElementById("st-peoples");
    if (container) {
      // Filter out items that should not be draggable
      const dragulaContainers = Array.from(container.children).filter(
        (child) => !child.classList.contains("no-drag")
      );

      const drake = dragula([container]);

      // Optional: Add event listeners to handle drag events
      drake.on("drag", (el, source) => {
        // console.log('Dragging...', el, source);
      });

      drake.on("drop", (el, target, source, sibling) => {
        // console.log('Dropped!', el.id, target, source);

        let curpeople = Array.from(container.children)
          .filter((child) => !child.classList.contains("no-drag"))
          .map((child) => child.getAttribute("id"));

        // console.log('New order:', curpeople);
        if (states && states.cListMode) {
          const copyListItems = [...peopleSorted];
          const sortedCopyListItems = copyListItems.sort((a, b) => {
            const indexA = curpeople.indexOf(a._id);
            const indexB = curpeople.indexOf(b._id);
            return indexA - indexB;
          });
          // console.log(sortedCopyListItems);

          setpeopleSorted(sortedCopyListItems);
          let updatedStates = { ...states, Person: curpeople };
          // console.log(updatedStates)
          dispatch({ type: STATES_MODIFICATIONS, payload: updatedStates });
          if (curpeople && curpeople.length > 0) {
            peopleStateUpdate(curpeople);
          }
        }
      });

      return () => {
        drake.destroy(); // Clean up to prevent memory leaks
      };
    }
  }, [document.getElementById("st-peoples")]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  return (
    <>
      {/* <Permission_ModePopUp /> */}
      {allData && allData.length > 0 && curFocused.length === 0 ? (
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <div className="wrap-lists all-lists" id="standard-mode">
            {allData.map((Item, index) => (
              <Item key={index} />
            ))}
          </div>
        </DragDropContext>
      ) : null}

      {/* Circle and Project mode */}

      {curFocused && curFocused.length > 0
        ? curFocused.map((Items, index) => {
          return <Items key={index} />;
        })
        : null}
    </>
  );
}
export default Body;