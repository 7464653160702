import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import User from './Component/Header/Header';
import Redirect from './Pages/Redirect/Redirect';
import Person_Report from './Pages/Report/Person_Report';
import { useDispatch, useSelector } from 'react-redux';
// import BaseComponent from './Pages/Login/BaseComponent'
import Login from './Pages/Login/Login';
import ResetSession from "./Pages/ResetSession/ResetSession";
import Pricing from './Pages/Pricing/Pricing'
import Register from './Pages/Register/Register';
// import Onboarding from './Pages/Onboarding';
// import FreeTrial from './Pages/FreeTrial';
import RequestReport from './Pages/RequestReport/RequestReport';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import LegalNotice from './Pages/LegalNotice/LegalNotice';
import AddOn from './Pages/AddOn/AddOn';

// import Loader from './Loader';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { gapi } from 'gapi-script';
import { getLoginUserData } from "./store/actions/authAction";
import { GetAdminSetting } from "./store/actions/settingsAction";
import { Helmet } from "react-helmet";
import OnboardingWizard from './Pages/OnboardingWizard/OnboardingWizard';
import { clearLocalStorage } from './HelperFunctions/ClearLocalStorage';
import { LogRocketSetUp } from './config/keys';
import ReviewPage from './Pages/ReviewPage/ReviewPage';

let imageArr = [
  {
    image: '../images/new-wallpapers/2531n.jpg',
    text: `Sweet Teams<br/>Are Made Of This`,
    color: '#000000'
  },
  {
    image: '../images/new-wallpapers/2532.jpg',
    text: `Ain't No Teamwork<br/>High Enough`,
    color: '#000000'
  },
  {
    image: '../images/new-wallpapers/2533.jpg',
    text: `Teams Just Want<br/>To Have Fun`,
    color: '#FFFFFF'
  },
  {
    image: '../images/new-wallpapers/2534.jpg',
    text: `Team It<br/>Like It’s Hot`,
    color: '#FFFFFF'
  },
  {
    image: '../images/new-wallpapers/2554n.jpg',
    text: `I Wanna Team<br/>With Somebody`,
    color: '#000000'
  }
]

function App() {
  const dispatch = useDispatch();
  const { loading, successMessage, error, curUserData, authenticate, myInfo } = useSelector(state => state.auth)
  const { adminSettings, Setting, SettingError, SettingSuccessMessage, SettingSuccessMessagee } = useSelector(state => state.settings)
  const [objNumber, setObjNumber] = useState(0);

  // after calling LogRocket.init()
  if (LogRocketSetUp) {
    LogRocket.init('i4jcug/mytpt-app', {
      dom: {
        inputSanitizer: true,
      },
    });
  }

  useEffect(() => {
    if (authenticate && myInfo) {
      LogRocket.identify(`${myInfo.userId}`, {
        name: `${myInfo.fname} ${myInfo.lname}`,
        email: `${myInfo.email}`,
        plan: `${curUserData.plan}`
      });
      dispatch(getLoginUserData({ userId: myInfo.userId }));
    }
  }, [authenticate, myInfo]);

  if (LogRocketSetUp) {
    setupLogRocketReact(LogRocket);
  }

  useEffect(() => {
    let savedtoken = localStorage.getItem('authToken');
    // console.log(0, savedtoken, 1, curUserData.sessionToken);

    if (savedtoken && curUserData.sessionToken && savedtoken !== curUserData.sessionToken) {
      // localStorage.clear();
      clearLocalStorage();
      window.location.reload();
    }
  }, [curUserData]);

  useEffect(() => {
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }

    let new_objNumber = getRandomInt(5);

    setObjNumber(new_objNumber);
    let bgImg = '';
    if (localStorage.getItem('bg-img') !== null) {
      bgImg = localStorage.getItem('bg-img');
    } else {
      bgImg = imageArr[new_objNumber].image;
    }

    /* Reset all previous wallpaper classes if applied */
    document.body.classList.remove(`wallpaper-bg-0`);
    document.body.classList.remove(`wallpaper-bg-1`);
    document.body.classList.remove(`wallpaper-bg-2`);
    document.body.classList.remove(`wallpaper-bg-3`);
    document.body.classList.remove(`wallpaper-bg-4`);
    document.body.classList.remove(`purpose-tree`);
    document.body.classList.remove(`workflow-page`);

    /* Apply the current wallpaper class */
    document.body.classList.add(`wallpaper-bg-${new_objNumber}`);

    if (new_objNumber !== 2 && new_objNumber !== 3) {
      document.body.classList.add(`menu2`);
    } else {
      document.body.classList.remove(`menu2`);
    }

    if (window.location.pathname == '' || window.location.pathname == '/') {
      document.body.style = '';
      document.body.classList.remove('no-app-pages');
    } else if (
      window.location.pathname == '/login' /* ||
      window.location.pathname == '/reset-password' ||
      window.location.pathname == '/request-report' */
    ) {
      document.body.classList.add('no-app-pages');
      document.body.style.backgroundImage = `url(${bgImg})`;
      document.body.style.backgroundSize = `cover`;
      document.body.style.backgroundRepeat = `no-repeat`;
      localStorage.removeItem('bg-img');
    }

    function start() {
      gapi.client.init({
        clientId: '740610511692-mlkgdr9pd0ap1r5nvvu4vvlgnja86p8v.apps.googleusercontent.com',
        scope: ''
      })
    };
    gapi.load('client: auth2', start);

  }, []);

  return (
    <BrowserRouter>
      {/* <Suspense fallback={<Loader />}> */}
      <Routes>
        {authenticate ?
          <Route path="/" exact element={<User />} /> :
          <Route path="/" element={<Navigate replace to="/login" />} />
        }

        <Route path='/login' exact element={<Login data={imageArr[objNumber]} objNumber={objNumber} />} ></Route>
        <Route path='/onboarding-wizard' exact element={<OnboardingWizard data={imageArr[objNumber]} />} ></Route>
        <Route path='/reset-password/:token' exact element={<ResetPassword data={imageArr[2]} objNumber={2} />} ></Route>
        <Route path='/reset-password' exact element={<ResetPassword data={imageArr[2]} objNumber={2} />} ></Route>
        <Route path='/redirect/:token' exact element={<Redirect />} ></Route>
        <Route path='/my-report/:secreatKey' exact element={<Person_Report />} ></Route>
        <Route path='/request-report' exact element={<RequestReport />} ></Route>
        <Route path='/pricing' exact element={<Pricing />} ></Route>
        <Route path='/register' exact element={<Register />} ></Route>
        <Route path='/register/:token' exact element={<Register />} ></Route>
        {/* <Route path='/onboarding' exact element={<Onboarding />} ></Route> */}
        {/* <Route path='/free-trial' exact element={<FreeTrial />} ></Route> */}
        <Route path='/legal-notice' exact element={<LegalNotice />} ></Route>
        <Route path='/reset-session-token/:userId' exact element={<ResetSession />} ></Route>
        <Route path='/add-on' exact element={<AddOn />} ></Route>
        <Route path='/review/:token' exact element={<ReviewPage />} ></Route>
        
        {/* Wildcard route for 404 page */}
        <Route path='*' element={<PageNotFound />} ></Route>
      </Routes>
      {/* </Suspense> */}
    </BrowserRouter>
  );
}

export default App;