import axios from 'axios';
import {
    WORKFLOW_ADD_SUCCESS,
    WORKFLOW_ADD_FAIL,
    ALL_WORKFLOW_GET_SUCCESS,
    ALL_WORKFLOW_GET_ERROR,
    WORKFLOW_SUCCESS_MESSAGE_CLEAR,
    WORKFLOW_ERROR_MESSAGE_CLEAR,
    WORKFLOW_UPDATE_SUCCESS,
    WORKFLOW_UPDATE_FAIL,
    WORKFLOW_DELETE_SUCCESS,
    WORKFLOW_DELETE_FAIL
} from '../types/workflowType';

import { SERVER_URI } from '../../config/keys';
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker';

// Function to set authorization headers
export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => Promise.reject(error)
    );
};

// Action to create a new workflow
export const createWorkflow = (data) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken');
        try {
            userAuth(token);
            const response = await axios.post(`${SERVER_URI}/workflow/create`, data);
            dispatch({
                type: WORKFLOW_ADD_SUCCESS,
                payload: {
                    workflowSuccessMessage: response.data.message,
                    current: response.data.current,
                    workflows: response.data.workflows
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: WORKFLOW_ADD_FAIL,
                payload: {
                    workflowErrorMessage: 'Error adding workflow'
                }
            });
        }
    };
};

// Action to fetch all workflows
export const getWorkflows = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': data.userId,
                'teamId': data.teamId
            }
        }

        try {
            const token = localStorage.getItem("authToken");
            userAuth(token);
            const response = await axios.get(`${SERVER_URI}/workflow/get`, config);

            dispatch({
                type: ALL_WORKFLOW_GET_SUCCESS,
                payload: {
                    workflows: response.data.workflows
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: ALL_WORKFLOW_GET_ERROR,
                payload: {
                    workflowErrorMessage: 'Error fetching workflows'
                }
            });
        }
    };
};

// Action to update a workflow
export const updateWorkflow = (data) => {
    // console.log(data);

    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken');
        try {
            userAuth(token);
            const response = await axios.put(`${SERVER_URI}/workflow/update`, data);

            dispatch({
                type: WORKFLOW_UPDATE_SUCCESS,
                payload: {
                    workflowSuccessMessage: response.data.message,
                    current: response.data.current,
                    workflows: response.data.workflows
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: WORKFLOW_UPDATE_FAIL,
                payload: {
                    workflowErrorMessage: 'Error updating workflow'
                }
            });
        }
    };
};

// Action to delete a workflow
export const deleteWorkflow = (data) => {
    // console.log(data);

    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken');

        try {
            userAuth(token);
            const response = await axios.post(`${SERVER_URI}/workflow/delete`, data);

            dispatch({
                type: WORKFLOW_DELETE_SUCCESS,
                payload: {
                    workflowSuccessMessage: response.data.message,
                    workflows: response.data.workflows
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: WORKFLOW_DELETE_FAIL,
                payload: {
                    workflowErrorMessage: 'Error deleting workflow'
                }
            });
        }
    };
};