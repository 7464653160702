import { configureStore } from '@reduxjs/toolkit'
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { authReducer } from './reducers/authReducers'
import { teamReducer } from './reducers/teamReducers'
import { peopleReducer } from './reducers/peopleReducers';
import { skillsReducer } from './reducers/skillReducers';
import { domainReducer } from './reducers/domainReducers';
import { linkReducer } from './reducers/linkReducers';
import { projectReducer } from './reducers/projectReducers';
import { roleReducer } from './reducers/roleReducers';
import { circleReducer } from './reducers/circleReducers';
import { tagsReducer } from './reducers/tagsReducers';
import { meetingReducer } from './reducers/meetingReducers';
import { statesReducer } from './reducers/stateReducers';
import { memberReducer } from './reducers/memberReducers';
import { reportReducer } from './reducers/reportReducer';
import { infoReducer } from './reducers/infoReducers';
import { pricingReducer } from './reducers/pricingReducers';
import { noteReducer } from './reducers/noteReducers';
import { historyReducer } from './reducers/historyReducers';
import { pfmemberReducer } from './reducers/pfmemberReducers';
import { toolsReducer } from './reducers/toolsReducers';
import { settingReducer } from './reducers/settingsReducer';
import { accessManagementReducer } from './reducers/accessManagementReducer';
import { workflowReducer } from './reducers/workflowReducer';

import blockDispatchMiddleware from './middleware/blockDispatchMiddleware';
import { reviewReducer } from './reducers/reviewReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    teams: teamReducer,
    peoples: peopleReducer,
    skills: skillsReducer,
    domains: domainReducer,
    links: linkReducer,
    roles: roleReducer,
    circles: circleReducer,
    projects: projectReducer,
    meetings: meetingReducer,
    tags: tagsReducer,
    members: memberReducer,
    pfmembers: pfmemberReducer,
    states: statesReducer,
    infos: infoReducer,
    notes: noteReducer,
    historys: historyReducer,
    authReport: reportReducer,
    pricings: pricingReducer,
    tools: toolsReducer,
    settings: settingReducer,
    accessManagement: accessManagementReducer,
    review: reviewReducer,
    workflows: workflowReducer
});

// const middleware = [thunkMiddleware]

const store = configureStore({ 
    reducer: rootReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(blockDispatchMiddleware), 
});

export default store;