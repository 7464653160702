import {
    WORKFLOW_ADD_SUCCESS,
    WORKFLOW_ADD_FAIL,
    ALL_WORKFLOW_GET_SUCCESS,
    ALL_WORKFLOW_GET_ERROR,
    WORKFLOW_SUCCESS_MESSAGE_CLEAR,
    WORKFLOW_ERROR_MESSAGE_CLEAR,
    WORKFLOW_UPDATE_SUCCESS,
    WORKFLOW_UPDATE_FAIL,
    WORKFLOW_DELETE_SUCCESS,
    WORKFLOW_DELETE_FAIL
} from '../types/workflowType';

const workflowState = {
    current: null,
    workflowData: [],
    workflowMessage: null,
    workflowError: null
}

export const workflowReducer = (state = workflowState, action) => {
    const { type, payload } = action;

    if (type === WORKFLOW_ADD_SUCCESS) {
        return {
            ...state,
            current: payload.current,
            workflowData: payload.workflows.reverse(),
            workflowMessage: payload.message
        };
    }

    if (type === WORKFLOW_UPDATE_SUCCESS) {
        return {
            ...state,
            current: payload.current,
            workflowData: payload.workflows.reverse(),
            workflowMessage: payload.message
        };
    }

    if (type === WORKFLOW_ADD_FAIL || type === WORKFLOW_UPDATE_FAIL) {
        return {
            ...state,
            workflowError: payload.message
        };
    }

    if (type === ALL_WORKFLOW_GET_SUCCESS) {
        return {
            ...state,
            workflowData: payload.workflows.reverse(),
            workflowMessage: payload.message,
        };
    }

    if (type === ALL_WORKFLOW_GET_ERROR) {
        return {
            ...state,
            workflowMessage: null,
            workflowError: payload.message,
        };
    }

    if (type === WORKFLOW_DELETE_SUCCESS) {
        return {
            ...state,
            workflowMessage: payload.workflowSuccessMessage,
            workflowData: payload.workflows.reverse(),
            workflowError: null,
        };
    }

    if (type === WORKFLOW_DELETE_FAIL) {
        return {
            ...state,
            workflowMessage: null,
            workflowError: payload.workflowErrorMessage,
        };
    }

    if (type === WORKFLOW_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            workflowMessage: null
        };
    }

    if (type === WORKFLOW_ERROR_MESSAGE_CLEAR) {
        return {
            ...state,
            workflowError: null
        };
    }

    return state;
};
