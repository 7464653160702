import React, { useEffect, useState } from 'react';
import './WorkFlow.css';
import WorkFlowComponent from './WorkFlowComponent';
import { createWorkflow, deleteWorkflow, getWorkflows, updateWorkflow } from '../../store/actions/workflowAction';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { GetAliasesName } from '../../HelperFunctions/GetAliasesName';
import { WORKFLOW_ERROR_MESSAGE_CLEAR, WORKFLOW_SUCCESS_MESSAGE_CLEAR } from '../../store/types/workflowType';
import { CLIENT_URL } from '../../config/keys';
import { freezeWindow, unfreezeWindow } from '../../HelperFunctions/Overlay';
import { getState } from '../../store/actions/stateAction';

export default function WorkFlow({ myInfo, teams, peoples, skills, roles, dRoles, domains, links, workflowData, workflowMessage, workflowError, report = false, isAllView = false, peopleId = false, showWorkflow = false }) {
    const dispatch = useDispatch();

    const newWorkflow = {
        _id: null, // New workflow doesn't have an ID initially
        teamId: localStorage.getItem("teamId"),
        name: "",
        notes: "",
        ownerType: null,
        owner: null,
        steps: [{ name: "", notes: "", owners: [] }],
        softDelete: false
    };

    const [workflowSearch, setWorkflowSearch] = useState("");
    const [workflows, setWorkflows] = useState([]);
    const [workflowToSave, setWorkflowToSave] = useState(null);

    useEffect(() => {
        document.body.classList.add('workflow-page');

        if (!report) {
            // dispatch(getWorkflows({ teamId: localStorage.getItem("teamId"), userId: myInfo.userId }));
            dispatch(getState({ teamId: localStorage.getItem("teamId"), userId: myInfo.userId }));
        }
    }, []);

    useEffect(() => {
        if (workflowData && workflowData.length > 0) {
            setWorkflows(workflowData);
        } else {
            if (!report && workflowSearch === "") {
                setWorkflows([newWorkflow]);
            }
        }
    }, [workflowData]);

    useEffect(() => {
        if (workflowMessage) {
            dispatch({ type: WORKFLOW_SUCCESS_MESSAGE_CLEAR });
        }

        if (workflowError) {
            Swal.fire({
                title: "Error",
                text: workflowError,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
            }).then(() => {
                dispatch({ type: WORKFLOW_ERROR_MESSAGE_CLEAR });
                setWorkflows(workflowData);
            })
        }
    }, [workflowMessage, workflowError]);

    const addWorkflow = () => {
        setWorkflows([newWorkflow, ...workflows]);
    };

    const addStepToWorkflow = (workflowIndex) => {
        setWorkflows(workflows.map((wf, index) =>
            index === workflowIndex
                ? { ...wf, steps: [...wf.steps, { name: "", notes: "", owners: [] }] }
                : wf
        ));
    };

    const deleteStepFromWorkflow = (workflowIndex, stepIndex) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this.",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                setWorkflows(workflows.map((wf, index) =>
                    index === workflowIndex
                        ? { ...wf, steps: wf.steps.filter((_, sIndex) => sIndex !== stepIndex) }
                        : wf
                ));

                freezeWindow(false, true, true);
                // Set workflow index to trigger saveWorkflow in useEffect
                setWorkflowToSave(workflowIndex);
            }
        });
    };

    const saveWorkflow = (workflowIndex) => {
        if (report) return false;

        const workflow = workflows[workflowIndex];
        const updatedWorkflow = { ...workflow, _id: workflow._id || null }; // Ensure _id is set or remains null

        if (updatedWorkflow.name.trim() === "") {
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate_animated animate_fadeInDown",
                },
                hideClass: {
                    popup: "animate_animated animate_fadeOutUp",
                },
                text: "Please enter workflow name!",
            });
            return false;
        }

        freezeWindow(true, true, true);

        if (updatedWorkflow._id === null) {
            // console.log("Creating workflow", updatedWorkflow);
            dispatch(createWorkflow(updatedWorkflow)).then(() => unfreezeWindow(false));
        } else {
            // console.log("Updating workflow", updatedWorkflow);
            dispatch(updateWorkflow(updatedWorkflow)).then(() => unfreezeWindow(false));
        }
    };

    const copyWorkflow = (workflowIndex) => {
        setWorkflows((prevWorkflows) => {
            const workflowToCopy = prevWorkflows[workflowIndex];
            if (workflowToCopy) {
                const copiedWorkflow = {
                    ...workflowToCopy,
                    _id: null, // New copy doesn't have an ID
                    name: `${workflowToCopy.name} (Copy)`,
                    steps: [...workflowToCopy.steps] // Copy steps array
                };

                const newWorkflows = [copiedWorkflow, ...prevWorkflows];

                freezeWindow(true, true, true);

                // Set the index of the newly copied workflow to trigger saving
                // setWorkflowToSave(newWorkflows.length - 1);
                setWorkflowToSave(0);

                return newWorkflows;
            }
            return prevWorkflows;
        });
    };

    const handleDeleteWorkflow = (workflowIndex) => {
        const workflowToDelete = workflows[workflowIndex];

        // console.log("Deleting workflow", workflowToDelete);

        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this.",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                freezeWindow(true, true, true);

                if (workflowToDelete._id !== null) {
                    dispatch(deleteWorkflow(workflowToDelete));
                }
                setWorkflows((prevWorkflows) => prevWorkflows.filter((_, index) => index !== workflowIndex));
            }
        });
    };

    const handleChange = (workflowIndex, stepIndex = null, field, value) => {
        // console.log({ workflowIndex, stepIndex, field, value });

        setWorkflows(workflows.map((wf, wIndex) =>
            wIndex === workflowIndex
                ? {
                    ...wf,
                    ...(stepIndex === null
                        ? {
                            [field]: value,
                            ...(field === "ownerType" && { owner: null }) // Reset owner if ownerType changes
                        }
                        : {
                            steps: wf.steps.map((step, sIndex) =>
                                sIndex === stepIndex ? { ...step, [field]: value } : step
                            )
                        })
                }
                : wf
        ));
    };

    /* Generate workflow ownertype options */
    const [workflowOwnerTypeOptions, setWorkflowOwnerTypeOptions] = useState({});
    const generateWorkflowOwnerTypeOptions = () => {
        const generateTypeOptions = (data, labelKey, valueKey = "_id") => {
            return data.map((item) => ({
                label: item[labelKey],
                value: item[valueKey],
                groupLabel: GetAliasesName(teams, labelKey),
            }));
        };

        const groupedOwnerTypeOptions = [
            {
                label: GetAliasesName(teams, "Skills"),
                options: generateTypeOptions(skills, "skillName"),
            },
            {
                label: GetAliasesName(teams, "Roles"),
                options: [...generateTypeOptions(roles, "roleName"), ...generateTypeOptions(dRoles, "roleName")],
            },
            {
                label: GetAliasesName(teams, "Domains"),
                options: generateTypeOptions(domains, "domainName"),
            },
            {
                label: GetAliasesName(teams, "Links"),
                options: generateTypeOptions(links, "linkName"),
            },
        ];

        setWorkflowOwnerTypeOptions(groupedOwnerTypeOptions);
    };

    /* Generate wf ownertype selected array */
    const [selectedWorkflowOwnerTypes, setSelectedWorkflowOwnerTypes] = useState([]);
    const generateSelectedWorkflowOwnerTypes = () => {
        const result = {};
        if (workflows) {
            workflows.forEach((workflow, index) => {
                let found = false;

                const searchAndAdd = (array, labelKey, valueKey, groupLabel) => {
                    if (found) return; // Stop searching if already found

                    const match = array.find(item => item._id === workflow.ownerType);
                    if (match) {
                        result[index] = {
                            label: match[labelKey],
                            value: match[valueKey],
                            groupLabel: GetAliasesName(teams, groupLabel),
                        };
                        found = true; // Mark as found to stop further searching
                    }
                };

                searchAndAdd(skills, "skillName", "_id", "Skills");
                searchAndAdd([...roles, ...dRoles], "roleName", "_id", "Roles");
                searchAndAdd(domains, "domainName", "_id", "Domains");
                searchAndAdd(links, "linkName", "_id", "Links");
            });
        }
        // console.log(result);
        setSelectedWorkflowOwnerTypes(result);
    };

    /* Generate workflow owner options */
    const [workflowOwnerOptions, setWorkflowOwnerOptions] = useState({});
    const generateWorkflowOwnerOptions = () => {
        const newOwnerOptions = {};
        if (workflows) {
            workflows.forEach((workflow, wfIndex) => {
                const selectedOptionId = workflow.ownerType; // Get selected ownerType (which is just an objectId)
                if (!selectedOptionId) return; // Skip if no ownerType is selected

                let ownerList = [];
                const dataArrays = [skills, roles, domains, links]; // Search in these arrays

                for (const dataArray of dataArrays) {
                    const matchedItem = dataArray.find(item => item._id === selectedOptionId);
                    if (matchedItem && matchedItem.owners && matchedItem.owners.length > 0) {
                        ownerList = matchedItem.owners
                            .map(ownerId => {
                                const person = peoples.find(p => p._id === ownerId);
                                return person ? { label: `${person.fname} ${person.lname}`, value: person._id } : null;
                            })
                            .filter(Boolean);
                        break; // Stop searching once found
                    }
                }

                // Store the result with wfIndex as key
                newOwnerOptions[wfIndex] = ownerList;
            });
        }
        // console.log(newOwnerOptions);

        setWorkflowOwnerOptions(newOwnerOptions);
    };

    /* Generate wf owner selected array */
    const [selectedWorkflowOwners, setSelectedWorkflowOwners] = useState([]);
    const generateSelectedWorkflowOwners = () => {
        const result = {};
        if (workflows) {
            workflows.forEach((workflow, index) => {
                const match = peoples.find(item => item._id === workflow.owner);
                if (match) {
                    result[index] = {
                        label: `${match.fname} ${match.lname}`,
                        value: match._id,
                        groupLabel: "Person"
                    };
                }
            });
        }
        // console.log(result);
        setSelectedWorkflowOwners(result);
    };

    /* Generate step owners selected array */
    const [selectedStepOwners, setSelectedStepOwners] = useState({});
    const generateSelectedStepOwners = () => {
        const result = {};
        if (workflows) {
            workflows.forEach((workflow, wfIndex) => {
                if (!workflow.steps) return; // Skip if no steps exist

                workflow.steps.forEach((step, stepIndex) => {
                    let owners = [];

                    // Check in skills, roles, domains, and links sequentially
                    [skills, roles, dRoles, domains, links].forEach((dataArray) => {
                        dataArray.forEach((item) => {
                            if (step.owners && step.owners.length > 0 && step.owners.includes(item._id)) {
                                owners.push({
                                    label: item.skillName || item.roleName || item.domainName || item.linkName,
                                    value: item._id,
                                    groupLabel: item.skillName
                                        ? GetAliasesName(teams, "Skills")
                                        : item.roleName
                                            ? GetAliasesName(teams, "Roles")
                                            : item.domainName
                                                ? GetAliasesName(teams, "Domains")
                                                : item.linkName
                                                    ? GetAliasesName(teams, "Links")
                                                    : "Unknown",
                                });
                            }
                        });
                    });

                    if (owners.length >= 0) {
                        if (!result[wfIndex]) result[wfIndex] = {}; // Initialize workflow index if not present
                        result[wfIndex][stepIndex] = owners; // Store array of owners
                    }
                });
            });
        }

        if (Object.keys(result).length > 0) {
            // console.log(result);
            setSelectedStepOwners(result);
        }
    };

    useEffect(() => {
        // console.log(workflows);

        if (!report && workflows && workflows.length === 0 && workflowSearch === "") {
            setWorkflows([newWorkflow]);
        }

        generateWorkflowOwnerTypeOptions();
        generateSelectedWorkflowOwnerTypes();

        generateWorkflowOwnerOptions();
        generateSelectedWorkflowOwners();

        generateSelectedStepOwners();
        // console.log(workflows);
    }, [workflows]);

    useEffect(() => {
        if (workflowSearch.length > 0) {
            // Convert search term to lowercase for case-insensitive matching
            const searchTerm = workflowSearch.toLowerCase();

            const filteredWorkflows = workflowData.filter(workflow => {
                // Check if workflow name or notes match
                const matchesWorkflow =
                    workflow.name.toLowerCase().includes(searchTerm) ||
                    workflow.notes.toLowerCase().includes(searchTerm); // Check notes if they exist

                // Check if any step name or step notes match
                const matchesStep = workflow.steps.some(step =>
                    step.name.toLowerCase().includes(searchTerm) ||
                    step.notes.toLowerCase().includes(searchTerm) // Check notes if they exist
                );

                // console.log({ matchesWorkflow, matchesStep })

                // Return true if found in workflow name, notes, step name, or step notes
                return matchesWorkflow || matchesStep;
            });

            setWorkflows(filteredWorkflows); // Store the filtered results in state
        } else {
            setWorkflows(workflowData); // Reset when search is cleared
        }
    }, [workflowSearch]);

    // useEffect to trigger saveWorkflow after state update
    useEffect(() => {
        if (workflowToSave !== null) {
            freezeWindow(false, true, true);
            saveWorkflow(workflowToSave);
            setWorkflowToSave(null); // Reset after saving
        }
    }, [workflowToSave]); // Runs when workflows or workflowToSave changes

    useEffect(() => {
        if (report && peopleId) {
            if (isAllView) {
                setWorkflows(workflowData);
            } else {
                const ownedEntities = [
                    ...[...skills, ...roles].map(entity => entity.owners && entity.owners.length > 0 && entity.owners.includes(peopleId) && entity),
                    ...domains.map(entity => entity.owners.owner && entity.owners.owner.length > 0 && entity.owners.owner === peopleId && entity),
                    ...links.map(entity => entity.owner && entity.owner.length > 0 && entity.owner === peopleId && entity)
                ].filter(Boolean).map(entity => entity._id);
                // console.log({ peopleId, ownedEntities });

                // setWorkflows(workflowData.filter(wf => wf.owner === peopleId) || []);

                setWorkflows(
                    workflowData
                        .map(wf =>
                            (
                                ownedEntities.includes(wf.ownerType) || 
                                wf.owner === peopleId ||
                                wf.steps.some(step => step.owners.some(ownerId => ownedEntities.includes(ownerId))))
                                ? wf
                                : null
                        )
                        .filter(Boolean) // Removes null values
                );
            }
        }
    }, [showWorkflow, isAllView, workflowData]);

    return (
        <div className="workflow-page container-fluid">
            {!report &&
                <>
                    <div className="row home-header">
                        <div className="col-md-12">
                            <div className="tracking-main">
                                <div className="tracking-part">
                                    <h2><img src="/images/icon-ladder.png" alt="Workflow" style={{ width: "25px" }} />&nbsp;Workflows</h2>
                                    <p>Once you’ve defined all the roles and responsibilities your team needs in the dashboard, organise them here into workflows. Create a new workflow for a typical process, name it, and arrange all required roles and responsibilities in the correct order. You can use all items from the blue lists.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="workflow-body" data-html2canvas-ignore="true">
                        <div className="left-section">
                            <span className="add-workflow" onClick={addWorkflow}>
                                <img src="/images/icon-add.png" alt="Add Workflow" />&nbsp;Add a new workflow
                            </span>
                        </div>

                        <div className="right-section">
                            <span className="clearable3">
                                <img alt="settings" src={`${CLIENT_URL}/images/newicons/search-2.png`} />
                                <input
                                    type="text"
                                    className="form-control"
                                    value={workflowSearch}
                                    onChange={(e) => setWorkflowSearch(e.target.value)}
                                    name="search_list3"
                                    placeholder="Search"
                                    autoComplete="off"
                                />
                                {workflowSearch.length > 0 && (
                                    <i className="clearable__clear" onClick={() => setWorkflowSearch("")}>×</i>
                                )}
                            </span>
                        </div>
                    </div>
                </>
            }

            <div className="workflows">
                {workflows && workflows.length > 0 &&
                    workflows
                        .filter(wf => !wf.softDelete)
                        .map((workflow, index) => {
                            return (
                                <WorkFlowComponent
                                    key={index} // Use index as key instead of id
                                    teams={teams}
                                    wfindex={index}
                                    workflow={workflow}
                                    workflows={workflows}
                                    addStep={() => addStepToWorkflow(index)}
                                    deleteStep={(index, stepIndex) => deleteStepFromWorkflow(index, stepIndex)}
                                    saveWorkflow={() => saveWorkflow(index)}
                                    copyWorkflow={() => copyWorkflow(index)}
                                    deleteWorkflow={() => handleDeleteWorkflow(index)}
                                    handleChange={handleChange}
                                    handleBlur={() => saveWorkflow(index)}
                                    workflowOwnerTypeOptions={workflowOwnerTypeOptions}
                                    workflowOwnerOptions={workflowOwnerOptions}
                                    selectedWorkflowOwnerTypes={selectedWorkflowOwnerTypes}
                                    selectedWorkflowOwners={selectedWorkflowOwners}
                                    selectedStepOwners={selectedStepOwners}
                                    report={report}
                                />
                            );
                        })}
            </div>
        </div>
    );
}
