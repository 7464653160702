export const WORKFLOW_ADD_SUCCESS = 'WORKFLOW_ADD_SUCCESS';
export const WORKFLOW_ADD_FAIL = 'WORKFLOW_ADD_FAIL';

export const ALL_WORKFLOW_GET_SUCCESS = 'ALL_WORKFLOW_GET_SUCCESS';
export const ALL_WORKFLOW_GET_ERROR = 'ALL_WORKFLOW_GET_ERROR';

export const WORKFLOW_SUCCESS_MESSAGE_CLEAR = 'WORKFLOW_SUCCESS_MESSAGE_CLEAR';
export const WORKFLOW_ERROR_MESSAGE_CLEAR = 'WORKFLOW_ERROR_MESSAGE_CLEAR';

export const WORKFLOW_UPDATE_SUCCESS = 'WORKFLOW_UPDATE_SUCCESS';
export const WORKFLOW_UPDATE_FAIL = 'WORKFLOW_UPDATE_FAIL';

export const WORKFLOW_DELETE_SUCCESS = 'WORKFLOW_DELETE_SUCCESS';
export const WORKFLOW_DELETE_FAIL = 'WORKFLOW_DELETE_FAIL';